<template>
  <div class="team">
    <div class="container">
      <h4 class="team-title" v-html="job.big_title"></h4>
    </div>
    <!-- <h4 class="team-title" v-html="job.big_title"></h4> -->
    <swiper
      :loop="true"
      :grabCursor="true"
      :centeredSlides="false"
      :slidesPerView="3"
      :spaceBetween="70"
      :coverflowEffect="false"
      :autoplay="{
        delay: 2500,
      }"
      :pagination="false"
      :navigation="navigation"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="j in job.footer" :key="j">
        <img :src="cdn + j.icon" />
      </swiper-slide>
    </swiper>
    <div class="nav-btn">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import { mapState } from "vuex";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      job: [],
    };
  },
  created() {
    axios
      .get("V1/vacancies")
      .then((response) => {
        console.log(response);
        this.job = response.data.data.second_block;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 425px) and (max-width: 767px) {
    @content;
  }
}

@mixin tablet-1024 {
  @media (min-width: 767px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}

@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.container {
  width: 95%;
  @include desktop() {
    width: 91%;
  }
  @include tablet-1024() {
    width: 90%;
  }
}
.nav-btn {
  position: absolute;
  top: 53%;
  width: 100%;
  @include desktop() {
    top: 63%;
  }
  @include tablet-1024() {
    top: 58%;
  }
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  &-3d {
    // width: 600px;
  }
  &-button {
    &-next {
      right: -20px;
      background-image: url("../assets/images/right.png");
      &::after {
        opacity: 0;
      }
    }
    &-prev {
      left: -20px;
      background-image: url("../assets/images/left.png");
      &::after {
        opacity: 0;
      }
    }
  }
  @include mobile() {
    padding-top: 0;
    padding-bottom: 30px;
  }
  @include tablet() {
    padding-top: 0;
    padding-bottom: 30px;
  }
  @include desktop() {
    width: 95%;
  }
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  img {
    width: 351px;
    height: 218px;
    margin-left: 10px;
    // width: 439px;
    // height: 331px;
  }
  &-next {
    img {
      transform: scale(1.5);
      object-fit: contain;
    }
  }
  @include mobile() {
    width: 300px;
    // height: 185px;
  }
  @include tablet() {
    width: 300px;
    // height: 185px;
  }
  @include tablet-1024() {
    width: 300px;
    // height: 185px;
  }
  @include desktop() {
    width: 420px;
    &-next {
      img {
        transform: scale(1.3);
        object-fit: contain;
      }
    }
  }
}
.swiper-slide img {
  display: block;
  // width: 100%;
  @include tablet-1024() {
    width: 200px;
    height: 140px;
    object-fit: contain;
  }
  @include desktop() {
    width: 299px;
    height: 193px;
    object-fit: contain;
  }
}
.team {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  &-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #373737;
    text-align: left;
  }
  @include mobile() {
    padding-top: 20px;
    padding-bottom: 20px;
    &-title {
      font-size: 12px;
      line-height: 15px;
    }
  }
  @include tablet() {
    padding-top: 20px;
    padding-bottom: 20px;
    &-title {
      font-size: 14px;
      line-height: 17px;
    }
  }
  @include tablet-1024() {
    padding-top: 40px;
    padding-bottom: 20px;
    &-title {
      font-size: 17px;
      line-height: 22px;
    }
  }
  @include desktop() {
    padding-bottom: 0;
  }
}
</style>
