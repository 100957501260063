<template>
    <div class="slide-btm">
        <div class="container">
            <p class="slide-btm__title" v-html="comments"></p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {mapState} from 'vuex'
export default {
    data() {
        return {
            comments: [],
        }
    },
    created() {
        axios.get('V1/comments')
        .then(response => {
            this.comments = response.data.big_title
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
@mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 769px) and (max-width: 1023px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1023px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1441px) and (max-width: 2560px) {
            @content;
        }
    }
    .slide-btm{
        @include mobile() {
            padding-top: 30px;
            padding-bottom: 30px;
            &__title {
                display: none;
            }
        }
        @include tablet() {
            padding-top: 30px;
            padding-bottom: 30px;
            &__title {
                display: none;
            }
        }
        @include tablet-1024() {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
</style>