<template>
    <div class="director">
        <div class="container">
            <div class="director-title" v-html="directorTitle"></div>
            <div class="director-sub" v-html="directorDesc1"></div>
            <div class="director-box" v-if="director">
                <div @click="openModal(dr.id)" class="director-card" v-for="dr in director" :key="dr">
                    <img :src="cdn + dr.image" alt="">
                    <h4 v-html="dr.title"></h4>
                    <h5 v-html="dr.position"></h5>
                </div>
            </div>
            <modal :open="isOpen" @close="isOpen = !isOpen">
                <div class="modals" v-if="selectedDirector">

                    <div class="modal-left" v-if="selectedDirector.image">
                        <img :src="cdn + selectedDirector.image" alt="">
                    </div>
                    <div class="modal-right">
                        <p class="name" v-html="selectedDirector.title"></p>
                        <p class="position" v-html="selectedDirector.position"></p>
                        <p class="par" v-html="selectedDirector.description"></p>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import {ref} from 'vue'
import modal from '../components/windows/modalCompany.vue'
export default {
    components: {modal},
    data() {
        return {
            director: [],
            directorTitle: [],
            directorDesc1: [],
            selectedModal: 0,
        }
    },
    setup() {
    const isOpen = ref(false)
        return {isOpen}
    },
    created() {
    axios.get('V1/company-managements')
        .then(response => {
            this.director = response.data.data.directors
            this.directorTitle = response.data.big_title
            this.directorDesc1 = response.data.desc_1
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    methods:{
        openModal(id){
            this.selectedModal = id
            this.isOpen = true
        }
    },
    computed :{
        ...mapState(['cdn']),
        selectedDirector(){
            return this.director.find(item => item.id === this.selectedModal)
        }
    },
    watch: {
        isOpen: function() {
            if(this.isOpen){
                document.documentElement.style.overflow = 'hidden'
                return
            }
            document.documentElement.style.overflow = 'auto'
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/style/director.scss';
</style>