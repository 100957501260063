export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salem i18n !!"])},
  "nav": {
    "Bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кәсіпке"])},
    "Individuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке тұлғаларға"])},
    "ServicesandPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервистер мен төлемдер"])},
    "ServicesandPayments1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервистер"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз туралы"])},
    "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыстар"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекен-жайлар"])}
  },
  "bussiness": {
    "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлікті сақтандыру"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мүлікті сақтандыру"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктерді сақтандыру"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жауапкершілікті сақтандыру"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денсаулықты cақтандыру"])},
    "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептелген cақтандыру"])}
  },
  "individual": {
    "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автокөлікті сақтандыру"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денсаулықты сақтандыру"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мүлікті сақтандыру"])},
    "tourist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туристерді сақтандыру"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың міндетті түрлері"])},
    "bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнесті сақтандыру"])}
  },
  "service": {
    "ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру жағдайы"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полис сатып алу"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полисті тексеру"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру шарттарын төлеу"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-калькулятор"])}
  },
  "about": {
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз туралы"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құжаттар"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пресс-центр"])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплаенс"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бос жұмыс орындары"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іс-шаралар"])},
    "ac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акционерлерге"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифрлық жобалар"])},
    "quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])}
  },
  "buttons": {
    "getPresentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компанияның презентациясын алу"])},
    "moreBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
    "moreBtn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыруды рәсімдеу"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания жаңалықтары"])},
    "allNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық басты жаңалықтар"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жиі қойылатын сұрақтар"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансия"])},
    "vacDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бос жұмыс орындары"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centras холдингінің өнімдері мен пайдалы қосымшалары"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тағы көрсету"])},
    "based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің мекенжайлар"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кері қоңырауға тапсырыс беру"])},
    "lmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ оқыңыз"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАСКО"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франчайзингпен Авто лимит"])},
    "sakta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["САКТА"])},
    "pledged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автокөлік кепілге қою"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу"])},
    "polis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полисті рәсімдеу"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1997 жылдан бастап денсаулықты, мүлікті және көлікті сақтандырамыз"])},
    "showMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картада көрсету"])},
    "ans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жиі қойылатын сұрақтарға жауаптар"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарих"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рейтингтер және қауымдастықтарға қатысу"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форманы толтырыңыз"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелген өтініш"])},
    "anon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимді өтініш"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмысқа өтініш"])},
    "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спикер болу"])},
    "sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демеуші болу"])},
    "hold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акционерлердің шешімдері"])},
    "hold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акция ұстаушылар тізімі"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
    "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шешім"])},
    "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем"])},
    "decor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тіркеу"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа сұрақтар"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотипті жүктеу"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайтқа өту"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық ақпарат алу"])}
  },
  "offer": {
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақпараттық қауіпсіздік саясаты"])}
  },
  "documents": {
    "lis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия"])},
    "fin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаржылық есептілік"])},
    "reestr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру агенттерінің тізілімі"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мемлекеттік тіркеу"])},
    "tar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру тарифтері"])},
    "rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру ережелері"])},
    "rec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректемелер"])},
    "ruleRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орыс тіліндегі инсайдерлік ақпаратты ішкі бақылау қағидалары"])},
    "ruleKz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ тіліндегі инсайдерлік ақпаратты ішкі бақылау қағидалары"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың міндетті түрлері бойынша қолданыстағы ережелер"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың ерікті түрлерінің қолданыстағы ережелері"])},
    "rule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың міндетті және ерікті түрлері бойынша күші жойылған ережелер"])},
    "comissionFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссиялық сыйақылар"])}
  },
  "par": {
    "bus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күтпеген тәуекелдерден қорғау шешімімен "])},
    "bus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жеке"])},
    "bus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бизнесіңіз үшін уайымдамаңыз"])},
    "dev1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайтты жасаған компания"])},
    "dev2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio Nomad"])},
    "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кішірейту"])},
    "reviewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық оқу"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл таңдалды"])}
  },
  "modal": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізбен бірге үнемдеңіз"])},
    "individDisc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форманы толтырыңыз және біздің өнімдерге жеке жеңілдік алыңыз."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру түрін таңдаңыз"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түйіндемені тіркеу"])},
    "we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз сізге көмектесуге қуаныштымыз"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің мамандар Сізге кез-келген мәселені шешуге көмектеседі. Сізді қызықтыратын өнімдерді таңдап, кері қоңырауға тапсырыс беріңіз."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің өнімдер туралы толығырақ білгіңіз келе ме?"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз 5 минут ішінде компания және біздің өнімдер туралы презентация жібереміз. Тек деректеріңізді қалдыруыңыз жеткілікті."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәлеметсіз бе! Мен сізге көмектесуге қуаныштымын!"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізді қызықтыратын өнімдерді таңдап, деректерді толтырыңыз. Біз сізге жақын арада хабарласамыз."])},
    "check1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дербес деректерді"])},
    "check2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["өңдеу саясатымен келісемін"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түймені басу арқылы сіз дербес деректерді"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["өңдеу шарттарымен келісесіз"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаланы таңдаңыз"])},
    "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмысқа өтініш беру"])}
  },
  "placeholder": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есімі*"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон нөмірі*"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail*"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания атауы*"])},
    "com": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікір (міндетті емес)"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқалардың бірін таңдаңыз"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтініш мәтіні..."])},
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті өріс!"])},
    "reqEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате енгізілген email!"])}
  },
  "notification": {
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті өрістерді толтырыңыз"])},
    "dontagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз жеке деректерді өңдеу саясатымен келіспедіңіз"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің формаңыз сәтті жіберілді!"])},
    "reqProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті өрістер толтырылмаған немесе сақтандыру түрі табылған жоқ!"])},
    "successVacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті жіберілді! Біз сізге жақын арада хабарласамыз"])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахмет, біз сіздің ескертуіңізді ескеретін боламыз!"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің формаңыз жіберілді!"])}
  },
  "product": {
    "med": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медицина"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автокаско"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктерді сақтандыру"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мүлік"])},
    "gpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа тұлғалар алдындағы азаматтық-құқықтық жауапкершілік"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заңды тұлғаларды міндетті сақтандыру"])},
    "ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызметкерлерді қауіп-қатерден міндетті сақтандыру"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа"])},
    "gpoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азаматтық жауапкершілік туындаған жағдайда сіздің қаржылық мүдделеріңізді қорғаймыз"])}
  }
}