<template>
  <about-us-header />
  <about-back />
  <about-buttons />
  <div class="reference">
    <div class="container">
      <p class="reference-title" v-html="gos.title"></p>
      <p class="reference-sub" v-html="gos.description"></p>
      <p class="reference-par" v-html="gos.text"></p>
      <div class="reference-box m_none">
        <div class="reference-box__item">
          <a :href="cdn + gos.doc">
            <img src="../assets/images/download.svg" alt="" />
            <p>{{ $t("documents.reg") }}</p>
          </a>
        </div>
      </div>
      <div class="ref d_none">
        <a :href="cdn + gos.doc" class="registry-box__item">
          <img
            class="download-img"
            src="../assets/images/download.svg"
            alt=""
          />
          <p>{{ $t("documents.reg") }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "./aboutUsHeader.vue";
import AboutBack from "./AboutBack.vue";
import aboutButtons from "./aboutButtons.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { aboutUsHeader, AboutBack, aboutButtons },
  data() {
    return {
      gos: [],
    };
  },
  created() {
    axios
      .get("V1/gosreg")
      .then((response) => {
        this.gos = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.ref {
  .registry-box__item {
    display: flex;
    align-items: center;
    padding-bottom: 13px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    p {
      @include fontStyle(Roboto, normal, 300, 10px, 12px, #373737);
      font-size: 14px;
      line-height: 16px;
      padding: 0px;
      text-decoration: none;
      margin-bottom: 0;
    }
  }
  @include tablet() {
    .registry-box__item {
      padding-top: 20px;
    }
  }
}
.reference {
  background: #efefef;
  padding: 80px 0;
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-sub,
  &-par {
    @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    padding-bottom: 20px;
  }
  &-sub {
    padding-top: 40px;
    // font-weight: 600;
  }
  &-box {
    background: #ffffff;
    &__item {
      a {
        display: flex;
        align-items: center;
      }
      img {
        width: 60px;
        height: 40px;
        padding-left: 20px;
      }
      p {
        padding: 23px 0 0px 20px;
        @include fontStyle(Roboto, normal, 300, 20px, 24px, #373737);
      }
    }
  }
  &-img {
    img {
      margin-right: 35px;
    }
  }
  @include mobile() {
    padding: 0px;
    padding-bottom: 20px;
    &-title {
      display: none;
    }
    &-sub {
      font-size: 14px;
      line-height: 16px;
      padding: 15px 0px;
      margin-bottom: 0;
      font-weight: 400;
    }
    &-par {
      display: none;
      font-size: 10px;
      line-height: 12px;
      padding: 10px 0;
      font-weight: 400;
    }
    &-img {
      img {
        width: 100px;
        margin-right: 20px;
      }
    }
  }
  @include tablet() {
    padding: 20px 0px;
    &-title {
      display: none;
    }
    &-sub {
      font-size: 14px;
      line-height: 16px;
      padding: 0px;
      margin-bottom: 0;
      font-weight: 400;
    }
    &-par {
      display: none;
      font-size: 10px;
      line-height: 12px;
      padding: 10px 0;
      font-weight: 400;
    }
    &-img {
      img {
        width: 130px;
        margin-right: 20px;
      }
    }
  }
  @include tablet-1024() {
    padding: 25px 0;
    &-title {
      font-size: 17px;
      line-height: 20px;
    }
    &-sub {
      font-size: 13px;
      line-height: 16px;
      padding: 10px 0;
    }
    &-par {
      font-size: 10px;
      line-height: 12px;
      padding: 10px 0;
    }
    &-img {
      img {
        width: 180px;
        margin-right: 20px;
      }
    }
  }
}
</style>
