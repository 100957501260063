<template>
    <about-us-header/>
    <about-back/>
    <about-buttons/>
    <div class="acc">
        <div class="container">
            <div class="acc-card" v-for="f in fin" :key="f">
                <input :id="f.id" type="checkbox" name="menu" />
                <label :for="f.id">{{f.year}}</label>
                <ul class="submenu" v-for="dt in f.date" :key="dt">
                    <a :href="cdn + dt.doc"><img src="@/assets/images/download.svg" alt=""><p v-html="dt.description"></p></a>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import aboutUsHeader from '../components/aboutUsHeader.vue';
import AboutBack from '../components/AboutBack.vue';
import aboutButtons from '../components/aboutButtons.vue';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    name: 'financeMobile',
    components: {
        aboutUsHeader,AboutBack,aboutButtons
    },
    data() {
        return {
            fin: [],
        }
    },
    created() {
        axios.get('V1/financial-accounts')
        .then(response => {
            this.fin = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    input, ul.submenu {
        display: none;
    }

    /*position the label*/
    label {
        position: relative;
        display: block;
        cursor: pointer;
        @include fontStyle(Roboto, normal, 500, 22px, 27px, #959595);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
            margin-left: 10px;
            padding-bottom: 5px;
        }
    }

    /*show the submenu when input is checked*/
    input:checked~ul.submenu {
        display: block;
        transition: all 0.4s linear;
    }
    .submenu a {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding-top: 10px;
        img {
            width: 19px;
        }
        p {
            @include fontStyle(Roboto, normal, 300, 12px, 14px, #373737);
            margin-bottom: 0;
            padding-left: 9px;
        }
    }
    .container {
        padding-left: 0;
    }
    .acc {
        padding: 80px 0;
        background: #EFEFEF;
        &-card {
            margin-bottom: 10px;
            padding-top: 13px;
            label {
                margin-bottom: 0;
            }
        }
        @include mobile() {
            padding:0 0 25px 0;
        }
    }
</style>
