<template>
    <div class="kasko-slider">
        <swiper
            :spaceBetween="30"
            :centeredSlides="true"
            :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
            }"
            :pagination="{
            clickable: true,
            }"
            :navigation="false"
            :modules="modules"
            class="mySwiper">
            <swiper-slide v-for="ks in kasko" :key="ks">
                <div class="kasko-card">
                    <img :src="cdn + ks.icon" alt="">
                    <p v-html="ks.description"></p>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from 'axios';
import { mapState } from "vuex";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
components: {
    Swiper,
    SwiperSlide,
},
data() {
    return {
        kasko: [],
    }
},
created() {
    axios.get('V1/top-products')
    .then(response => {
        this.kasko = response.data.data[1].second_block
    })
    .catch(e => {
        this.errors.push(e)
    })
},
computed :{
    ...mapState(['cdn']),
},
setup() {
    return {
        modules: [Autoplay, Pagination, Navigation],
    };
},
};
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    .kasko-card {
        padding-bottom: 20px;
        p {
            //change
            @include fontStyle(Roboto, normal, 300, 14px, 16px, #373737);
            background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-align: center;
            width: 86%;
            margin: 0 auto;
        }
        img {
            // min-height: 90px;
            max-height: 120px;
            min-width: 90px;
            object-fit: contain;
            // max-width: 100px;
            object-fit: contain;
            padding-bottom: 10px;
        }
        @include tablet() {
            p {
                font-size: 14px;
                line-height: 17px;
            }
        }
        @include tablet-1024() {
            p {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
</style>
