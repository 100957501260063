import { createWebHistory, createRouter } from 'vue-router'
import TransportMain from '.././views/TransportMain.vue'
import MainPage from '.././views/MainPage.vue'
import PropertyMain from '.././views/PropertyMain.vue'
import ResponseMain from '.././views/ResponseMain.vue'
import HealthMain from '.././views/HealthMain.vue'
import TemporaryMain from '.././views/TemporaryMain.vue'
import Cargo from '../views/Cargo.vue'
import autoIndivid from '../views/autoIndivid.vue'
import healthIndivid from '../views/healthIndivid.vue'
import houseIndivid from '../views/houseIndivid.vue'
import touristIndivid from '../views/touristIndivid.vue'
import typeIndivid from '../views/typeIndivid.vue'
import buisnessIndivid from '../views/buisnessIndivid.vue'
import policy from '../views/policy.vue'
import airTransport from '../views/airTransport.vue'
import trainTransport from '../views/trainTransport.vue'
import boatTransport from '../views/boatTransport.vue'
import autoTransport from '../views/autoTransport.vue'
import saktaTransport from '../views/saktaTransport.vue'
import autoLimitTransport from '../views/autoLimitTransport.vue'
import digitalAbout from '../views/digitalAbout.vue'
import kaskoMain from '../views/kaskoMain.vue'
import OGPO from '../views/OGPO.vue'
import GPO from '../views/GPO.vue'
import DGPO from '../views/DGPO.vue'
import ownerResp from '../views/ownerResp.vue'
import tenantResp from '../views/tenantResp.vue'
import medicineResp from '../views/medicineResp.vue'
import profResp from '../views/profResp.vue'
import accidentHealth from '../views/accidentHealth.vue'
import Health from '../views/Health.vue'
import Property from '../views/Property.vue'
import ComplexProperty from '../views/ComplexProperty.vue'
import TitleProperty from '../views/TitleProperty.vue'
import BuildProperty from '../views/BuildProperty.vue'
import OilProperty from '../views/OilProperty.vue'
import AboutUs from '../views/AboutUs.vue'
import documentsAbout from '../views/documentsAbout.vue'
import financeAccordion from '../components/financeAccordion.vue'
import financeMobile from '../components/financeMobile.vue'
import LicenseAbout from '../components/LicenseAbout.vue'
import registryAbout from '../components/registryAbout.vue' 
import referenceAbout from '../components/referenceAbout.vue'
import rulesAbout from '../components/rulesAbout.vue'
import rulesMobile from '../components/rulesMobile.vue'
import requisitesAbout from '../components/requisitesAbout.vue'
import pressAbout from '../components/pressAbout.vue'
import jobAbout from '../views/jobAbout.vue'
import eventAbout from '../views/eventAbout.vue'
import HolderAbout from '../views/HolderAbout.vue'
import Holder1 from '../components/Holder1.vue'
import Holder2 from '../components/Holder2.vue'
import complAbout from '../views/complAbout.vue'
import FAQabout from '../views/FAQabout.vue'
import Address from '../views/Address.vue'
import interviewForm from '../components/interviewForm.vue'
import TarifsAbout from '../components/TarifsAbout.vue'
import condition from '../views/condition.vue'
import slideVideo from '@/components/slideVideo.vue'
// import pressSlider from '@/components/pressSlider.vue'
import tarifs from '@/components/tarifs.vue'
import comissionFees from '@/components/comissionFees.vue'
import news from '@/components/news.vue'
import bcc from '@/views/bcc.vue'

const routes = [
    // {
    //     path:'/:lang',
    //     render (c) {
    //         return c('router-view')
    //     },
    //     children: [
        {
            path: '/',
            name: 'MainPage',
            component: MainPage
        },
        {
            path: '/transport',
            name: 'TransportMain',
            component: TransportMain
        },
        {
            path: '/property-main',
            name: 'PropertyMain',
            component: PropertyMain
        },
        {
            path: '/response',
            name: 'ResponseMain',
            component: ResponseMain
        },
        {
            path: '/HealthMain',
            name: 'HealthMain',
            component: HealthMain
        },
        {
            path:'/TemporaryMain',
            name: 'TemporaryMain',
            component: TemporaryMain
        },
        {
            path: '/Cargo',
            name: 'Cargo',
            component: Cargo
        },
        {
            path: '/autoIndivid',
            name: 'autoIndivid',
            component: autoIndivid
        },
        {
            path: '/healthIndivid',
            name: 'healthIndivid',
            component: healthIndivid
        },
        {
            path: '/houseIndivid',
            name: 'houseIndivid',
            component: houseIndivid
        },
        {
            path: '/touristIndivid',
            name: 'touristIndivid',
            component: touristIndivid
        },
        {
            path: '/typeIndivid',
            name: 'typeIndivid',
            component: typeIndivid
        },
        {
            path: '/buisnessIndivid',
            name: 'buisnessIndivid',
            component: buisnessIndivid
        },
        {
            path: '/policy',
            name: 'policy',
            component: policy
        },
        {
            path: '/airTransport',
            name: 'airTransport',
            component: airTransport
        },
        {
            path: '/trainTransport',
            name: 'trainTransport',
            component: trainTransport
        },
        {
            path: '/boatTransport',
            name: 'boatTransport',
            component: boatTransport
        }, 
        {
            path: '/autoTransport',
            name: 'autoTransport',
            component: autoTransport
        },
        {
            path: '/saktaTransport',
            name: 'saktaTransport',
            component: saktaTransport
        },
        {
            path: '/autoLimitTransport',
            name: 'autoLimitTransport',
            component: autoLimitTransport
        },
        {
            path: '/digitalAbout',
            name: 'digitalAbout',
            component: digitalAbout
        },
        {
            path: '/kaskoMain',
            name: 'kaskoMain',
            component: kaskoMain
        },
        {
            path: '/OGPO',
            name: 'OGPO',
            component: OGPO
        },
        {
            path: '/GPO',
            name: 'GPO',
            component: GPO
        },
        {
            path: '/DGPO',
            name: 'DGPO',
            component: DGPO
        },
        {
            path: '/ownerResp',
            name: 'ownerResp',
            component: ownerResp
        },
        {
            path: '/tenantResp',
            name: 'tenantResp',
            component: tenantResp
        },
        {
            path: '/medicineResp',
            name: 'medicineResp',
            component: medicineResp
        },
        {
            path: '/profResp',
            name: 'profResp',
            component: profResp
        },
        {
            path: '/accidentHealth',
            name: 'accidentHealth',
            component: accidentHealth
        },
        {
            path: '/Health',
            name: 'Health',
            component: Health
        },
        {
            path: '/Property',
            name: 'Property',
            component: Property
        },
        {
            path: '/ComplexProperty',
            name: 'ComplexProperty',
            component: ComplexProperty
        },
        {
            path: '/TitleProperty',
            name: '/TitleProperty',
            component: TitleProperty
        },
        {
            path:'/BuildProperty',
            name: 'BuildProperty',
            component: BuildProperty
        },
        {
            path: '/OilProperty',
            name: 'OilProperty',
            component: OilProperty
        },
        {
            path: '/about',
            name: 'AboutUs',
            component: AboutUs
        },
        {
            path: '/documentsAbout',
            name: 'documentsAbout',
            component: documentsAbout
        },
        {
            path: '/financeAccordion',
            name: 'financeAccordion',
            component: financeAccordion
        },
        {
            path: '/financeMobile',
            name: 'financeMobile',
            component: financeMobile
        },
        {
            path: '/LicenseAbout',
            name: 'LicenseAbout',
            component: LicenseAbout
        },
        {
            path: '/registryAbout',
            name: 'registryAbout',
            component: registryAbout
        },
        {
            path: '/referenceAbout',
            name: 'referenceAbout',
            component: referenceAbout
        },
        {
            path: '/rulesAbout',
            name: 'rulesAbout',
            component: rulesAbout
        },
        {
            path: '/rulesMobile',
            name: 'rulesMobile',
            component: rulesMobile
        },
        {
            path: '/requisitesAbout',
            name: 'requisitesAbout',
            component: requisitesAbout
        },
        {
            path: '/pressAbout',
            name: 'pressAbout',
            component: pressAbout
        },
        {
            path: '/jobAbout',
            name: 'jobAbout',
            component: jobAbout
        },
        {
            path: '/eventAbout',
            name:'eventAbout',
            component: eventAbout
        },
        {
            path: '/HolderAbout',
            name: 'HolderAbout',
            component: HolderAbout
        },
        {
            path: '/Holder1',
            name: 'Holder1',
            component: Holder1
        },
        {
            path: '/Holder2',
            name: 'Holder2',
            component: Holder2
        },
        {
            path: '/compliance',
            name: 'complAbout',
            component: complAbout
        },
        {
            path: '/FAQabout',
            name: 'FAQabout',
            component: FAQabout
        },
        {
            path: '/Address',
            name: 'Address',
            component: Address
        },
        {
            path: '/interviewForm',
            name: 'interviewForm',
            component: interviewForm
        },
        {
            path: '/TarifsAbout',
            name: 'TarifsAbout',
            component: TarifsAbout
        },
        {
            path: '/condition',
            name: 'condition',
            component: condition
        },
        {
            path: '/slideVideo',
            name: 'slideVideo',
            component: slideVideo
        },
        {
            path: '/tarifs',
            name: 'tarifs',
            component: tarifs
        },
        {
            path: '/comissionFees',
            name: 'comissionFees',
            component: comissionFees
        },
        {
            path: '/news/:id',
            name: 'news',
            component: news
        },
        {
            path: '/bcc',
            name: 'bcc',
            component: bcc
        }
    // ]
    // }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
// export default new VueRouter({
//     routes: [
//         {
//             path: ''
//             component:
//         },
//         {
//             path: ''
//             component:
//         },
//         {
//             path: ''
//             component:
//         }
//     ]
// })