<template>
    <swiper 
        :effect="'coverflow'"
        :loop="false"
        :grabCursor="true"
        :centeredSlides="false"
        :slidesPerView="1"
        :coverflowEffect="{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        }"
        :navigation="navigation"
        :modules="modules"
        class="mySwiper">
        <swiper-slide v-for="v in video" :key="v">
            <iframe width="560" height="315" :src="v.video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <!-- <p class="episode" v-html="v.name"></p> -->
        </swiper-slide>
        <div class="navigation-btn">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </swiper>
</template>
<script>

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/effect-coverflow";
import "swiper/css";
import "swiper/css/navigation";
import {EffectCoverflow, Navigation } from "swiper";
import Embed from 'v-video-embed'
import axios from 'axios'
import { mapState } from "vuex";

export default {
    components: {
        Swiper,
        SwiperSlide,
        Embed
    },
    data() {
        return {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            video: []
        }
    },
    setup() {
        return {
            modules: [EffectCoverflow,Navigation],
        };
    },
    created(){
        axios.get('V1/company-videos')
            .then(response => {
                this.video = response.data.data
            })
            .catch(e => {
                this.errors.push(e)
            })
    },
    computed :{
        ...mapState(['cdn']),
    },
};
</script>

<style lang="scss" scoped>
.mySwiper__img{
    padding: 30px;
}
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    iframe {
        @include mobile() {
            height: 130px;
        }
        @include tablet() {
            height: 200px;
        }
    }
    .episode {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 119.69%;
        text-align: center;
        color: #373737;
        padding-top: 8px;
    }
    .swiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        &-button {
            &-prev {
                // top: 47%;
                top: 56%;
                background: url('@/assets/images/left1.png');
                background-repeat: no-repeat;
                &::after {
                    opacity: 0;
                }
            }
            &-next {
                // top: 47%;
                top: 56%;
                background: url('@/assets/images/right1.png');
                background-repeat: no-repeat;
                &::after {
                    opacity: 0;
                }
            }
        }
        @include mobile() {
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 370px;
        height: 300px;
        @include mobile() {
            height: 125px;
        }
        @include tablet() {
            height: 200px;
        }
    }
    .swiper-slide iframe {
        display: block;
        width: 85%;
        margin: 0 auto;
        @include mobile() {
            border-radius: 5px;
        }
    }
</style>