<template>
    <div class="back">
        <div class="wave">
        <div class="container">
            <a class="container-img" href="https://kupipolis.kz
"><img src="../assets/images/another.svg" alt=""></a>
            <div class="wave-par">
                <p class="wave-par__title" v-html="policyTitle"></p>
                <p class="wave-par__sub" v-html="policyDesc"></p>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            policyTitle: null,
            errors: [],
            policyDesc: null
        }
    },
    created() {
        axios.get('V1/service_payments')
        .then(response => {
            this.policyTitle = response.data.data[0].title
            this.policyDesc = response.data.data[0].description
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1440px) and (max-width: 2560px) {
            @content;
        }
    }
    .back {
        background-color: #EFEFEF;
        padding-top: 70px;
        @include mobile() {
            padding-top: 20px;
        }
        @include tablet() {
            padding-top: 30px;
        }
    }
    .wave {
        background: url(../assets/images/waveInd.png);
        background-repeat: no-repeat;
        background-size: contain;
        &-par {
            &__title {
                padding-top: 54px;
                @include fontStyle(Roboto, normal, 500, 42px, 58px, transparent);
                background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-transform: uppercase;
            }
            &__sub {
                padding-top: 10px;
                @include fontStyle(Roboto, normal, 400, 23px, 33px, #6A4A83);
            }
        }
        @media (min-width: 1441px) and (max-width: 1600px) {
            &-par {
                &__title {
                    font-size: 36px;
                    line-height: 42px;
                }
                &__sub {
                    font-size: 19px;
                    line-height: 24px;
                }
            }
        }
        @include mobile() {
            &-par {
                &__title {
                    text-align: center;
                    font-size: 16px;
                    line-height: 17px;
                    padding-top: 0;
                    margin-bottom: 0;
                    font-weight: 600;
                }
                &__sub {
                    font-size: 14px;
                    line-height: 16px;
                    color: #373737;
                    text-align: center;
                    margin-bottom: -10px;
                }
            }
        }
        @include tablet() {
            &-par {
                &__title {
                    text-align: center;
                    font-size: 17px;
                    line-height: 20px;
                    padding-top: 0;
                    margin-bottom: 0;
                }
                &__sub {
                    font-size: 14px;
                    line-height: 17px;
                    color: #373737;
                    text-align: center;
                    margin-bottom: -10px;
                }
            }
        }
        @include tablet-1024() {
            &-par {
                width: 100%;
                &__title {
                    padding-top: 0;
                    font-size: 24px;
                    line-height: 30px;
                    margin-top: -10px;
                }
                &__sub {
                    font-size: 18px;
                    line-height: 23px;
                    padding-bottom: 20px;
                    text-align: center;
                }
            }
        }
        @include desktop() {
            &-par {
                width: 60%;
                &__title {
                    padding-top: 0;
                    font-size: 30px;
                    line-height: 36px;
                }
                &__sub {
                    font-size: 20px;
                    line-height: 26px;
                    padding-bottom: 20px;
                    padding-top: 20px;
                }
            }
        }
    }
    .container {
        display: flex;
        justify-content: space-around;
        &-img {
            margin-left: -150px;
            margin-top: -70px;
            img {
                max-width: 890px;
                width: 100%;
            }
        }
        @include mobile() {
            flex-wrap: wrap-reverse;
            &-img {
                margin: 0 auto;
                img {
                    min-width: 300px;
                }
            }
        }
        @include tablet() {
            flex-wrap: wrap-reverse;
            &-img {
                margin: 0 auto;
                img {
                    width: 380px;
                }
            }
        }
    }
</style>