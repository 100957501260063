<template>
  <about-us-header />
  <about-back />
  <div class="holder animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="container">
      <div class="holder-par">
        <router-link to="/Holder1"
          ><p>{{ $t("buttons.hold1") }}</p>
          <div class="holder-par__links" v-for="h in hold" :key="h">
            <a :href="cdn + h.doc"
              ><span
                ><img
                  class="download-img"
                  src="../assets/images/download.svg"
                  alt="" /></span
              >{{ $t("documents.lis") }}</a
            >
          </div>
        </router-link>
        <router-link to="/Holder2"
          ><p>{{ $t("buttons.hold2") }}</p>
          <div class="holder-par__links" v-for="rs in reestr" :key="rs">
            <a :href="cdn + rs.doc"
              ><span
                ><img
                  class="download-img"
                  src="../assets/images/download.svg"
                  alt="" /></span
              >Сертификат</a
            >
          </div>
        </router-link>
      </div>
      <div class="holder-text" v-html="reestr[0].description"></div>
      <div class="holder-card" v-for="r in reestr" :key="r">
        <a :href="cdn + r.doc">
          <!-- <p><span><img class="download-img" src="../assets/images/download.svg" alt=""></span></p> -->
          <span class="box"
            ><img
              class="download-img"
              src="../assets/images/download.svg"
              alt="" />
            <p v-html="r.accept"></p
          ></span>
        </a>
        <!-- <a :href="cdn + reestr.doc"><span><img class="download-img" src="../assets/images/download.svg" alt=""></span>Сертификат</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "../components/aboutUsHeader.vue";
import AboutBack from "../components/AboutBack.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { aboutUsHeader, AboutBack },
  data() {
    return {
      hold: [],
      reestr: [],
    };
  },
  created() {
    axios
      .get("V1/share_holders")
      .then((response) => {
        this.hold = response.data.Shareholders;
        this.reestr = response.data.Register;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1440px) and (max-width: 2560px) {
    @content;
  }
}
.holder {
  background: #efefef;
  padding-top: 70px;
  padding-bottom: 50px;
  &-par {
    display: flex;
    a {
      text-decoration: none;
    }
    p {
      padding-right: 40px;
      @include fontStyle(Roboto, normal, 300, 24px, 29px, #373737);
      &:hover,
      :focus {
        font-weight: 600;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 2px solid;
        border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47%
          0%;
      }
    }
  }
  &-text {
    @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    padding-top: 60px;
    padding-bottom: 30px;
  }
  &-card {
    background-color: #ffffff;
    padding: 20px;
    p {
      @include fontStyle(Roboto, normal, 300, 20px, 24px, #373737);
      text-decoration-line: underline;
    }
    .box {
      display: flex;
      align-items: center;
    }
  }
  @include mobile() {
    padding-top: 15px;
    padding-bottom: 15px;
    &-par {
      flex-wrap: wrap;
      p {
        font-size: 12px;
        line-height: 15px;
        padding-top: 15px;
        font-weight: 500;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        width: 100%;
      }
      &__links {
        display: flex;
        flex-wrap: wrap;
        a {
          width: 100%;
          @include fontStyle(Roboto, normal, 300, 10px, 12px, #373737);
          padding-bottom: 13px;
          img {
            // padding-right: 9px;
            width: 19px;
          }
          &:hover,
          &:active {
            font-weight: 400;
            text-decoration: underline;
          }
        }
      }
    }
    &-text,
    &-card {
      display: none;
    }
  }
  @include tablet() {
    padding-top: 15px;
    padding-bottom: 15px;
    &-par {
      flex-wrap: wrap;
      p {
        font-size: 14px;
        line-height: 17px;
        padding-top: 15px;
        font-weight: 500;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        width: 100%;
      }
      &__links {
        display: flex;
        flex-wrap: wrap;
        a {
          width: 100%;
          @include fontStyle(Roboto, normal, 300, 12px, 14px, #373737);
          padding-bottom: 13px;
          img {
            padding-right: 9px;
          }
          &:hover,
          &:active {
            font-weight: 400;
            text-decoration: underline;
          }
        }
      }
    }
    &-text,
    &-card {
      display: none;
    }
  }
  @include tablet-1024() {
    padding-top: 25px;
    &-par {
      p {
        font-size: 18px;
        line-height: 23px;
        padding-top: 20px;
        border-bottom: 2px solid currentColor;
        &:hover,
        :focus {
          font-weight: 600;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 2px solid;
          border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47%
            0%;
        }
      }
      &__links {
        display: none;
      }
    }
  }
  @include desktop() {
    &-par {
      p {
        border-bottom: 2px solid currentColor;
        &:hover {
          font-weight: 600;
          border-bottom: 2px solid violet;
        }
      }
      &__links {
        display: none;
      }
    }
  }
  @include desktop-max() {
    &-par {
      p {
        border-bottom: 2px solid currentColor;
        &:hover {
          font-weight: 600;
          border-bottom: 2px solid violet;
        }
      }
      &__links {
        display: none;
      }
    }
  }
}
</style>
