<template>
    <div>
        <about-us-header/>
        <about-back/>
        <about-buttons/>
    </div>
</template>

<script>
import aboutBack from '@/components/AboutBack.vue'
import aboutUsHeader from '../components/aboutUsHeader.vue'
import aboutButtons from '../components/aboutButtons.vue'
export default {
    components: {aboutUsHeader,aboutBack,aboutButtons}
}
</script>