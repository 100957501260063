<template>
    <div class="trust">
        <div class="container">
            <swiper
                :slidesPerView="5"
                :spaceBetween="10"
                :freeMode="true"
                :centeredSlides="false"
                :autoplay="{
                delay: 1500,
                disableOnInteraction: false,
                }"
                :pagination="false"
                :navigation="false"
                :modules="modules"
                class="mySwiper">
                <swiper-slide v-for="trs in trust" :key="trs">
                    <div class="box">
                        <img :src="cdn + trs.icon" alt="">
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import axios from 'axios'
import {mapState} from 'vuex';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
        modules: [Autoplay, Pagination, Navigation],
        };
    },
    data() {
        return {
            trust: null,
            trustTitle: null,
            errors: [],
        }
    },
    created() {
        axios.get('V1/partners')
        .then(response => {
            this.trust = response.data.data
            this.trustTitle = response.data.data.title[0]
            // console.log(response)
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
    font-family: $family;
    font-style: normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $lheight;
    color: $color;
}
@mixin mobile {
    @media(min-width: 320px) and (max-width: 425px) {
        @content;
    }
}
@mixin tablet {
    @media(min-width: 425px) and (max-width: 767px) {
        @content;
    }
}
@mixin tablet-1024 {
    @media(min-width: 767px) and (max-width: 1024px) {
        @content;
    }
}
@mixin desktop {
    @media(min-width: 1024px) and (max-width: 1440px) {
        @content;
    }
}
@mixin desktop-max {
    @media(min-width: 1620px) and (max-width: 2560px) {
        @content;
    }
}
.trust {
    background: #EFEFEF;
    padding-bottom: 85px;
    @include mobile() {
        padding-bottom: 25px;
    } 
    @include tablet() {
        padding-bottom: 15px;
    }
    @include tablet-1024() {
        padding-bottom: 30px;
    }
}
.box {
    width: 233px;
    height: 233px;
    background: #FFFFFF;
    border-radius: 15px;
    h5 {
        @include fontStyle(Roboto, normal, 500, 24px, 31px, #000000);
        text-transform: uppercase;
        text-align: left;
        padding: 55px 48px;
    }
    img {
        max-width: 170px;
        width: 100%;
        position: absolute;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        padding-left: 12px;
        padding-right: 12px;
    }
    @include mobile() {
        width: 64px;
        height: 64px;
        h5 {
            font-size: 6px;
            line-height: 7px;
            padding: 10px 5px;
        }
        img {
            padding-left: 3px;
            padding-right: 3px;
        }
    }
    @include tablet() {
        width: 72px;
        height: 72px;
        h5 {
            font-size: 8px;
            line-height: 10px;
            padding: 10px 5px;
        }
        img {
            padding-left: 3px;
            padding-right: 3px;
        }
    }
    @include tablet-1024() {
        height: 130px;
        h5 {
            font-size: 13px;
            line-height: 15px;
            padding: 10px 5px;
        }
    }
}
.swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    border-radius: 15px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    @include mobile() {
        border-radius: 3px;
    }
    @include tablet() {
        border-radius: 5px;
    }
}
</style>
