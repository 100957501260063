<template>
  <div>
    <div class="reverse1">
      <div class="client">
        <!-- <div class="container">
                    <h2 class="client-title" >Что говорят наши клиенты</h2>
                </div> -->
        <client-say />
        <SliderMain2 />
        <div class="cont">
          <review-slide />
        </div>
      </div>
      <div class="question">
        <div class="container">
          <div class="question-cards">
            <a href="FAQabout">
              <img src="../assets/images/question.svg" alt="" />
              <h5 class="question-cards-title">
                FAQ
                <span class="d_none"
                  ><img src="@/assets/images/arrow-little.svg" alt=""
                /></span>
              </h5>
              <p class="question-cards-par">{{ $t("buttons.faq") }}</p>
            </a>
          </div>
          <div class="question-cards">
            <a href="jobAbout">
              <img src="../assets/images/sound.svg" alt="" />
              <h5 class="question-cards-title">
                {{ $t("buttons.vac") }}
                <span class="d_none"
                  ><img src='@/assets/images/arrow-little.svg' alt=""
                /></span>
              </h5>
              <p class="question-cards-par">{{ $t("buttons.vacDesc") }}</p>
            </a>
          </div>
          <div class="question-cards">
            <a href="digitalAbout">
              <img src="../assets/images/project.svg" alt="" />
              <h5 class="question-cards-title">
                {{ $t("about.dig") }}
                <span class="d_none"
                  ><img src="@/assets/images/arrow-little.svg" alt=""
                /></span>
              </h5>
              <p class="question-cards-par">
                {{ $t("buttons.dig") }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <div class="container">
        <h2 class="video-title" v-html="title"></h2>
      </div>
      <!-- <slide-video class="m_none1"/> -->
      <VideoCompany class="m_none1" />
      <!-- <div class="d_none1"><video-slide/></div> -->
      <mobile-video class="d_none1" />
    </div>
  </div>
</template>

<script>
import SliderMain2 from "./SliderMain2.vue";
import SliderMain3 from "./SliderMain3.vue";
import reviewSlide from "../components/box/reviewSlide.vue";
import videoSlide from "../components/box/videoSlide.vue";
import slideVideo from "@/components/slideVideo.vue";
import clientSay from "./clientSay.vue";
import axios from "axios";
import { mapState } from "vuex";
import VideoCompany from "./VideoCompany.vue";
import mobileVideo from "./mobileVideo.vue";
export default {
  name: "ClientMain",
  components: {
    SliderMain2,
    SliderMain3,
    reviewSlide,
    videoSlide,
    slideVideo,
    clientSay,
    VideoCompany,
    mobileVideo,
  },
  data() {
    return {
      title: [],
    };
  },
  created() {
    axios
      .get("V1/company-videos")
      .then((response) => {
        this.title = response.data.big_title;
      })
      .catch((e) => {
        this.error.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 424px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
.cont {
  display: none;
  @include mobile() {
    display: block;
  }
  @include tablet() {
    display: block;
  }
}
.reverse1 {
  @include mobile() {
    display: flex;
    flex-direction: column-reverse;
  }
  @include tablet() {
    display: flex;
    flex-direction: column-reverse;
  }
}
.client {
  // padding-top: 100px;
  padding-bottom: 115px;
  background: #efefef;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 49px, #373737);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 35px;
  }
  @include mobile() {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    &-title {
      display: none;
    }
  }
  @include tablet() {
    padding-top: 30px;
    padding-bottom: 30px;
    &-title {
      display: none;
    }
  }
  @include tablet-1024() {
    padding-top: 0px;
  }
}
.question {
  padding-top: 114px;
  padding-bottom: 100px;
  .container {
    display: flex;
    justify-content: space-between;
  }
  &-cards {
    background: #efefef;
    // backdrop-filter: blur(35px);
    border-radius: 15px;
    width: 300px;
    height: 228px;
    padding: 25px;
    margin-right: 10px;
    a {
      text-decoration: none;
    }
    &:hover {
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.2);
    }
    &:last-child {
      max-width: 580px;
      width: 100%;
    }
    &-title {
      @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      padding-top: 15px;
      padding-bottom: 8px;
      span {
        img {
          width: 6px;
        }
      }
    }
    &-par {
      @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    }
  }
  @include mobile() {
    padding-top: 5px !important;
    padding-bottom: 59px !important;
    .container {
      justify-content: space-around;
    }
    padding-top: 0px;
    padding-bottom: 43px;
    &-cards {
      width: 90px;
      height: 55px;
      padding: 0;
      background: transparent;
      text-align: center;
      margin-left: 0px;
      margin-right: -10px;
      &:hover {
        box-shadow: none;
      }
      &-title {
        font-size: 14px;
        line-height: 16px;
        padding-top: 3px;
      }
      img {
        width: 38px;
      }
      &:first-child {
        width: 90px;
        height: 55px;
        padding: 0;
        order: 3;
        img {
          width: 37px;
        }
        span {
          img {
            width: 7px;
          }
        }
      }
      &:last-child {
        width: 90px;
        height: 55px;
      }
      &-par {
        display: none;
      }
      span {
        img {
          width: 7px;
        }
      }
    }
  }
  @include tablet() {
    padding-top: 5px !important;
    padding-bottom: 59px !important;
    .container {
      justify-content: space-between;
      width: 85%;
    }
    padding-top: 0px;
    padding-bottom: 43px;
    &-cards {
      width: 90px;
      height: 55px;
      padding: 0;
      background: transparent;
      text-align: center;
      margin-left: 0px;
      margin-right: -10px;
      &:hover {
        box-shadow: none;
      }
      &-title {
        font-size: 14px;
        line-height: 16px;
        padding-top: 3px;
      }
      img {
        width: 38px;
      }
      &:first-child {
        width: 90px;
        height: 55px;
        padding: 0;
        order: 3;
        img {
          width: 37px;
        }
        span {
          img {
            width: 7px;
          }
        }
      }
      &:last-child {
        width: 90px;
        height: 55px;
      }
      &-par {
        display: none;
      }
      span {
        img {
          width: 7px;
        }
      }
    }
  }
  @include tablet-1024() {
    &-cards {
      &-title {
        font-size: 18px;
      }
      &-par {
        font-size: 14px;
        line-height: 12px;
      }
    }
  }
  @include desktop() {
    &-cards {
      &-title {
        font-size: 18px;
      }
      &-par {
        font-size: 14px;
        line-height: 12px;
      }
    }
  }
}
.video {
  margin-bottom: 110px;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    // background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    text-align: center;
    margin-bottom: 40px;
  }
  @include mobile() {
    margin-bottom: 30px;
    &-title {
      padding-top: 25px;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 14px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 34.97%,
        #1b27ff 87%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
      span {
        display: none;
      }
    }
  }
  @include tablet() {
    margin-bottom: 30px;
    &-title {
      margin-top: 35px;
      // margin-bottom: -25px !important;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      span {
        display: none;
      }
    }
  }
  @include tablet-1024() {
    &-title {
      font-size: 22px;
      line-height: 28px;
    }
  }
}
</style>
