<template>
    <div>
        <about-us-header/>
        <about-back/>
        <div class="container">
            <div class="work">
                <p class="work-title" v-html="about[1].text"></p>
                <p class="work-sub" v-html="about[2].text"></p>
                <p class="work-par" v-html="about[3].text"></p>
                <p class="work-par" v-html="about[4].text"></p>
                </div>
        </div>
        <history class="m_none"/>
        <history-mobile class="d_none"/>
        <div class="slide-team">
            <Director/>
            <Owner/>
            <!-- <director-team/> -->
            <!-- <slide-team/> -->
        </div>
        <div class="lead-wrap">
            <lead-slider/>
            <lead-slider-2/>
        </div>
        <div class="rating-slide d_none">
            <rating-slide/>
        </div>
        <rating/>
        <trust-slide/>
        <trust-bottom-slide/>
        <client-say/>
        <div class="wrap">
            <slider-main-2/>
            <div class="d_none">
                <review-slide/>
            </div>
        </div>
    </div>
</template>

<script>
import AboutBack from '@/components/AboutBack.vue'
import AboutUsHeader from '../components/aboutUsHeader.vue'
import SliderMain2 from '../components/SliderMain2.vue'
import RatingSlide from '../components/ratingSlide.vue'
import rating from '../components/rating.vue'
import TrustSlide from '../components/trustSlide.vue'
import TrustBottomSlide from '../components/trustBottomSlide.vue'
import leadSlider from '../components/leadSlider.vue'
import LeadSlider2 from '@/components/leadSlider2.vue'
import history from '../components/history.vue'
import historyMobile from '../components/historyMobile.vue'
import SlideTeam from '../components/SlideTeam.vue'
import directorTeam from '../components/directorTeam.vue'
import reviewSlide from '../components/box/reviewSlide.vue'
import axios from 'axios'
import { mapState } from 'vuex'
import ClientSay from '@/components/clientSay.vue'
import Director from '@/components/Director.vue'
import Owner from '@/components/Owner.vue'

export default {
    components: { AboutUsHeader, SliderMain2, AboutBack, RatingSlide, rating, TrustSlide, TrustBottomSlide, leadSlider, LeadSlider2, history, SlideTeam, directorTeam, reviewSlide, historyMobile, ClientSay, Director, Owner },
    methods: {
        tabButton(t){
            if(t == null){
                // console.log('test',this.tabs[0])
                this.tabs[0] = t
            }
            else {
            this.tabContent = t
            // console.log("tab",t)
            }
        },
    },
    data() {
        return {
            about: [],
        }
    },
    created() { 
        axios.get('V1/abouts')
        .then(response => {
            this.about = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 767px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 767px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    .slide-team {
        background: #efefef;
        @include mobile() {
            display: none;
        }
        @include tablet() {
            display: none;
        }
    }
    .lead-wrap {
        display: none;
        @include mobile(){
            display: block;
        }
        @include tablet(){
            display: block;
        }
    }
    .show {
        display: flex;
        justify-content: flex-end;
        transition-delay: 3000ms;
    }
    .card-line {
        border-right: 1px dashed #959595;
        margin-top: -10px;
        margin-left: -30px;
        @include mobile() {
            margin-top: -20px;
            margin-left: -5px;
            margin-right: -10px;
        }
        @include tablet() {
            margin: -10px -10px 10px -10px;
        }
        @include tablet-1024() {
            margin: -10px 0px 10px -10px;
        }
    }
    .card-body {
        margin-top: 50px;
        padding: 15px 0px 15px 30px;
        max-width: 900px;
        border-radius: 15px !important;

        background-clip: padding-box;
        border:1px solid 1px transparent;
        &:before {
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
            background: linear-gradient(to left,#1B27FF 20%,#C600DD 35%, #FF143F 100%);
        }
        @include mobile() {
            margin-top: 10px;
            ul {
                margin-bottom: 0;
                li {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
        @include tablet() {
            margin-top: 10px;
            padding-right: 5px;
            ul {
                margin-bottom: 0;
                li {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
        @include tablet-1024() {
            margin-top: 30px;
            padding-right: 5px;
            ul {
                margin-bottom: 0;
                li {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
        ul {
            li {
                @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
                &::marker {
                    color: #959595;
                }
            }
        }
    .work {
        &-title {
            @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
            letter-spacing: 0.03em;
            text-transform: uppercase;
            margin-top: 75px;
            margin-bottom: 20px;
        }
        &-sub {
            @include fontStyle(Roboto, normal, 400, 24px, 29px, transparent);
            background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
        &-par {
            @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
            padding-bottom: 10px;
            span {
                font-weight: bold;
            }
            a {
                text-decoration: none;
            }
        }
        @include mobile() {
            &-title {
                display: none;
            }
            &-sub {
                font-size: 14px;
                line-height: 17px;
                padding-top: 25px;
                font-weight: 500;
                width: 70%;
                margin-bottom: 0;
                padding-bottom: 10px;
            }
            &-par,span {
                font-size: 14px;
                line-height: 17px;
                // font-weight: 400;
                margin-bottom: 0;
            }
        }
        @include tablet() {
            &-title {
                display: none;
            }
            &-sub {
                font-size: 14px;
                line-height: 17px;
                padding-top: 20px;
            }
            &-par,span {
                font-size: 14px;
                line-height: 17px;
            }
        }
        @include tablet-1024() {
            &-title {
                display: none;
            }
            &-sub {
                font-size: 12px;
                line-height: 15px;
                padding-top: 20px;
            }
            &-par {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }
    .history {
        padding-top: 200px;
        &-card {
            text-align: center;
            margin-right: -20px;
        }
        &-title {
            @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
            letter-spacing: 0.03em;
            text-transform: uppercase;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        &-line {
            // position: relative;
            z-index: 0;
            margin-bottom: -190px;
            height: 3px;
            background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
            opacity: 1;
        }
        &-year {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            p {
                @include fontStyle(Roboto, normal, 400, 20px, 24px, #959595);
            }
            h5 {
                @include fontStyle(Roboto, normal, 400, 65px, 77px, #959595);
            }
        }
        @include mobile() {
            padding-top: 50px;
            &-title {
                display: none;
            }
            &-card {
                button {
                    margin-top: -5px;
                }
            }
            &-year {
                p {
                    font-size: 8px;
                    line-height: 10px;
                    margin-bottom: 0;
                    margin-top: 25px;
                }
                h5 {
                    font-size: 14px;
                    line-height: 17px;
                    margin-top: 15px;
                }
                &-1 {
                    button {
                        margin-top: -23px;
                    }
                }
            }
            &-line {
                margin-bottom: -49px;
            }
        }
        @include tablet() {
            padding-top: 50px;
            &-title {
                display: none;
            }
            &-card {
                button {
                    margin-top: -5px;
                }
            }
            &-year {
                p {
                    font-size: 12px;
                    line-height: 14px;
                    margin-bottom: 0;
                    margin-top: 20px;
                }
                h5 {
                    font-size: 22px;
                    line-height: 24px;
                    margin-top: 15px;
                }
                &-1 {
                    button {
                        margin-top: -27px;
                    }
                }
            }
            &-line {
                margin-bottom: -51px;
            }
        }
        @include tablet-1024() {
            &-line {
                margin-bottom: -150px;
            }
            &-year {
                p {
                    font-size: 22px;
                    margin-bottom: 0;
                }
                h5 {
                    font-size: 60px;
                }
                &-1 {
                    margin-top: -20px;
                }
            }
        }
        // @include desktop() {
        //     &-box {
        //         height: 140px;
        //     }
        // }
        // @include desktop-max() {
        //     &-box {
        //         height: 140px;
        //     }
        // }
    }
</style>

