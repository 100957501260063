<template>
    <div>
        <head-bottom/>
        <div class="owner">
            <div class="container">
                <div class="owner-cards">
                    <div class="owner-cards-left">
                        <p class="owner-cards-left__title" v-html="accident[1].sub_cats[0].title"></p>
                        <p class="owner-cards-left__sub" v-html="accident[1].sub_cats[0].desc"></p>
                        <div class="owner-cards-left__btn m_none">
                            <polis-button :p_name="p_name"/>
                            <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
                        </div>
                    </div>
                    <div class="owner-cards-right">
                        <!-- <img :src="cdn + accident.icon" alt=""> -->
                        <img src="../assets/images/health2.png" alt="">
                        <div class="owner-cards-left__btn-mob d_none">
                            <polis-button :p_name="p_name"/>
                            <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="about">
                <div class="about-list">
                    <p class="about-list__title" v-html="accident[1].sub_cats[1].title"></p>
                    <p class="about-list__par" v-html="accident[1].sub_cats[1].desc"></p>
                </div>
                <div class="about-list">
                    <p class="about-list__title" v-html="accident[1].sub_cats[2].title"></p>
                    <p class="about-list__par" v-html="accident[1].sub_cats[2].desc"></p>
                </div>
                <div class="about-btn">
                    <time-btn :p_name="p_name"/>
                    <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
                </div>
            </div>
        </div>
        <accordion/>
        <question :p_name="p_name"/>
        <SliderMain1 :thirdblock = "thirdblock" class="m_none"/>
        <slider-main-mobile-2 class="d_none"/>
    </div>
</template>

<script>
import Question from '../components/Question.vue'
import HeadBottom from '../components/HeadBottom.vue'
import SliderMain1 from '../components/SliderMain1-1.vue'
import SliderMainMobile2 from '@/components/SliderMainMobile2.vue'
import accordion from '../components/accordion.vue'
import axios from 'axios'
import PolisButton from '@/components/PolisButton.vue'
import timeBtn from '@/components/timeBtn.vue'
import {mapState} from 'vuex'
export default {
    components: {HeadBottom, Question, SliderMain1,accordion, PolisButton, timeBtn,SliderMainMobile2},
    data() {
        return {
            p_name: 'Страхование от несчастного случая (НС)',
            accident: [],
        }
    },
    created() {
        axios.get('V1/business/health')
        .then(response => {
            this.accident = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>

    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1441px) and (max-width: 2560px) {
            @content;
        }
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    .owner {
        background: #EFEFEF;
        padding-top: 50px;
        padding-bottom: 30px;
        &-cards {
            display: flex;
            justify-content: space-between;
            &-left {
                &__title {
                    @include fontStyle(Roboto, normal, 500, 36px, 43px, transparent);
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                &__sub {
                    @include fontStyle(Roboto, normal, 400, 24px, 36px, #373737);
                    text-transform: uppercase;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
                &__btn, &__btn-mob {
                    button {
                        background: #FFFFFF;
                        border-radius: 15px;
                        width: 200px;
                        height: 70px;
                        border: none;
                        color: #373737;
                        @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
                        &:hover {
                            background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
                            color: #FFFFFF;
                        }
                    }
                }
            }
            &-right {
                width: 35%;
                img {
                    width: 100%;
                    min-width: 300px;
                }
            }
        }
        @include mobile() {
            padding-top: 20px;
            padding-bottom: 20px;
            &-cards {
                flex-wrap: wrap;
                &-left {
                    margin: 0 auto;
                    &__title {
                        width: 100%;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: left;
                    }
                    &__sub {
                        font-size: 12px;
                        line-height: 14px;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    &__btn-mob {
                        text-align: center;
                        button {
                            border-radius: 3px;
                            width: 96px;
                            height: 28px;
                            font-size: 9px;
                        }
                    }
                }
                &-right {
                    text-align: center;
                    width: 100%;
                    img {
                        min-width: 200px;
                        margin-top: 10px;
                        width: 50%;
                    }
                }
            }
        }
        @include tablet() {
            padding-top: 20px;
            padding-bottom: 20px;
            &-cards {
                flex-wrap: wrap;
                &-left {
                    margin: 0 auto;
                    &__title {
                        text-align: center;
                        width: 100%;
                        font-size: 22px;
                        line-height: 24px;
                    }
                    &__sub {
                        font-size: 16px;
                        line-height: 22px;
                        padding-bottom: 0;
                        padding-top: 0;
                    }
                    &__btn {
                        text-align: center;
                        button {
                            border-radius: 3px;
                            width: 116px;
                            height: 38px;
                            font-size: 10px;
                        }
                    }
                }
                &-right {
                    text-align: center;
                    width: 100%;
                    img {
                        margin-top: 10px;
                        width: 50%;
                    }
                }
            }
        }
        @include tablet-1024() {
            &-cards {
                &-right {
                    img {
                        min-width: 200px;
                        width: 50%;
                    }
                }
                &-left {
                    width: 70%;
                    &__title {
                        font-size: 18px;
                        line-height: 20px;
                    }
                    &__sub {
                        font-size: 12px;
                        line-height: 18px;
                    }
                    &__btn {
                        button {
                            width: 170px;
                            height: 50px;
                            border-radius: 7px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        @include desktop() {
            &-cards {
                &-left {
                    width: 70%;
                    &__title {
                        font-size: 24px;
                        line-height: 28px;
                    }
                    &__sub {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
        @include desktop-max() {
            padding-top: 79px;
            padding-bottom: 70px;
        }
    }
    .about {
        padding-top: 85px;
        &-list {
            padding-bottom: 35px;
            &__title {
                margin-bottom: 0;
                @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
                letter-spacing: 0.03em;
                text-transform: uppercase;
                padding-bottom: 10px;
            }
            &__par {
                margin-bottom: 0;
                @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
            }
        }
        &-btn {
            button {
                background: #eFeFeF;
                border-radius: 15px;
                width: 200px;
                height: 70px;
                border: none;
                color: #373737;
                @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
                &:hover {
                    background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
                    color: #FFFFFF;
                }
            }
        }
        @include mobile() {
            padding-top: 30px;
            &-list {
                padding-bottom: 35px;
                &__title {
                    font-size: 14px;
                    line-height: 20px;
                    padding-bottom: 0;
                }
                &__par {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            &-btn {
                text-align: center;
                margin-bottom: 20px;
                button {
                    width: 130px;
                    height: 50px;
                    border-radius: 7px;
                    font-size: 12px;
                }
            }
        }
        @include tablet() {
            padding-top: 30px;
            &-list {
                padding-bottom: 35px;
                &__title {
                    font-size: 14px;
                    line-height: 20px;
                    padding-bottom: 0;
                }
                &__par {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            &-btn {
                text-align: center;
                margin-bottom: 20px;
                button {
                    width: 130px;
                    height: 50px;
                    border-radius: 7px;
                    font-size: 12px;
                }
            }
        }
        @include tablet-1024() {
            padding-top: 30px;
            &-list {
                padding-bottom: 35px;
                &__title {
                    font-size: 18px;
                    line-height: 20px;
                    padding-bottom: 0;
                }
                &__par {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            &-btn {
                text-align: center;
                button {
                    width: 150px;
                    height: 50px;
                    border-radius: 8px;
                    font-size: 12px;
                }
            }
        }
    }
</style>