<template>
    <div class="lead">
        <h4 class="lead-title" v-html="directorDesc1"></h4>
        <swiper
            :spaceBetween="30"
            :centeredSlides="true"
            :pagination="{
                clickable: true,
            }"
            :navigation="false"
            :modules="modules"
            class="mySwiper">
            <swiper-slide v-for="(dr, id) in director" :key="id">
                <div class="lead-card">
                    <img :src="cdn + dr.image" alt="">
                    <h5 class="lead-card__name" v-html="dr.title"></h5>
                    <h5 class="lead-card__st" v-html="dr.position"></h5>
                    <div class="lead-card__inp">
                        <div class="lead-card__par" :class="{isActive : more.includes(id)}">
                            <p class="texts" v-html="dr.description"></p>
                        </div>
                        <button class="read-more-button" v-if="!more.includes(id)" @click="readMore(id)">{{ $t("par.more") }}</button>
                        <button class="read-more-button" v-else @click="readLess(id)">{{ $t("par.less") }}</button>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from 'axios'
import { mapState } from "vuex";
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
        director: [],
        directorDesc1: [],
        more : []
    }
  },
  methods: {
    readMore(id) {
      this.more.push(id)
    },
    readLess(id) {
      this.more = this.more.filter(item => item !== id);
    }
  },
  created() {
    axios.get('V1/company-managements')
      .then(response => {
        this.director = response.data.data.directors
        this.directorDesc1 = response.data.desc_1
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  computed :{
      ...mapState(['cdn']),
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
    font-family: $family;
    font-style: normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $lheight;
    color: $color;
}
.isActive {
    max-height: none !important;
}
.lead {
    background: #EFEFEF;
    padding-top: 40px;
    margin-top: 10px;
    padding-bottom: 0px;
    &-title {
        @include fontStyle(Roboto, normal, 500, 14px, 17px, #373737);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        text-align: center;
    }
    &-card {
        background: #EFEFEF;
        img {
            // width: 131px;
            width: clamp(131px,50%,170px);
            min-height: 150px;
            max-height: 180px;
            border-radius: 15px;
        }
        &__name {
            @include fontStyle(Roboto, normal, 500, 14px, 17px, #373737);
            background: linear-gradient(90deg, #FF143F 31%, #C600DD 49.97%, #1B27FF 62%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            margin-top: 7px;
            margin-bottom: 3px;
        }
        &__st {
            @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
        }
        &__more {
            @include fontStyle(Roboto, normal, 300, 12px, 14px, #959595);
            text-decoration-line: underline;
            margin-bottom: 25px;
            cursor: pointer;
        }
        &__par {
            // width: 90%;
            // margin: 0 auto;
            // max-height: 0px;
            max-height: 0px;
            overflow: hidden;
            position: relative;
            margin-bottom: -12px;
            p {
                @include fontStyle(Roboto, normal, 400, 14px, 17px, #373737);
            }
            .bottom {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0px;
                opacity: 1;
                transition: 3000ms;
            }
        }
        &__inp {
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
            padding-bottom: 14px;
            .read-more-button {
                cursor: pointer;
                display: inline-block;
                margin-bottom: 25px;
                @include fontStyle(Roboto, normal, 300, 12px, 10px, #959595);
                text-decoration-line: underline;
                cursor: pointer;
                background-color: transparent;
                border: none;
            }
        }
    }
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #EFEFEF;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
    margin: 0 auto;
    display: block;
    object-fit: cover;
    border-radius: 15px;
}

</style>