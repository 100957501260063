<template>
  <div>
    <head-individ />
    <another-site />
    <div class="container">
      <div class="houseIndivid">
        <div class="houseIndivid-left">
          <p class="houseIndivid-left__title" v-html="propInd[0].title"></p>
          <p class="houseIndivid-left__sub" v-html="propInd[0].desc"></p>
          <div class="houseIndivid-left__type">
            <div class="houseIndivid-left__type_par">
              <p class="title" v-html="propInd[0].sub_type[0].title"></p>
              <p class="sub" v-html="propInd[0].sub_type[0].desc"></p>
            </div>
            <div class="houseIndivid-left__type_par">
              <p class="title" v-html="propInd[0].sub_type[1].title"></p>
              <p class="sub" v-html="propInd[0].sub_type[1].desc"></p>
            </div>
            <div class="houseIndivid-left__type_par">
              <p class="title" v-html="propInd[0].sub_type[2].title"></p>
              <p class="sub" v-html="propInd[0].sub_type[2].desc"></p>
            </div>
          </div>
          <div class="houseIndivid-left__btn white_hover__btn">
            <a :href="propInd[0].link"
              ><button>
                {{ $t("buttons.moreBtn1") }} <a>kupipolis.kz</a>
              </button></a
            >
          </div>
        </div>
        <div class="houseIndivid-left__btn__mobile white_hover__btn">
          <a :href="propInd[0].link"
            ><button>
              {{ $t("buttons.moreBtn1") }} <a>kupipolis.kz</a>
            </button></a
          >
        </div>
        <div class="houseIndivid-right">
          <img :src="cdn + propInd[0].icon" alt="" />
        </div>
      </div>
      <div class="houseIndivid">
        <div class="houseIndivid-left">
          <p class="houseIndivid-left__title" v-html="propInd[1].title"></p>
          <p class="houseIndivid-left__sub" v-html="propInd[1].desc"></p>
          <div class="houseIndivid-left__type">
            <div class="houseIndivid-left__type_par">
              <p class="title" v-html="propInd[1].sub_type[0].title"></p>
              <p class="sub" v-html="propInd[1].sub_type[0].desc"></p>
            </div>
            <div class="houseIndivid-left__type_par">
              <p class="title" v-html="propInd[1].sub_type[1].title"></p>
              <p class="sub" v-html="propInd[1].sub_type[1].desc"></p>
            </div>
            <div class="houseIndivid-left__type_par">
              <p class="title" v-html="propInd[1].sub_type[2].title"></p>
              <p class="sub" v-html="propInd[1].sub_type[2].desc"></p>
            </div>
          </div>
          <div class="houseIndivid-left__btn white_hover__btn">
            <a :href="propInd[1].link"
              ><button>
                {{ $t("buttons.moreBtn1") }} <a>kupipolis.kz</a>
              </button></a
            >
          </div>
        </div>
        <div class="houseIndivid-left__btn__mobile white_hover__btn">
          <a :href="propInd[1].link"
            ><button>
              {{ $t("buttons.moreBtn1") }} <a>kupipolis.kz</a>
            </button></a
          >
        </div>
        <div class="houseIndivid-right">
          <img :src="cdn + propInd[1].icon" alt="" />
        </div>
      </div>
    </div>
    <service-main />
  </div>
</template>

<script>
import HeadIndivid from "../components/HeadIndivid.vue";
import anotherSite from "../components/anotherSite.vue";
import ServiceMain from "../components/ServiceMain.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { HeadIndivid, anotherSite, ServiceMain },
  data() {
    return {
      propInd: [],
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/individual-insurance-property")
      .then((response) => {
        this.propInd = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1440px) and (max-width: 2560px) {
    @content;
  }
}
.houseIndivid {
  background: #efefef;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 85px;
  margin-bottom: 70px;
  padding: 65px 40px 60px 60px;
  &-left {
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
    &__sub {
      @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    }
    &__type {
      margin-top: 40px;
      display: flex;
      // justify-content: space-between;
      &_par {
        margin-right: 15px;
        &:nth-child(2) {
          margin-left: 15px;
          margin-right: 85px;
        }
        .title {
          @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
        }
        .sub {
          @include fontStyle(Roboto, normal, 300, 16px, 19px, #373737);
        }
      }
    }
    &__btn {
      &__mobile {
        display: none;
      }
      button {
        width: 280px;
        height: 69px;
        background-color: #fff;
        a {
          color: #8948b1;
        }
        &:hover {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &-right {
    img {
      width: 100%;
      min-width: 155px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
  }
  @include mobile() {
    margin: 30px 0;
    flex-wrap: wrap;
    border-radius: 3px;
    padding: 30px;
    &-left {
      &__title {
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 5px;
      }
      &__sub {
        font-size: 14px;
        line-height: 14px;
      }
      &__type {
        margin-top: 10px;
        flex-wrap: wrap;
        &_par {
          margin-left: 0;
          &:nth-child(2) {
            margin-left: 0;
          }
          .title {
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            padding-bottom: 5px;
          }
          .sub {
            font-size: 14px;
            line-height: 14px;
            margin-top: -14px;
          }
        }
      }
      &__btn {
        display: none;
        &__mobile {
          display: block;
          text-align: center;
          margin: 0 auto;
          margin-top: 10px;
          // margin-bottom: -15%;
          order: 3;
          border-radius: 3px;
          button {
            background-color: #fff;
            min-width: 200px;
            height: 34px;
            border-radius: 3px;
            font-size: 9px;
            line-height: 12px;
            a {
              color: #8948b1;
            }
          }
        }
      }
    }
    &-right {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      img {
        min-width: 122px;
        width: 144px;
        margin-top: 10px;
      }
    }
  }
  @include tablet() {
    margin-top: 30px;
    flex-wrap: wrap;
    border-radius: 3px;
    padding: 20px;
    &-left {
      width: 100%;
      &__title {
        font-size: 16px;
        line-height: 17px;
      }
      &__sub {
        font-size: 14px;
        line-height: 14px;
      }
      &__type {
        margin-top: 10px;
        flex-wrap: wrap;
        &_par {
          margin-left: 0 !important;
          .title {
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
          }
          .sub {
            font-size: 14px;
            line-height: 14px;
            margin-top: -14px;
          }
        }
      }
      &__btn {
        display: none;
        &__mobile {
          display: block;
          text-align: center;
          margin: 0 auto;
          margin-top: 10px;
          margin-bottom: -12%;
          order: 3;
          border-radius: 3px;
          button {
            background-color: #fff;
            min-width: 200px;
            height: 34px;
            border-radius: 3px;
            font-size: 9px;
            line-height: 12px;
            a {
              color: #8948b1;
            }
          }
        }
      }
    }
    &-right {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      img {
        min-width: 122px;
        width: 144px;
        margin-top: 10px;
      }
    }
  }
  @include tablet-1024() {
    flex-wrap: wrap;
    &-left {
      &__title {
        font-size: 30px;
        line-height: 35px;
      }
      &__sub {
        padding-top: 20px;
        font-size: 12px;
        line-height: 16px;
      }
      &__btn {
        button {
          width: 240px;
          height: 50px;
          border-radius: 8px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      &__type {
        .title {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 0;
        }
        .sub {
          font-size: 10px;
          line-height: 13px;
        }
      }
    }
    &-right {
      margin: 0 auto;
      img {
        min-width: 162px;
        width: 162px;
        margin-top: 30px;
      }
    }
  }
  @include desktop() {
    &-left {
      &__title {
        font-size: 28px;
        line-height: 33px;
        padding-bottom: 10px;
      }
      &__sub {
        font-size: 17px;
        line-height: 19px;
      }
      &__type {
        &_par {
          width: 46%;
          margin-right: 35px;
          .title {
            font-size: 18px;
            line-height: 18px;
          }
          .sub {
            font-size: 13px;
            line-height: 14px;
          }
        }
      }
      &__btn {
        button {
          width: 200px;
          height: 50px;
          border-radius: 8px;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
  @include desktop-max() {
    &:nth-child(2) {
      // padding-bottom: 0;
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-left {
      width: 70%;
      &__type {
        &_par {
          &:first-child {
            width: 485px;
          }
        }
      }
    }
  }
}
</style>
