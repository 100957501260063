<template>
    <div class="team">
        <div class="container">
            <h4 class="team-title" v-html="job.big_title"></h4>
        </div>
        <swiper
            :effect="'coverflow'"
            :grabCursor="true"
            :centeredSlides="false"
            :slidesPerView="1"
            :coverflowEffect="{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
            }"
            :pagination="{
            clickable: true,
            }"
            :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
            }"
            :navigation="false"
            :modules="modules"
            class="mySwiper">
            <swiper-slide v-for="j in job.footer" :key="j">
                <img :src="cdn + j.icon" />
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


// import required modules
import {Autoplay,EffectCoverflow, Pagination, Navigation } from "swiper";
import axios from 'axios';
import { mapState } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
    data() {
        return {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            job: [],
        }
    },
    created() { 
        axios.get('V1/vacancy-info')
        .then(response => {
            this.job = response.data.data.second_block
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
  setup() {
    return {
      modules: [Autoplay,EffectCoverflow, Pagination, Navigation],
    };
  },
};
</script>


<style lang="scss" scoped>
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }

    @mixin tablet {
        @media(min-width: 425px) and (max-width: 767px) {
            @content;
        }
    }

    @mixin tablet-1024 {
        @media(min-width: 767px) and (max-width: 1024px) {
            @content;
        }
    }

    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }

    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    .container {
        @include mobile() {
            width: 85%;
        }
        @include tablet() {
            width: 85%;
        }
    }
    .swiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        @include mobile() {
            padding-top: 0;
            padding-bottom: 30px;
        }
        @include tablet() {
            padding-top: 0;
            padding-bottom: 30px;
        }
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 539px;
        // height: 331px;
        @include mobile() {
            width: 300px;
            // height: 185px;
        }
        @include tablet() {
            width: 300px;
            // height: 185px;
        }
        @include tablet-1024() {
            width: 180px;
            // height: 185px;
        }
        @include desktop() {
            width: 420px;
            // height: 260px;
        }
        // &-active {
        //     img {
        //         width: 150% !important;
        //     }
        // }
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        margin: 0 auto;
        @include mobile() {
            width: 75%;
        }
        @include tablet() {
            width: 65%;
        }
        @include tablet-1024() {
            width: 80%;
        }
    }
    .team {
        padding-top: 80px;
        padding-bottom: 80px;
        &-title {
            text-align: center;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #373737;
        }
        @include mobile() {
            padding-top: 30px;
            padding-bottom: 5px;
            &-title {
                font-size: 14px;
                line-height: 15px;
            }
        }
        @include tablet() {
            padding-top: 20px;
            padding-bottom: 20px;
            &-title {
                font-size: 14px;
                line-height: 17px;
            }
        }
        @include tablet-1024() {
            padding-top: 40px;
            padding-bottom: 20px;
            &-title {
                font-size: 17px;
                line-height: 22px;
            }
        }
    }
</style>