<template>
  <div>
    <div class="temporary-quest">
      <div class="temporary-quest__inner">
        <p class="temporary-quest__inner__par" v-html="sub"></p>
        <!-- <p class="temporary-quest__inner__par">ОстаЛИСЬ ВОПРОСЫ? <br> Оставьте заявку и мы перезвоним вам</p> -->
        <div class="temporary-quest__inner__inp">
          <!-- <input
            v-model="GuestName"
            type="text"
            class="name"
            placeholder="Имя"
          /> -->
          <Form class="err-message">
            <Field
            @click="IsRemove = false"
              v-model="GuestName"
              name="username"
              type="text"
              class="name"
              :placeholder="$t('placeholder.name')"
              :rules="requiredName"
              @keyup="onlyText()"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="username" />
            </div>
          </Form>
          <Form class="err-message">
            <Field
            @click="IsRemove = false"
              v-model="organization_name"
              type="text"
              :rules="requiredName"
              name="organization"
              class="mail mobile"
              aria-autocomplete="none"
              id=""
              :placeholder="$t('placeholder.company')"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="organization" />
            </div>
          </Form>
          <!-- <input v-model="GuestEmail" type="email" class="mail mobile" placeholder="E-mail"> -->
          <Form class="err-message">
            <Field
            @click="IsRemove = false"
              v-mask="'+7(7##)-###-##-##'"
              v-model="GuestPhone"
              type="text"
              name="phone"
              class="tel"
              :placeholder="$t('placeholder.phone')"
              :rules="requiredNum"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="phone" />
            </div>
          </Form>
        </div>
        <div class="temporary-quest__inner_btn">
          <button
            @click.prevent="Send"
            :disabled="disabled"
            :class="{isDisabled : disabled}"
            @click="buttonClicked"
          >
            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
            {{ $t("buttons.send") }}
          </button>
          <div class="checkbox">
            <input type="checkbox" name="" id="check" v-model="checked" />
            <label for="check"
              >{{ $t("modal.rule1") }}
              <a href="/condition"
                >{{ $t("modal.rule2") }}</a
              ></label
            >
          </div>
        </div>
        <div class="image_mobilka position_absolute d_none">
          <img src="../assets/images/girl2.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { createToaster } from "@meforma/vue-toaster";
// import * as Yup from "yup";
const toaster = createToaster({ position: "top-right" });
export default {
  props: ["p_name"],
  components: { Field, Form, ErrorMessage },
  data() {
    return {
      GuestName: null,
      GuestPhone: null,
      organization_name: null,
      resp: [],
      disabled: false,
      checked: false,
      loading: false,
      sub: [],
      IsRemove: false,
    };
  },
  created() {
    axios
      .get("V1/other-questions")
      .then((response) => {
        this.sub = response.data.data.title;
      })
      .catch((e) => {
        this.error.push(e);
      });
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      setTimeout(() => (this.disabled = !true), 5000);
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      //All is good
      return true;
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
    // removeErr() {
    //   this.IsRemove = true;
    // },
    Send() {
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "discount",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          // email: this.GuestEmail,
          description: "mainPage",
          product_name: this.p_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 3000);
              this.isOpen = false;
              toaster.success(this.$t('notification.success'));
              this.GuestName = "";
              // this.GuestEmail = "";
              this.GuestPhone = "";
              this.organization_name = "";
              this.checked = false;
              this.IsRemove = true;
            } else {
              toaster.error(this.$t('notification.req'));
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree')
        );
      }
    },
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1224px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1224px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.isDisabled {
  opacity: 0.2;
  backdrop-filter: sepia(90%);
}
.spinner-border .spinner-border-sm {
  align-items: center;
  padding-bottom: 5px;
}
.image_mobilka {
  display: none;
}
.remove {
  display: none;
  margin-bottom: -15px;
}
.temporary {
  &-quest {
    background: #efefef;
    margin: 0 auto;
    max-width: 1320px;
    width: 80%;
    border-radius: 15px;
    &__inner {
      background: url(../assets/images/girl.png);
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: 250px;
      background-size: 38%;
      padding-left: 113px;

      &__par {
        @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
        letter-spacing: 0.02em;
        text-transform: uppercase;
        padding-top: 75px;
        padding-bottom: 25px;
      }
      &__inp {
        display: flex;
        input {
          width: 260px;
          height: 70px;
          background: #ffffff;
          border-radius: 15px;
          border: none;
          @include fontStyle(Roboto, normal, 300, 18px, 29px, #373737);
          padding-left: 25px;
          margin-right: 27px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
      &_btn {
        display: flex;
        margin-top: 30px;
        padding-bottom: 70px;
        margin-bottom: 70px;
        align-items: center;
        // background-color: #FFFFFF;
        .checkbox {
          display: flex;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          width: 260px;
          height: 70px;
          border: none;
          @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
          background-color: #fff;
          text-align: center;
          // border: none;

          // background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
          // @include fontStyle(Roboto, normal, 500, 18px, 25px, #FFFFFF);
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
            -webkit-text-fill-color: #ffffff;
            button {
              -webkit-background-clip: text;
              background-clip: text;
            }
          }
        }
        input {
          width: 17px;
          height: 17px;
          margin-left: 25px;
        }
        label {
          @include fontStyle(Roboto, normal, 300, 14px, 18px, #959595);
          margin-left: 10px;
          a {
            text-decoration: underline;
            color: #959595;
          }
        }
      }
    }
  }
  @include mobile() {
    &-quest {
      background: #ffffff;
      width: 90%;
      &__inner {
        margin-top: 30px;
        z-index: 10;
        padding-left: 0px;
        background-position-y: 130px;
        background-size: 50%;
        background-image: none;
        &__par {
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
          line-height: 16px;
          text-align: left;
          background: linear-gradient(
            90deg,
            #ff143f 0%,
            #c600dd 49.97%,
            #1b27ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        &__inp {
          flex-direction: column;
          width: 100%;
          input {
            min-width: 100%;
            width: 100%;
            height: 38px;
            border: 1px solid #efefef;
            border-radius: 3px;
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 12px;
            padding-left: 16px;
            margin-right: 0px;
          }
          .mail {
            // display: none;
          }
        }
        &_btn {
          width: 100%;
          flex-direction: column-reverse;
          align-items: flex-start;
          margin: 0 0 35px 0;
          padding-bottom: 0;
          button {
            background-color: #efefef;
            padding: 8px 12px;
            width: 100%;
            height: 40px;
            border-radius: 3px;
            font-size: 14px;
            line-height: 14px;
          }
          // input,label {
          //     display: none;
          // }
          .checkbox {
            padding-top: 0px;
            padding-bottom: 6px;
            input {
              margin-left: 0;
            }
            label {
              font-size: 10px;
              line-height: 12px;
              margin-left: 5px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  @include tablet() {
    &-quest {
      background: #ffffff;
      width: 90%;
      &__inner {
        margin-top: 30px;
        z-index: 10;
        padding-left: 0px;
        background-position-y: 130px;
        background-size: 50%;
        background-image: none;
        &__par {
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
          line-height: 16px;
          text-align: left;
          background: linear-gradient(
            90deg,
            #ff143f 0%,
            #c600dd 49.97%,
            #1b27ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        &__inp {
          flex-direction: column;
          width: 100%;
          input {
            min-width: 100%;
            width: 100%;
            height: 38px;
            border: 1px solid #efefef;
            border-radius: 3px;
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 12px;
            padding-left: 16px;
            margin-right: 0px;
          }
          .mail {
            // display: none;
          }
        }
        &_btn {
          width: 100%;
          flex-direction: column-reverse;
          align-items: flex-start;
          margin: 0 0 35px 0;
          padding-bottom: 0;
          button {
            background-color: #efefef;
            padding: 8px 12px;
            width: 100%;
            height: 40px;
            border-radius: 3px;
            font-size: 14px;
            line-height: 14px;
          }
          // input,label {
          //     display: none;
          // }
          .checkbox {
            padding-top: 0px;
            padding-bottom: 6px;
            input {
              margin-left: 0;
            }
            label {
              font-size: 10px;
              line-height: 12px;
              margin-left: 5px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  @include tablet-1024() {
    &-quest {
      // background: #FFFFFF;
      &__inner {
        z-index: 10;
        padding-left: 10px;
        background-position-y: 60px;
        background-size: 50%;
        &__par {
          padding-top: 20px;
          padding-bottom: 40px;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          background: linear-gradient(
            90deg,
            #ff143f 0%,
            #c600dd 49.97%,
            #1b27ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        &__inp {
          input {
            width: 160px;
            height: 28px;
            border: 1px solid #efefef;
            border-radius: 3px;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 8px;
          }
          .mail {
            // display: none;
          }
        }
        &_btn {
          margin-top: 0;
          margin-bottom: 40px;
          button {
            padding: 8px 12px;
            width: 100px;
            height: 28px;
            border-radius: 3px;
            font-size: 9px;
            line-height: 12px;
          }
          .checkbox {
            align-items: center;
            input {
              width: 12px;
              height: 12px;
            }
            label {
              font-family: Roboto;
              font-style: normal;
              font-weight: 300;
              font-size: 9px;
              line-height: 12px;
              color: #959595;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
  @include desktop() {
    &-quest {
      &__inner {
        margin-right: -96px;
        background-position-y: 100%;
        &__inp {
          input {
            width: 200px;
            height: 55px;
            font-size: 14px;
            line-height: 17px;
            padding-left: 15px;
            border-radius: 8px;
          }
        }
        &__par {
          font-size: 24px;
          line-height: 30px;
        }
        &_btn {
          button {
            width: 200px;
            height: 55px;
            font-size: 14px;
            line-height: 17px;
            border-radius: 8px;
          }
          label {
            font-size: 12px;
            line-height: 14px;
            margin-left: 5px;
          }
          .checkbox {
            width: 44%;
          }
        }
      }
    }
  }
  @include desktop-max() {
    &-quest {
      &__inner {
        background-position-y: 100%;
        background-size: 38%;
        padding-left: 113px;
        margin-right: -158px;
        &__par {
          margin-bottom: 0;
        }
        &_btn {
          margin-top: 20px;
          .checkbox {
            width: 44%;
          }
        }
      }
    }
  }
}
</style>
