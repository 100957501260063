<template>
  <div>
    <head-bottom />
    <div class="kasko">
      <div class="container">
        <div class="kasko-left">
          <p class="kasko-left__title" v-html="kasko.title"></p>
          <hr class="kasko-line" />
          <p class="kasko-left__sub" v-html="kasko.description"></p>
          <div class="kasko-left__btn">
            <button @click="isOpen = true, this.$refs.myForm.resetForm()" id="click9">
              {{ kasko.button }}
            </button>
            <!-- <button @click="isOpen=true">Получить индивидуальное предложение</button> -->
          </div>
          <modal :open="isOpen" @close="isOpen = !isOpen">
            <get-discount />
            <Form ref="myForm" @submit="Send">
            <div class="modal-inputs">
              <div class="err-message">
                <Field
                  v-model="GuestName"
                  name="username"
                  type="text"
                  class="name"
                  :placeholder="$t('placeholder.name')"
                  :rules="requiredName"
                  @keyup="onlyText()"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="username" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-mask="'+7(7##)-###-##-##'"
                  v-model="GuestPhone"
                  type="text"
                  :placeholder="$t('placeholder.phone')"
                  name="phone"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="phone" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-model="organization_name"
                  type="text"
                  :placeholder="$t('placeholder.company')"
                  name="organization"
                  class="name"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="organization" />
                </div>
              </div>
              <textarea
                v-model="guest_desc"
                :placeholder="$t('placeholder.com')"
              ></textarea>
            </div>
            <div class="modal-btn">
              <button
                type="submit"
                :class="{isDisabled : disabled}"
                @click="buttonClicked"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                ></div>
                {{ $t("buttons.send") }}
              </button>
            </div>
            <div class="modal-rule">
              <input type="checkbox" id="check" v-model="checked" />
              <label for="check"
                >{{ $t("modal.check1") }}
                <a href="/condition">{{ $t("modal.check2") }}</a></label
              >
            </div>
          </Form>
          </modal>
        </div>
        <div class="kasko-right">
          <div
            class="kasko-right__card"
            v-html="kasko.mini_description_first"
          ></div>
          <div
            class="kasko-right__card"
            v-html="kasko.mini_description_second"
          ></div>
          <div
            class="kasko-right__card"
            v-html="kasko.mini_description_third"
          ></div>
        </div>
      </div>
    </div>
    <div class="middle">
      <hr class="middle-line" />
      <div class="container">
        <div class="middle-box">
          <p class="middle-box__title" v-html="tabs[0].big_title"></p>
          <div class="kasko-slider">
            <kasko-slider />
          </div>
          <div class="middle-box__bottom">
            <div class="middle-box__bottom-1">
              <button
                v-for="t in tabs.slice(0, 3)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>

            <div class="middle-box__bottom-3">
              <button
                v-for="t in tabs.slice(3, 6)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>
            <div class="middle-box__bottom-2" v-if="tabContent">
              <img :src="cdn + tabContent.icon" alt="" />
              <div class="par" v-html="tabContent.description"></div>
              <div class="btn">
                <button
                  id="click9"
                  @click="isOpen = true, this.$refs.myForm.resetForm()"
                  v-html="tabContent.button"
                ></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
              </div>
            <div class="middle-box__bottom-2" v-else>
              <img :src="cdn + tabs[0].icon" alt="" />
              <div class="par" v-html="tabs[0].description"></div>
              <div class="btn">
                <button @click="isOpen = true, this.$refs.myForm.resetForm()" v-html="tabs[0].button"></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="about-kasko">
        <p class="about-kasko__title" v-html="kasko3[0].big_title"></p>
        <div class="about-kasko__card" v-for="(k, i) in kasko3" :key="k">
          <p class="about-kasko__card__num" v-if="i < 10">0{{ i + 1 }}.</p>
          <p class="about-kasko__card__num" v-else>{{ i + 1 }}.</p>
          <div class="about-kasko__card__par">
            <span v-html="k.title"></span>
            <p v-html="k.description"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="range">
      <range />
    </div>
    <div class="sliderRange">
      <slider-range />
    </div>
    <div class="reverse1">
      <accordion />
      <discount :p_name="p_name" />
    </div>
    <SliderMain1 />
    <div class="slide-btm">
      <client-say />
      <div class="d_none">
        <SliderMainMobile class="d_none" />
      </div>
    </div>
    <div class="wrap">
      <slider-main-2 />
      <div class="d_none">
        <review-slide />
      </div>
    </div>
  </div>
</template>

<script>
import HeadBottom from "../components/HeadBottom.vue";
import accordion from "../components/accordion.vue";
import Discount from "../components/Discount.vue";
import SliderMain1 from "../components/SliderMain1-1.vue";
import SliderMain2 from "../components/SliderMain2.vue";
// import SliderMainMobile2 from '@/components/SliderMainMobile2.vue'
import range from "../components/range.vue";
import sliderRange from "../components/sliderRange.vue";
import kaskoSlider from "../components/box/kaskoSlider.vue";
import reviewSlide from "../components/box/reviewSlide.vue";
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { mapState } from "vuex";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { createToaster } from "@meforma/vue-toaster";
// const toaster = createToaster({ position: "top-right"})
// import first from '../assets/images/kasko1.png'
// import second from '../assets/images/kasko2.png'
// import third from '../assets/images/kasko3.png'
// import fourth from '../assets/images/kasko-img.png'
// import fifth from '../assets/images/kasko6.png'
// import sixth from '../assets/images/kasko5.png'
import getDiscount from "@/components/getDiscount.vue";
import ClientSay from "@/components/clientSay.vue";

const toaster = createToaster({ position: "top-right" });
export default {
  components: {
    HeadBottom,
    accordion,
    Discount,
    SliderMain1,
    SliderMain2,
    modal,
    range,
    sliderRange,
    kaskoSlider,
    reviewSlide,
    getDiscount,
    ClientSay,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      disabled: false,
      loading: false,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      product_name: "Автокаско",
      p_name: "Автокаско",
      organization_name: null,
      tabContent: null,
      // thirdblock: [],
      resp: [],
      tab: "",
      checked: false,
      guest_desc: null,
      tabs: [],
      kasko: [],
      // kasko2: [],
      kasko3: [],
      IsRemove: false,
    };
  },
  created() {
    axios
      .get("V1/top-products")
      .then((response) => {
        this.kasko = response.data.data[1].first_block;
        this.tabs = response.data.data[1].second_block;
        this.kasko3 = response.data.data[1].third_block;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    tabButton(t) {
      if (t == null) {
        // console.log("test", this.tabs[0]);
        this.tabs[0] = t;
      } else {
        this.tabContent = t;
        // console.log("tab", t);
      }
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "consultant",
          organization_name: "cic.kz",
          phone: this.GuestPhone,
          email: this.GuestEmail,
          description: this.guest_desc,
          product_name: this.product_name,
          organization_name: this.organization_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 20000);
              toaster.success(this.$t('notification.success'));
              resetForm()
              setTimeout(() => {
                this.isOpen = false;
              }, 500);
            } else {
              toaster.error(this.$t('notification.req'));
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree')
        );
      }
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.checked = false;
      this.IsRemove = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background: #ffffff;
}

@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 426px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1023px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1023px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.kasko {
  background: url(../assets/images/kaskoBack.png);
  background-repeat: no-repeat;
  background-size: cover;
  &-slider {
    display: none;
    @include mobile() {
      display: block;
    }
    @include tablet() {
      display: block;
    }
    @include tablet-1024() {
      display: block;
    }
  }
  &-line {
    border: 1px solid #ffffff;
    margin: 20px 0;
  }
  &-left {
    width: 60%;
    padding-top: 140px;
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 49px, #ffffff);
      letter-spacing: 0.015em;
      text-transform: uppercase;
      text-shadow: 0px 4px 30px rgba(0, 0, 0, 0.45);
    }
    &__sub {
      @include fontStyle(Roboto, normal, 300, 20px, 27px, #ffffff);
      text-shadow: 0px 4px 50px rgba(0, 0, 0, 0.45);
      letter-spacing: 0.01em;
    }
    &__btn {
      // width: 405px;
      // height: 70px;
      // background: #FFFFFF;
      // border-radius: 15px;
      margin: 120px 0;
      button {
        width: 405px;
        height: 70px;
        background: #ffffff;
        border-radius: 15px;
        border: none;
        @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #ffffff;
          button {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            background-clip: text;
          }
        }
      }
      // &:hover {
      //     background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
      //     color: #FFFFFF;
      //     button {
      //         -webkit-background-clip: text;
      //         -webkit-text-fill-color: #FFFFFF;
      //         background-clip: text;
      //     }
      // }
    }
  }
  &-right {
    margin: 130px 0;
    &__card {
      width: 223px;
      height: 128px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(50px);
      border-radius: 15px;
      @include fontStyle(Roboto, normal, 400, 17px, 23px, #ffffff);
      padding: 30px 30px;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 348px) {
    &-left {
      &__btn {
        button {
          margin-top: 27% !important;
        }
      }
    }
  }
  @media (min-width: 349px) and (max-width: 383px) {
    &-left {
      &__btn {
        button {
          margin-top: 25% !important;
        }
      }
    }
  }
  @include mobile() {
    background: url(../images/kasko-back22.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f6f6f6;
    height: 380px;
    &-line {
      display: none;
    }
    &-left {
      width: 100%;
      padding-top: 20px;
      &__title {
        //change
        font-size: 20px;
        line-height: 21px;
        margin-bottom: 0px;
        padding: 25px 0 10px 0;
      }
      &__sub {
        width: 90%;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: #373737;
        background: #f4f4f4;
        backdrop-filter: blur(30px);
        border-radius: 0px 5px 5px 0px;
        border-radius: 0px 5px 5px 0px;
        padding: 10px 5px 10px 6%;
        margin-left: -6%;
      }
      &__btn {
        margin: 0 auto;
        width: 100%;
        height: 0px;
        border-radius: 3px;
        button {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          line-height: 16px;
          margin-top: 27%;
        }
      }
    }
    &-right {
      &__card {
        display: none;
      }
    }
  }
  @include tablet() {
    background: url(../images/kasko-back22.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f6f6f6;
    height: 380px;
    &-line {
      display: none;
    }
    &-left {
      width: 100%;
      padding-top: 30px;
      &__title {
        //change
        font-size: 20px;
        line-height: 21px;
        margin-bottom: 0px;
        padding: 25px 0 10px 0;
      }
      &__sub {
        width: 80%;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: #373737;
        background: #f4f4f4;
        backdrop-filter: blur(30px);
        border-radius: 0px 5px 5px 0px;
        border-radius: 0px 5px 5px 0px;
        padding: 10px 10px 10px 14%;
        margin-left: -14%;
      }
      &__btn {
        margin: 0 auto;
        width: 100%;
        height: 0px;
        border-radius: 3px;
        button {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          line-height: 16px;
          margin-top: 27%;
        }
      }
    }
    &-right {
      &__card {
        display: none;
      }
    }
  }
  @include tablet-1024() {
    background-size: cover;
    background-color: #f6f6f6;
    padding-bottom: 20px;
    &-line {
      display: none;
    }
    &-left {
      width: 100%;
      padding-top: 30px;
      &__title {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 8px;
      }
      &__sub {
        width: 75%;
        font-size: 14px;
        line-height: 17px;
        padding: 20px 0 20px 0;
      }
      &__btn {
        margin: 0 auto;
        width: 250px;
        height: 40px;
        border-radius: 3px;
        button {
          width: 250px;
          height: 40px;
          border-radius: 15px;
          font-size: 11px;
          line-height: 14px;
        }
      }
    }
    &-right {
      &__card {
        display: none;
      }
    }
  }
  @include desktop() {
    background-size: cover;
    padding-bottom: 60px;
    &-line {
      margin: 8px 0;
    }
    &-left {
      padding-top: 50px;
      &__title {
        font-size: 28px;
        line-height: 33px;
      }
      &__sub {
        font-size: 14px;
        line-height: 18px;
      }
      &__btn {
        margin: 0;
        width: 300px;
        height: 60px;
        border-radius: 8px;
        button {
          width: 300px;
          height: 60px;
          border-radius: 8px;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    &-right {
      margin: 50px 0;
      &__card {
        margin-bottom: 15px;
        padding: 20px;
        width: 175px;
        height: 92px;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}
.container {
  display: flex;
  justify-content: space-between;
}
.container1 {
  margin: 0 auto;
  max-width: 1320px;
  width: 78%;
}
.middle {
  &-line {
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    height: 1px;
    margin-top: 70px;
    position: relative;
    z-index: 3;
  }
  &-box {
    z-index: 2;
    background-color: #f6f6f6;
    padding: 100px 70px;
    border-radius: 15px;
    margin-top: -100px;
    max-width: 1320px;
    width: 100%;
    &__title {
      @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      &-1 {
        text-align: center;
      }
      &-1,
      &-3 {
        max-width: 300px;
        flex-wrap: wrap;
        button {
          background: #f6f6f6;
          width: 190px;
          height: 60px;
          border: 1px solid #c4c4c4;
          border-radius: 15px;
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
          margin-top: 40px;
          &:hover {
            filter: drop-shadow(0px 4px 43px rgba(0, 0, 0, 0.15));
            background-color: #fff;
            border: none;
          }
        }
      }
      &-2 {
        text-align: center;
        height: 380px;
        img {
          max-width: 106px;
          max-height: 106px;
        }
        .par {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, transparent);
          background: #373737;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          padding-top: 20px;
          padding-bottom: 60px;
          text-align: center;
          margin: 0 auto;
          height: 180px;
          width: 330px;
        }
        .btn {
          width: 380px;
          height: 70px;
          background: #ffffff;
          border-radius: 15px;
          button {
            padding-top: 15px;
            background: #ffffff;
            border: none;
            @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
          }
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
            button {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #ffffff;
              background-clip: text;
            }
          }
        }
      }
      &-3 {
        text-align: center;
      }
    }
  }
  @include mobile() {
    background: #f6f6f6;
    padding-bottom: 20px;
    &-line {
      display: none;
    }
    &-box {
      background: transparent;
      padding: 0;
      margin-top: 25px;
      &__title {
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet() {
    background: #efefef;
    &-line {
      display: none;
    }
    &-box {
      background: transparent;
      padding: 120px 0 0 0;
      &__title {
        font-size: 14px;
        line-height: 14px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet-1024() {
    background: #f6f6f6;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0px;
      padding: 50px;
      &__title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include desktop() {
    &-line {
      height: 1px;
    }
    &-box {
      padding: 50px;
      margin-top: -140px;
      &__title {
        font-size: 18px;
        line-height: 24px;
      }
      &__bottom {
        &-2 {
          .btn {
            width: 280px;
            height: 60px;
            border-radius: 10px;
            button {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  @include desktop-max() {
    &-box {
      margin-top: -220px;
    }
    &-line {
      margin-top: 130px;
    }
  }
}
.about {
  margin: 0 auto;
  max-width: 1320px;
  width: 80%;
  &-kasko {
    margin-top: 95px;
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 10px;
    }
    &__card {
      display: flex;
      align-items: center;
      &__num {
        @include fontStyle(Roboto, normal, 900, 60px, 82px, transparent);
        background: -webkit-linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        color: #ffffff;
        align-items: center;
      }
      &__par {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        background: #f6f6f6;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-sizing: border-box;
        backdrop-filter: blur(55px);
        border-radius: 15px;
        padding: 20px;
        margin-left: 12px;
        width: 100%;
        height: 70px;
        display: flex;
        align-items: baseline;
        margin-bottom: 15px;
        span {
          @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
          padding-right: 20px;
          margin-bottom: -10px;
        }
      }
    }
  }
  @include mobile() {
    width: 90%;
    padding-top: 15px;
    padding-bottom: 15px;
    &-kasko {
      margin-top: 25px;
      margin-bottom: -15px;
      &__title {
        //change
        font-size: 16px;
        font-weight: 600;
        line-height: 17px;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 36.97%,
          #1b27ff 57%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
        padding-bottom: 0px;
      }
      &__card {
        align-items: baseline;
        //change
        &__num {
          font-size: 14px;
          line-height: 14px;
          font-weight: 700;
          letter-spacing: 0.03em;
          padding: 1px;
        }
        &__par {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          background: transparent;
          margin-left: 5px;
          margin-bottom: -10px;
          padding: 0;
          span {
            width: 100%;
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
            padding-right: 10px;
          }
        }
      }
    }
  }
  @include tablet() {
    padding-top: 20px;
    padding-bottom: 20px;
    &-kasko {
      margin-top: 25px;
      &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
      }
      &__card {
        align-items: baseline;
        &__num {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          letter-spacing: 0.03em;
        }
        &__par {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          background: transparent;
          margin-left: 5px;
          padding: 0;
          span {
            font-size: 14px;
            line-height: 18px;
            width: 100%;
            font-weight: 500;
            padding-right: 10px;
          }
        }
      }
    }
  }
  @include tablet-1024() {
    &-kasko {
      &__title {
        font-size: 30px;
        line-height: 33px;
      }
      &__card {
        &__num {
          font-size: 35px;
          line-height: 42px;
        }
        &__par {
          font-size: 16px;
          line-height: 20px;
          span {
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
    }
  }
}
.sliderMain1-title {
  @include mobile() {
    p {
      font-size: 14px;
      line-height: 17px;
      padding-top: 0;
      padding-bottom: 0;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  @include tablet() {
    p {
      font-size: 17px;
      line-height: 25px;
      padding-top: 0;
      padding-bottom: 0;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  @include tablet-1024() {
    p {
      padding-top: 0;
    }
  }
}
.slide-btm {
  @include mobile() {
    padding-top: 25px;
    padding-bottom: 30px;
    &__title {
      display: none;
    }
  }
  @include tablet() {
    padding-top: 30px;
    padding-bottom: 30px;
    &__title {
      display: none;
    }
  }
}
</style>
