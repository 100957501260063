<template>
  <transition name="fade">
    <div class="back-modal">
      <div class="vue-modal" v-show="open">
        <transition name="drop-in">
          <div class="vue-modal-inner" v-show="open">
            <div class="vue-modal-content">
              <slot />
              <button type="button" class="close" @click="close"></button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import { onMounted, onUnmounted } from "vue";

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const close = () => {
      emit("close");
    };
    const handleKeyup = (event) => {
      if (event.keyCode === 27) {
        close();
      }
    };

    onMounted(() => document.addEventListener("keyup", handleKeyup));
    onUnmounted(() => document.removeEventListener("keyup", handleKeyup));

    return { close };
  },
  methods: {
    watch: {
      isOpen: function () {
        if (this.isOpen) {
          document.documentElement.style.overflow = "hidden";
          return;
        }
        document.documentElement.style.overflow = "auto";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding-right: 5px;
  background: #ffff;
  border-radius: 15px;
  &::after {
    display: inline-block;
    content: "\00d7";
    margin-top: 15px;
    margin-right: 15px;
  }
}
.back-modal {
}
.vue-modal {
  position: fixed;
  left: 70%;
  top: 15%;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10000000 !important;
  // background: rgba(0, 0, 0, 0.1);
  &-inner {
    width: clamp(200px, 40%, 500px);
    margin-right: auto;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.13));
    z-index: 10000000 !important;
  }
  &-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 15px;
    padding: 25px;
  }
  @include mobile() {
    &-inner {
      width: 90%;
      margin: 0 auto;
    }
  }
  @include tablet() {
    &-inner {
      width: 65%;
      margin: 0 auto;
    }
  }
  @media (max-width: 1740px) {
    left: 65%;
  }
  @media (max-width: 1460px) {
    left: 55%;
    top: 10%;
    &-content {
      padding: 15px;
    }
  }
  @media (max-width: 1150px) {
    left: 45%;
    top: 10%;
  }
  @media (max-width: 930px) {
    left: 35%;
    top: 10%;
  }
  @media (max-width: 770px) {
    left: 10%;
    top: 10%;
  }
  @media (max-width: 430px) {
    left: 1%;
    top: 10%;
  }
}

//animation --->
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}
.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>
