<template>
    <about-us-header/>
    <about-back/>
    <about-buttons/>
    <div class="acc">
        <div class="faqs">
            <FAQ2 
            v-for="(faq, i) in faqs" 
            :faq="faq" 
            :index="i" 
            :key="i"
            :open="faq.open"
            @toggleOpen="toggleOpen"
            />
        </div>
    </div>
</template>

<script>
import aboutUsHeader from '../components/aboutUsHeader.vue';
import AboutBack from '../components/AboutBack.vue';
import aboutButtons from '../components/aboutButtons.vue';
import FAQ2 from './FAQ2.vue';
import axios from 'axios'
import { mapState } from 'vuex';
export default {
    name: 'financeAccordion',
    components: {
        aboutUsHeader,AboutBack,aboutButtons,
        FAQ2
    },
    data () {
        return {
            // faqs: null,
            faqs: [],
            open: false,
        }
    },
    methods: {
        toggleOpen: function (index) {
        this.faqs = this.faqs.map((faq, i) => {
            if (index === i) {
                faq.open = !faq.open;
            } else {
                faq.open = false;
            }
            return faq;
        });
        },
        getLicense(){
            axios.get('V1/financial-accounts')
            .then(response => {
                this.faqs = response.data.data
                
            })
            .catch(e => {
                this.errors.push(e)
            })
        }
    },
    computed: {
        ...mapState(['cdn'])
    },
    mounted(){
        this.getLicense();
    }
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    .acc {
        padding: 80px 0;
        background: #EFEFEF;
        @include mobile() {
            padding: 0;
        }
        @include tablet() {
            padding: 0;
        }
    }
    // .accordion-title {
    //     @include fontStyle(Roboto, normal, 500, 36px, 49px, #373737);
    //     text-align: center;
    //     letter-spacing: 0.02em;
    //     text-transform: uppercase;
    //     margin-bottom: 30px;
    // }
    .faq {
        display: block;
        width: 80%;
        max-width: 1320px;
        margin: auto;
        padding: 15px;
        border: 1px solid #D3D3D3;
        background-color: #FFF;
        .question {
            position: relative;
            @include fontStyle(Roboto, normal, 400, 18px, 33px, #373737);
            font-weight: 400 !important;
            transition: all 0.4s linear;
            padding: 10px 0 0px 112px;
            // p {
            //     margin-bottom: 0 !important;
            // }
            &::after {
                margin-right: 62px;
                margin-top: 8px;
                content: '+';
                color: #D3D3D3;
                font-size: 57px;
                font-weight: 200;
                border-radius: 50%;
                background: #EFEFEF;
                padding: 5px 6px 7px 5px;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%) rotate(0deg);
                width: 42px;
                height: 42px;
                transition: all 0.2s linear;
            }
        }
        @include mobile() {
            padding: 5px;
            .question {
                &::after {
                    margin-top: -8px;
                }
            }
        }
        @include tablet() {
            padding: 5px;
            .question {
                &::after {
                    margin-top: -8px;
                }
            }
        }
    }
    .faq.open .question::after {
        content: '-';
        transition: 400ms;
        font-size: 110px;
        font-weight: 200;
        padding: 2px 7px 7px 5px;
        margin-right: 62px;
    }
    .faq .answer {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        padding-left: 112px;
        opacity: 0;
        max-height: 0px;
        overflow-y: hidden;
        transition: all 0.4s ease-out;
    }
    .faq.open .answer {
        opacity: 1;
        max-height: 1000px;
        padding-bottom: 20px;
        &:hover {
            background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
</style>
