<template>
  <about-us-header />
  <about-back />
  <div class="holder">
    <div class="container">
      <div class="holder-par">
        <p class="d_none">{{ $t("buttons.hold1") }}</p>
        <p class="m_none">
          <router-link to="/Holder1">{{ $t("buttons.hold1") }}</router-link>
        </p>
        <!-- <Holder1/> -->
        <div class="links">
          <div class="holder-par__links" v-for="sh in share" :key="sh">
            <a :href="cdn + sh.doc"
              ><img src="../assets/images/download.png" alt="" /><span
                v-html="sh.accept"
              ></span
            ></a>
          </div>
        </div>
        <p class="d_none">{{ $t("buttons.hold2") }}</p>
        <p class="m_none">
          <router-link to="/Holder2">{{ $t("buttons.hold2") }}</router-link>
        </p>
        <!-- <Holder2/> -->
        <div class="link">
          <div class="holder-par__links" v-for="rs in reestr" :key="rs">
            <a :href="cdn + rs.doc"
              ><img class="" src="../assets/images/download.svg" alt="" /><span
                v-html="rs.accept"
              ></span
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "../components/aboutUsHeader.vue";
import AboutBack from "../components/AboutBack.vue";
import axios from "axios";
import { mapState } from "vuex";
// import Holder1 from '@/components/Holder1.vue';
// import Holder2 from '@/components/Holder2.vue';
import Holder1 from "@/components/Holder1.vue";
import Holder2 from "@/components/Holder2.vue";
export default {
  components: { aboutUsHeader, AboutBack, Holder1, Holder2, Holder1, Holder2 },
  data() {
    return {
      event: [],
      reestr: [],
      share: [],
    };
  },
  created() {
    axios
      .get("V1/share_holders")
      .then((response) => {
        this.event = response.data.data;
        this.reestr = response.data.Register;
        this.share = response.data.Shareholders;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 767px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1440px) and (max-width: 2560px) {
    @content;
  }
}
.holder {
  background: #efefef;
  &-par {
    display: flex;
    a {
      text-decoration: none;
    }
    p {
      padding-right: 40px;
      padding-top: 70px;
      @include fontStyle(Roboto, normal, 300, 24px, 29px, #373737);
      a {
        padding-right: 40px;
        padding-top: 70px;
        @include fontStyle(Roboto, normal, 300, 24px, 29px, #373737);
      }
    }
  }
  @include mobile() {
    padding-top: 10px;
    padding-bottom: 27px;
    &-par {
      flex-wrap: wrap;
      p {
        font-size: 14px;
        line-height: 15px;
        padding-top: 25px;
        padding-bottom: 0;
        font-weight: 500;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 18.97%,
          #1b27ff 33%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        width: 100%;
      }
      &__links {
        display: flex;
        flex-wrap: wrap;
        a {
          display: flex;
          align-items: center;
          width: 100%;
          @include fontStyle(Roboto, normal, 300, 14px, 15px, #373737);
          padding-bottom: 13px;
          img {
            width: 29px;
            height: 20px;
            padding-right: 9px;
          }
          &:hover,
          &:active {
            font-weight: 400;
            text-decoration: underline;
          }
        }
      }
    }
  }
  @include tablet() {
    padding-top: 10px;
    padding-bottom: 27px;
    &-par {
      flex-wrap: wrap;
      p {
        font-size: 14px;
        line-height: 17px;
        padding-top: 25px;
        padding-bottom: 0;
        font-weight: 500;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 18.97%,
          #1b27ff 33%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        width: 100%;
      }
      &__links {
        display: flex;
        flex-wrap: wrap;
        a {
          display: flex;
          align-items: center;
          width: 100%;
          @include fontStyle(Roboto, normal, 300, 14px, 15px, #373737);
          padding-bottom: 13px;
          img {
            padding-right: 9px;
            width: 29px;
            height: 20px;
          }
          &:hover,
          &:active {
            font-weight: 400;
            text-decoration: underline;
          }
        }
      }
    }
  }
  @include tablet-1024() {
    padding-top: 25px;
    &-par {
      p {
        border-bottom: 2px solid currentColor;
        &:hover,
        :focus {
          font-weight: 600;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 2px solid;
          border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47%
            0%;
        }
        font-size: 18px;
        line-height: 23px;
        padding-top: 20px;
      }
      &__links {
        display: none;
      }
    }
  }
  @include desktop() {
    &-par {
      p {
        border-bottom: 2px solid currentColor;
        &:hover,
        :focus {
          font-weight: 600;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 2px solid;
          border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47%
            0%;
        }
      }
      &__links {
        display: none;
      }
    }
  }
  @include desktop-max() {
    &-par {
      p {
        border-bottom: 2px solid currentColor;
        &:hover,
        :focus {
          font-weight: 600;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 2px solid;
          border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47%
            0%;
        }
      }
      &__links {
        display: none;
      }
    }
  }
}
</style>
