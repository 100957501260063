<template>
  <div>
    <head-bottom />
    <div class="propertyMain">
      <div class="container">
        <div class="property">
          <div
            class="property-cards"
            v-for="property in PropertyMain"
            :key="property"
          >
            <div class="property-cards-left">
              <p class="property-cards-left__title" v-html="property.title"></p>
              <p class="property-cards-left__sub" v-html="property.desc"></p>
              <div class="property-cards-left__btn white_hover__btn">
                <a :href="property.link"><button>{{ $t("buttons.lmore") }}</button></a>
              </div>
            </div>
            <div class="property-cards-right">
              <img :src="cdn + property.icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadBottom from "../components/HeadBottom.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { HeadBottom },
  data() {
    return {
      PropertyMain: [],
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/business/property")
      .then((response) => {
        this.PropertyMain = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -600px 600px;
  }
}
.propertyMain {
  background: #efefef;
  width: 100%;
  background-image: url("../assets/images/cool-background1.png");
  animation: animatedBackground 60s linear infinite;
  -moz-animation: animatedBackground 60s linear infinite;
  -webkit-animation: animatedBackground 60s linear infinite;
  -o-animation: animatedBackground 60s linear infinite;
}
.property {
  padding-top: 100px;
  padding-bottom: 80px;
  &-cards {
    margin-bottom: 20px;
    background: #ffffff;
    width: 100%;
    padding: 32px 30px 17px 32px;
    display: flex;
    justify-content: space-around;
    border-radius: 15px;
    &-left {
      &__title {
        @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
        text-transform: uppercase;
      }
      &__sub {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        padding: 26px 0 26px 0;
      }
      &__btn {
        button {
          width: 215px;
          height: 69px;
          background-color: #efefef;
          // border-radius: 15px;
          // border: none;
          // background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
          // @include fontStyle(Roboto, normal, 600, 18px, 25px,#FFFFFF);
          // &:hover {
          // border: 3px solid #BE31CE;
          // }
        }
      }
    }
    &-right {
      img {
        width: 80%;
        min-width: 300px;
        height: 87%;
      }
    }
    &:hover {
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.2);
    }
  }
  @include mobile() {
    padding-top: 30px;
    padding-bottom: 20px;
    &-cards {
      display: flex;
      flex-wrap: wrap;
      padding: 30px 20px;
      &-left {
        &__title {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
        }
        &__sub {
          text-align: center;
          font-size: 15px;
          line-height: 18px;
          padding: 15px 0 15px 0;
        }
        &__btn {
          margin: 0 auto;
          text-align: center;
          button {
            font-size: 12px;
            width: 135px;
            height: 35px;
            border-radius: 5px !important;
          }
        }
      }
      &-right {
        margin-top: 20px;
        text-align: center;
        img {
          min-width: 200px;
        }
      }
    }
  }
  @include tablet() {
    padding-top: 30px;
    padding-bottom: 20px;
    &-cards {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
      &-left {
        margin: 0 auto;
        &__title {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          margin-top: 20px;
        }
        &__sub {
          text-align: center;
          font-size: 15px;
          line-height: 18px;
          padding: 15px 0 15px 0;
        }
        &__btn {
          margin: 0 auto;
          text-align: center;
          button {
            font-size: 12px;
            width: 150px;
            height: 40px;
            border-radius: 5px;
          }
        }
      }
      &-right {
        margin-top: 20px;
        text-align: center;
        img {
          min-width: 200px;
        }
      }
    }
  }
  @include tablet-1024() {
    padding-top: 20px;
    padding-bottom: 20px;
    &-cards {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
      &-left {
        margin: 0 auto;
        &__title {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          margin-top: 20px;
        }
        &__sub {
          text-align: center;
          font-size: 18px;
          line-height: 20px;
          padding: 15px 0 15px 0;
        }
        &__btn {
          margin-bottom: 20px;
          text-align: center;
          margin: 0 auto;
          button {
            font-size: 12px;
            width: 150px;
            height: 40px;
            border-radius: 5px;
          }
        }
      }
      &-right {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        img {
          min-width: 200px;
        }
      }
    }
  }
  @include desktop() {
    &-cards {
      padding: 40px 50px;
      justify-content: space-between;
      &-left {
        width: 60%;
        &__title {
          font-size: 28px;
          line-height: 30px;
        }
        &__sub {
          font-size: 14px;
          line-height: 18px;
          padding: 15px 0 15px 0;
        }
        &__btn {
          button {
            width: 150px;
            height: 50px;
            border-radius: 5px;
            font-size: 12px;
          }
        }
      }
      &-right {
        width: 33%;
        height: 170px;
        img {
          height: 160px;
          width: 260px;
          object-fit: contain;
        }
      }
    }
  }
  @include desktop-max() {
    &-cards {
      padding: 55px 70px;
      margin-bottom: 63px;
      justify-content: space-between;
      &-left {
        width: 59%;
        align-self: flex-end;
        // height: 283px;
      }
      &-right {
        img {
          height: 250px;
          object-fit: contain;
        }
      }
      // &:last-child {
      //   padding-left: 7%;
      // }
    }
  }
}
</style>
