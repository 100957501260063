<template>
  <div class="top">
    <div class="container">
      <h2 class="top-title" v-html="title"></h2>

      <swiper
        :slidesPerView="4"
        :spaceBetween="0"
        :slidesPerGroup="1"
        :loop="true"
        :pagination="false"
        :navigation="navigation"
        :modules="modules"
        class="mySwiper m_none"
      >
        <swiper-slide v-for="slide in sliderMain" :key="slide">
          <a :href="slide.link">
            <img class="mySwiper__img" :src="cdn + slide.image" alt="" />
            <div class="par">
              <p class="mySwiper__title" v-html="slide.title"></p>
              <p class="mySwiper__sub" v-html="slide.description"></p>
            </div>
          </a>
        </swiper-slide>
      </swiper>
      <div class="nav-btn m_none">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <slider-main-mobile-2 class="d_none" />
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";
import { mapState } from "vuex";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import SliderMainMobile2 from "./SliderMainMobile2.vue";

export default {
  name: "SliderMain1",
  components: {
    Swiper,
    SwiperSlide,
    SliderMainMobile2,
  },
  data() {
    return {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      getCompany: [],
      prod: [],
      sliderMain: [],
      title: [],
    };
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  created() {
    axios
      .get("V1/top-products")
      .then((response) => {
        this.sliderMain = response.data.data;
        this.title = response.data.big_title;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.container {
  position: relative;
  width: 100%;
  @include tablet() {
    width: 88%;
  }
  @include tablet-1024() {
    width: 75%;
  }
  @include desktop() {
    width: 79%;
  }
  @media (min-width: 1440px) and (max-width: 1620px) {
    max-width: 1380px;
    width: 80%;
  }
}
.mySwiper {
  a {
    text-decoration: none;
  }
}
.par {
  height: 170px;
  @include tablet() {
    height: 0;
  }
  @include tablet-1024() {
    height: 110px;
    margin-left: 0px;
  }
  @include desktop() {
    margin-left: 0px;
  }
  @include desktop-max() {
    margin-left: 0px;
    height: 140px;
  }
}
.top {
  padding-top: 100px;
  background: #efefef;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 119.69%, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-bottom: 30px;
    &-mobile {
      display: none;
    }
    // span {
    //     background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //     background-clip: text;
    // }
  }
  &-sub {
    @include fontStyle(Roboto, normal, 300, 20px, 119.69%, #373737);
    padding-bottom: 48px;
  }
  @include mobile() {
    background: #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
    &-title {
      display: none;
      &-mobile {
        display: block;
        @include fontStyle(Roboto, normal, 500, 12px, 14px, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-bottom: 14px;
      }
    }
    &-sub {
      display: none;
    }
  }
  @include tablet() {
    background: #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
    &-title {
      display: none;
      &-mobile {
        display: block;
        @include fontStyle(Roboto, normal, 500, 16px, 18px, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-bottom: 20px;
      }
    }
    &-sub {
      display: none;
    }
  }
  @include tablet-1024() {
    padding-top: 50px;
    padding-bottom: 20px;
    &-title {
      font-size: 26px;
      padding-bottom: 10px;
    }
    &-sub {
      font-size: 15px;
      padding-bottom: 20px;
    }
  }
  @include desktop-max() {
    padding-bottom: 50px;
  }
}
.nav-btn {
  top: 43%;
  position: absolute;
  width: 100%;
  @include tablet-1024() {
    top: 36%;
  }
  @include desktop() {
    top: 38%;
  }
}
.swiper {
  max-width: 1370px;
  width: 100%;
  height: 100%;
  // top: -130px;
  // bottom: -130px;
  &-button {
    &-next,
    &-prev {
      top: 34% !important;
      width: 29px;
      height: 45px;
      &::after {
        opacity: 0;
      }
    }
    &-prev {
      background: url("../images/left.svg");
      left: -50px;
    }
    &-next {
      background: url("../images/right.svg");
      right: -29px;
    }
    @include desktop() {
      &-next,
      &-prev {
        top: 29% !important;
      }
    }
    @include tablet-1024() {
      &-next,
      &-prev {
        top: 25% !important;
        // width: 15px;
        // height: 30px;
      }
    }
  }
  &-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    img {
      display: block;
      object-fit: cover;
    }
    .mySwiper {
      align-items: center;
      &__img {
        width: 285px;
        height: 300px;
        border-radius: 15px;
        margin-left: 0px;
        // margin-top: 120px;
        &:hover {
          filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.35));
          border-radius: 15px;
          &__title {
            font-weight: 600 !important;
          }
        }
      }
      &__title {
        width: 100%;
        height: 30px;
        @include fontStyle(Roboto, normal, 400, 23px, 29px, #373737);
        text-align: left;
        margin-top: 30px;
      }
      &__sub {
        width: 88%;
        @include fontStyle(Roboto, normal, 300, 16px, 19px, #373737);
        text-align: left;
        margin-top: 20px;
        margin-bottom: 25px;
        height: 50px;
      }
      &__btn {
        width: 174px;
        height: 67px;
        margin: auto;
        text-align: end;
        button {
          width: 174px;
          height: 67px;
          border-radius: 15px;
          border: none;
          @include fontStyle(Roboto, normal, 500, 18px, 136.69%, #373737);
          background: #ffffff;
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            border-radius: 7px;
            color: #ffffff;
          }
        }
      }
    }
  }
  @include mobile() {
    &-slide {
      .mySwiper {
        &__img {
          width: 62px;
          height: 70px;
          margin-left: 0;
          border-radius: 15px;
        }
        &__title {
          //change
          font-size: 12px;
          line-height: 14px;
          margin-top: 5px;
        }
        &__sub,
        &__btn {
          display: none;
        }
      }
    }
    &-button {
      &-next::after,
      &-prev::after {
        font-size: 15px;
      }
    }
  }
  @include tablet() {
    &-slide {
      .mySwiper {
        width: 90px;
        display: flex;
        flex-wrap: wrap;
        &__img {
          width: 90px;
          height: 90px;
          margin-left: 0;
          border-radius: 15px;
        }
        &__title {
          //change
          font-size: 12px;
          line-height: 14px;
          margin-top: 5px;
        }
        &__sub,
        &__btn {
          display: none;
        }
      }
    }
    &-button {
      &-next::after,
      &-prev::after {
        font-size: 15px;
      }
    }
  }
  @include tablet-1024() {
    width: 100%;
    &-slide {
      .mySwiper {
        width: 165px;
        align-items: center;
        &__img {
          // width: 262px;
          height: 140px;
          border-radius: 15px;
          margin-left: 0px;
          max-width: 86%;
        }
        &__title {
          font-size: 14px;
          line-height: 18px;
          margin-top: 10px;
          margin-bottom: 0;
        }
        &__sub {
          font-size: 10px;
          line-height: 12px;
          margin-top: 5px;
          margin-bottom: 45px;
        }
        &__btn {
          button {
            width: 100px;
            height: 30px;
            border-radius: 5px;
            font-size: 12px;
            margin: auto;
          }
        }
      }
    }
  }
  @include desktop() {
    &-slide {
      .mySwiper {
        align-items: center;
        &__img {
          width: 262px;
          height: 264px;
          border-radius: 15px;
          margin-left: 0px;
          max-width: 86%;
        }
        &__title {
          font-size: 16px;
          line-height: 20px;
          height: 20px;
          margin-top: 20px;
        }
        &__sub {
          width: 100%;
          font-size: 12px;
          line-height: 16px;
          margin-top: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          height: 34px;
        }
        &__btn {
          display: none;
        }
      }
    }
  }
  @include desktop-max() {
    &-slide {
      .mySwiper {
        &__img {
          max-width: 90%;
        }
        &__sub {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          height: 40px;
        }
      }
    }
  }
}
</style>
