<template>
  <header class="header">
    <div class="container">
      <div class="header-nav">
        <router-link to="/autoIndivid">{{ $t("individual.auto") }}</router-link>
        <router-link to="/healthIndivid">{{
          $t("individual.health")
        }}</router-link>
        <router-link to="/houseIndivid">{{
          $t("individual.property")
        }}</router-link>
        <router-link to="/touristIndivid">{{
          $t("individual.tourist")
        }}</router-link>
        <router-link to="/typeIndivid">{{ $t("individual.type") }}</router-link>
        <router-link to="/buisnessIndivid">{{
          $t("individual.bussiness")
        }}</router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.container {
  padding-left: 0;
  padding-right: 0;
}
.header {
  &-nav {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    a {
      @include fontStyle(Roboto, normal, 400, 16px, 32px, #373737);
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #000000;
        font-weight: 600;
      }
    }
  }
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
  @include tablet-1024() {
    &-nav {
      a {
        font-size: 8px;
        line-height: 10px;
      }
    }
  }
  @include desktop() {
    &-nav {
      a {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
</style>
