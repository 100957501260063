<template>
  <about-us-header />
  <about-back />
  <div class="acc animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="container">
      <h2 class="accordion-title">{{ $t("buttons.ans") }}</h2>
      <div class="acc-card">
        <input id="check00" type="checkbox" name="menu" />
        <label v-if="arrowClicked" for="check00"
        @click="arrowDown()"
          >{{ $t("buttons.basic") }}
          <p><i class="arrow rotate"></i></p>
        </label>
        <label v-else for="check00"
        @click="arrowUp()"
          >{{ $t("buttons.basic") }}
          <p><i class="arrow down"></i></p>
        </label>
        <ul :class="{'display_block' : arrowClicked}" class="submenu animate__animated animate__fadeIn" style="animation-duration: 3s;">
          <div class="faqs">
            <FaqCompany
              v-for="(faq, i) in faqs"
              :faq="faq"
              :index="i"
              :key="i"
              :open="faq.open"
              :active="faq.active"
              @toggleOpen="toggleOpen"
            />
          </div>
        </ul>
      </div>
      <div class="acc-card">
        <input id="check01" type="checkbox" name="menu" />
        <label v-if="arrowClicked1" for="check01" @click="arrowDown1()"
          >{{ $t("buttons.pay") }}
          <p><i class="arrow rotate"></i></p
        ></label>
        <label v-else for="check01" @click="arrowUp1()"
          >{{ $t("buttons.pay") }}
          <p><i class="arrow down"></i></p
        ></label>
        <ul :class="{'display_block' : arrowClicked1}" class="submenu animate__animated animate__fadeIn" style="animation-duration: 3s;">
          <div class="faqs">
            <FaqCompany
              v-for="(faq, i) in faqsPayment"
              :faq="faq"
              :index="i"
              :key="i"
              :open="faq.open2"
              @toggleOpen="toggleOpen2"
            />
          </div>
        </ul>
      </div>
      <div class="acc-card">
        <input id="check02" type="checkbox" name="menu" />
        <label v-if="arrowClicked2" for="check02"
        @click="arrowDown2()"
          >{{ $t("buttons.decor") }}
          <p><i class="arrow rotate"></i></p
        ></label>
        <label v-else for="check02"
        @click="arrowUp2()"
          >{{ $t("buttons.decor") }}
          <p><i class="arrow down"></i></p
        ></label>
        <ul :class="{'display_block' : arrowClicked2}" class="submenu animate__animated animate__fadeIn" style="animation-duration: 3s;">
          <div class="faqs">
            <FaqCompany
              v-for="(faq, i) in faqsDesign"
              :faq="faq"
              :index="i"
              :key="i"
              :open="faq.open3"
              @toggleOpen="toggleOpen3"
            />
          </div>
        </ul>
      </div>
      <div class="acc-card">
        <input id="check03" type="checkbox" name="menu" />
        <label v-if="arrowClicked3" for="check03"
        @click="arrowDown3()"
          >{{ $t("buttons.other") }}
          <p><i class="arrow rotate"></i></p
        ></label>
        <label v-else for="check03"
        @click="arrowUp3()"
          >{{ $t("buttons.other") }}
          <p><i class="arrow down"></i></p
        ></label>
        <ul :class="{'display_block' : arrowClicked3}" class="submenu animate__animated animate__fadeIn" style="animation-duration: 3s;">
          <div class="faqs">
            <FaqCompany
              v-for="(faq, i) in faqsApplication"
              :faq="faq"
              :index="i"
              :key="i"
              :open="faq.open4"
              @toggleOpen="toggleOpen4"
            />
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import FaqCompany from "../components/FaqCompany.vue";
import aboutUsHeader from "../components/aboutUsHeader.vue";
import AboutBack from "../components/AboutBack.vue";
import axios from "axios";
import { mapState } from "vuex";
// import accordion from '../components/accordion.vue'
export default {
  components: { aboutUsHeader, AboutBack, FaqCompany },
  data() {
    return {
      faqs: [],
      faqsPayment: [],
      faqsDesign: [],
      faqsApplication: [],
      active: false,
      open: false,
      arrowClicked: false,
      arrowClicked1: false,
      arrowClicked2: false,
      arrowClicked3: false,
    };
  },
  created() {
    axios
      .get("V1/faq")
      .then((response) => {
        this.faqs = response.data.data.main;
        this.faqsPayment = response.data.data.payment;
        this.faqsDesign = response.data.data.design;
        this.faqsApplication = response.data.data.application;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    arrowDown() {
      this.arrowClicked = false;
    },
    arrowUp() {
      this.arrowClicked = true;
    },
    arrowDown1() {
      this.arrowClicked1 = false;
    },
    arrowUp1() {
      this.arrowClicked1 = true;
    },
    arrowDown2() {
      this.arrowClicked2 = false;
    },
    arrowUp2() {
      this.arrowClicked2 = true;
    },
    arrowDown3() {
      this.arrowClicked3 = false;
    },
    arrowUp3() {
      this.arrowClicked3 = true;
    },
    toggleOpen: function (index) {
      this.faqs = this.faqs.map((faq, i) => {
        if (index === i) {
          faq.open = !faq.open;
          this.open = true
        } else {
          faq.open = false;
          faq.active = false;
          this.open = false
        }
        return faq;
      });
    },
    toggleOpen2: function (index) {
      this.faqsPayment = this.faqsPayment.map((faq, i) => {
        if (index === i) {
          faq.open2 = !faq.open2;
        } else {
          faq.open2 = false;
        }
        return faq;
      });
    },
    toggleOpen3: function (index) {
      this.faqsDesign = this.faqsDesign.map((faq, i) => {
        if (index === i) {
          faq.open3 = !faq.open3;
        } else {
          faq.open3 = false;
        }
        return faq;
      });
    },
    toggleOpen4: function (index) {
      this.faqsApplication = this.faqsApplication.map((faq, i) => {
        if (index === i) {
          faq.open4 = !faq.open4;
        } else {
          faq.open4 = false;
        }
        return faq;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 767px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.display_block {
  display: block !important;
}
.asd {
  position: absolute;
  height: 30px;
  top: 0;
  width: 100%;
}
.rotate {
  transform: rotate(-135deg) !important;
  transition: 0.6s;
}
.arrow {
  border: solid #959595;
  display: inline-block;
  padding: 3px;
  border-width: 0px 2px 2px 0;
  @include mobile() {
    // padding: 2px;
    // border-width: 0 3px 3px 0;
  }
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
input,
ul.submenu {
  display: none;
}

/*position the label*/
label {
  position: relative;
  display: block;
  cursor: pointer;
  @include fontStyle(Roboto, normal, 400, 18px, 22px, #959595);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    margin-left: 10px;
    padding-bottom: 5px;
  }
  @include mobile() {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }
}

/*show the submenu when input is checked*/
input:checked ~ ul.submenu {
  display: block;
  transition: all 0.4s linear;
}
.container {
  padding-left: 0;
}
.acc {
  padding: 80px 0;
  background: #efefef;
  &-card {
    position: relative;
    margin-bottom: 30px;
    label {
      i {
        
      }
    }
  }
  @include mobile() {
    padding: 25px 0;
  }
}
.accordion-title {
  @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 22px;
  text-align: left;
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
}
.faq {
  display: block;
  width: 100%;
  max-width: 1320px;
  margin: auto;
  padding: 15px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  .question {
    position: relative;
    @include fontStyle(Roboto, normal, 400, 18px, 33px, #373737);
    font-size: 18px !important;
    font-weight: 300 !important;
    transition: all 0.4s linear;
    padding: 10px 0 10px 112px;
    p {
      margin-bottom: 0;
    }
    &::after {
      margin-right: 62px;
      content: "+";
      color: #d3d3d3;
      font-size: 57px;
      font-weight: 200;
      border-radius: 50%;
      background: #efefef;
      padding: 5px 6px 7px 5px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotate(0deg);
      width: 42px;
      height: 42px;
      transition: all 0.2s linear;
      @include mobile() {
        display: none;
      }
      @include tablet() {
        display: none;
      }
    }
  }
  @include mobile() {
    background-color: #ffffff !important;
    border: 1px solid #d3d3d3 !important;
    padding: 7px 0px 3px 0px;
    &.question {
      padding: 5px !important;
      margin-left: -40px !important;
    }
  }
  @include tablet() {
    background-color: #ffffff !important;
    border: 1px solid #d3d3d3 !important;
    padding: 7px 0px 3px 0px;
    &.question {
      padding: 5px !important;
      margin-left: -40px !important;
    }
  }
}
.faq.open .question::after {
  content: "-";
  width: 32px;
  height: 33px;
  transition: 400ms;
  font-size: 110px;
  font-weight: 200;
  padding: 2px 7px 7px 5px;
  margin-right: 62px;
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
}
.faq .answer {
  margin-bottom: 0;
  @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
  padding-left: 112px;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
  @include tablet() {
    padding-left: 0;
  }
}
.faq.open .answer {
  opacity: 1;
  max-height: 1000px;
  padding-bottom: 20px;
  &:hover {
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  @include mobile() {
    margin-left: -30px;
  }
}
</style>
