<template>
  <div class="news">
    <div class="container2 m_none">
      <div class="news-top">
        <h2 class="news-title" v-html="title"></h2>
        <span class="all"
          ><a href="/pressAbout">{{ $t("buttons.allNews") }}</a>
          <img src="../images/arrow-purple.png" alt=""
        /></span>
      </div>
      <div class="news-item m_none">
        <div class="news-item__card" v-for="n in news.slice(0, 2)" :key="n">
          <v-lazy-image :src="cdn + n.icon" alt="" />
          <div class="news-item__card__words">
            <p class="date" v-html="format_date(n.created_at)"></p>
            <p class="par" v-html="n.title"></p>
            <a :href="n.link" v-if="n.link" class="more">{{
              $t("buttons.moreBtn")
            }}</a>
            <router-link
              class="more"
              v-else
              :to="{ path: `/news/${n.id}`, params: { id: n.id } }"
              >{{ $t("buttons.moreBtn") }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container d_none">
      <h2 class="news-title" v-html="title"></h2>
      <swiper
        :slidesPerView="1"
        :spaceBetween="0"
        :slidesPerGroup="1"
        :loop="false"
        :loopFillGroupWithBlank="false"
        :pagination="false"
        :navigation="navigation"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="n in news.slice(0, 2)" :key="n">
          <div class="news-item__card">
            <div class="news-item__card-img">
              <img :src="cdn + n.icon" alt="" />
            </div>
            <div class="news-item__card__words">
              <p class="date" v-html="format_date(n.created_at)"></p>
              <p class="par" v-html="n.title"></p>
              <a :href="n.link" class="more" v-if="n.link">
                <p>{{ $t("buttons.moreBtn") }}</p>
                <img
                  class="arrow d_none"
                  src="../assets/images/Arrow.png"
                  alt=""
                />
              </a>
              <router-link
                class="more"
                v-else
                :to="{ path: `/news/${n.id}`, params: { id: n.id } }"
                >{{ $t("buttons.moreBtn") }}</router-link
              >
              <!-- <a href="/pressAbout" class="more">Подробнее</a> -->
            </div>
          </div>
        </swiper-slide>
        <div class="all-news-card">
          <a href="/pressAbout" class="all-news">
            {{ $t("buttons.allNews") }}
          </a>
          <a href="/pressAbout"
            ><img src="../images/arrow-purple.png" alt=""
          /></a>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import moment from "moment";
import VLazyImage from "v-lazy-image";
export default {
  name: "NewsCompany",
  components: {
    Swiper,
    SwiperSlide,
    VLazyImage
  },
  data() {
    return {
      news: [],
      errors: [],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      title: [],
    };
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  created() {
    axios
      .get("V1/company-news")
      .then((response) => {
        this.news = response.data.data.data;
        this.title = response.data.bit_title;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    format_date(value) {
      const lang = localStorage.getItem('lang');
      if(lang == 'kz') {
        moment.locale('kk');
      }
      else if(lang == 'ru') {
        moment.locale('ru')
      }
      if (value) {
        return moment(String(value)).format('LL');
      }
      // if(lang === 'kz') {
      //   return moment.locale('kk');
      // }
      // else if(lang === 'kz') {
      //   return moment.locale('kk');
      // }
      // else if(lang === 'ru') {
      //   return moment.locale('ru');
      // }
    },
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}

@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.all {
  @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
  img {
    margin-left: 7px;
  }
  a {
    color: #8948b1 !important;
  }
}
.all-news {
  @include fontStyle(Roboto, normal, 300, 14px, 14px, #8948b1);
  padding-top: 8px;
  &-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.container2 {
  // width: 80%;
  margin: 0 auto;
  max-width: 1320px;
  width: 80%;
}
.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  position: absolute;
  top: 50%;
}
.container {
  position: relative;
  @include tablet() {
    width: 90%;
  }
}
.swiper {
  &-button {
    &-prev {
      z-index: 111;
      // background: url('@/assets/images/left1.png');
      background-repeat: no-repeat;
      left: 2px;
      &::after {
        opacity: 0;
      }
    }
    &-next {
      z-index: 111;
      // background: url('@/assets/images/right1.png');
      background-repeat: no-repeat;
      right: -10px;
      &::after {
        opacity: 0;
      }
    }
  }
}
a {
  p {
    margin-bottom: 0 !important;
  }
}
.news {
  // padding-top: 122px;
  background-color: #efefef;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 34px;
  }
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 119.69%, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;
    &__card {
      display: flex;
      img {
        width: 303px;
        height: 192px;
        border-radius: 15px;
        object-fit: cover;
      }
      &__words {
        padding-left: 20px;
        padding-top: 17px;
        position: relative;
        .arrow {
          width: 12px;
          height: 5px;
        }
        .date {
          @include fontStyle(Roboto, normal, 300, 18px, 119.69%, #959595);
          margin-bottom: 0;
          padding-bottom: 23px;
        }

        .par {
          @include fontStyle(Roboto, normal, 400, 20px, 119.69%, #373737);
        }
        .more {
          position: absolute;
          bottom: 0;
          @include fontStyle(Roboto, normal, 300, 18px, 119.69%, #8948b1);
          // background: linear-gradient(90deg, #F5010A 0%, #1621E8 100%) !important;
          // -webkit-background-clip: text !important;
          // -webkit-text-fill-color: transparent;
          // background-clip: text;
          display: flex;
          align-items: baseline;
          &:hover {
            font-weight: 200;
            color: #8948b1;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // background-clip: text;
          }
        }
      }
    }
  }
  @media (max-width: 356px) {
    &-item {
      &__card {
        &-img {
          img {
            width: 142px !important;
          }
        }
      }
    }
  }
  @include mobile() {
    padding-top: 40px !important;
    padding-bottom: 10px;
    background: #ffffff;

    &-title {
      padding-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 11.97%,
        #1b27ff 29%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: 90%;
      // margin: 0 auto;
    }
    &-item {
      padding-bottom: 0px;
      flex-wrap: wrap;
      &__card {
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        &:last-child {
          // padding-top: 35px;
        }
        img {
          border-radius: 5px;
          width: 162px;
          height: 92px;
        }
        &__words {
          padding-top: 0;
          padding-left: 12px;
          .date {
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 5px;
            padding-bottom: 0;
          }

          .par,
          .more {
            //changes
            font-size: 14px;
            line-height: 15px;
          }

          .par {
            // margin-top: -10px;
            margin-bottom: 0px;
          }

          .more {
            position: fixed;
            margin-top: 5px;
            color: #acacac;
            font-size: 12px;
            text-decoration: revert;
            p {
              margin-bottom: 0 !important;
              background: linear-gradient(
                90deg,
                #f5010a 0%,
                #1621e8 100%
              ) !important;
              -webkit-background-clip: text !important;
              -webkit-text-fill-color: transparent;
              background-clip: text;
            }
            span img {
              width: 12px;
              height: 5px;
            }
          }
        }
      }
    }
  }

  @include tablet() {
    padding-top: 35px;
    padding-bottom: 20px;
    background: #ffffff;

    &-title {
      font-size: 16px;
      line-height: 17px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-left: 0px;
      padding-bottom: 5px;
    }

    &-item {
      padding-bottom: 30px;
      flex-wrap: wrap;

      &__card {
        justify-content: center;
        max-width: 100%;
        margin: 0 auto;
        &:last-child {
          // padding-top: 15px;
          // max-width: 90%;
          // margin: 0 auto;
        }

        img {
          width: 155px;
          height: 100px;
          border-radius: 5px;
        }

        &__words {
          padding-top: 0;
          .date {
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 5px;
            padding-bottom: 0;
          }

          .par,
          .more {
            font-size: 14px;
            line-height: 15px;
          }

          .more {
            position: fixed;
            color: #acacac;
            font-size: 12px;
            text-decoration: revert;
            span img {
              width: 12px;
              height: 5px;
            }
            p {
              background: linear-gradient(
                90deg,
                #f5010a 0%,
                #1621e8 100%
              ) !important;
              -webkit-background-clip: text !important;
              -webkit-text-fill-color: transparent;
              background-clip: text;
            }
          }
        }
      }
    }
  }

  @include tablet-1024() {
    padding-top: 30px;

    &-item {
      flex-wrap: wrap;

      &__card {
        padding-top: 20px;

        img {
          width: 200px;
          height: 160px;
          border-radius: 5px;
        }

        &__words {
          .date {
            font-size: 12px;
          }

          .par {
            font-size: 15px;
            line-height: 17px;
          }

          .more {
            font-size: 15px;
            line-height: 17px;
            color: #acacac;
            font-size: 12px;
            text-decoration: revert;
            p {
              background: linear-gradient(
                90deg,
                #f5010a 0%,
                #1621e8 100%
              ) !important;
              -webkit-background-clip: text !important;
              -webkit-text-fill-color: transparent;
              background-clip: text;
            }
            span {
              img {
                width: 15px;
                height: 4px;
              }
            }
          }
        }
      }
    }
  }
  @include desktop() {
    &-item {
      width: 100%;
      &__card {
        width: 47%;
        img {
          width: 60%;
          max-width: 390px;
          // width: 371px;
          // height: 200px;
        }
        &__words {
          .date {
            font-size: 12px;
          }

          .par {
            font-size: 18px;
            line-height: 19px;
          }

          .more {
            text-decoration-line: underline !important;
            &:hover {
              color: #8948b1;
            }
          }
        }
      }
    }
  }
  @include desktop-max() {
    &-item {
      width: 100%;
      &__card {
        width: 47%;
        &:nth-child(2) {
          padding-left: 2%;
        }
        img {
          max-width: 371px;
          min-width: 360px;
          height: 200px;
        }
        &__words {
          .more {
            text-decoration-line: underline !important;
            &:hover {
              color: #8948b1;
            }
          }
        }
      }
    }
  }
}
</style>
