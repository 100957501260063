<template>
    <div class="container">
        <p v-html="doc.description">
        </p>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            doc: []
        }
    },
    created() {
    axios.get('V1/document?slug=condition')
      .then(response => {
        this.doc = response.data.data
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
}
</script>
<style lang="scss" scoped>
    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #373737;

        margin-top: 50px;
        margin-bottom: 50px;
    }
    ul {
            li {
                margin-left: 30px !important;
            }
        }
</style>