<template>
    <div class="top">
        <div class="container">
            <h2 class="top-title-mobile" v-html="bigT"></h2>
            <!-- <h2 class="top-title-mobile">Топ 7 продуктов</h2> -->
        </div>
            <swiper
            :slidesPerView="5"
            :spaceBetween="25"
            :slidesPerGroup="1"
            :loop="true"
            :pagination="false"
            :navigation="navigation11"
            :modules="modules"
            class="mySwiper">
                <swiper-slide v-for="sl in slide" :key="sl">
                    <a :href="sl.link">
                        <img class="mySwiper__img" :src="cdn + sl.image" alt="">
                        <p class="mySwiper__title" v-html="sl.mobile_title"></p>
                    </a>
                </swiper-slide>
            </swiper>
            <!-- <div class="navigation-btn">
                <div class="swiper-button-prev1">
                    <img src="../assets/images/left1.png" alt="">
                </div>
                <div class="swiper-button-next1">
                    <img src="../assets/images/right1.png" alt="">
                </div>
            </div> -->
    </div>
</template>


<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import axios from 'axios';
import { mapState } from "vuex";

export default {
    name: 'SliderMain1',
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            navigation11: {
                nextEl: '.swiper-button-next1',
                prevEl: '.swiper-button-prev1',
                slide: [],
                title: []
            },
            slide: [],
            bigT: [],
        }
    },
    setup() {
        return {
        modules: [Pagination, Navigation],
        };
    },
    created(){
        axios.get('V1/top-products')
            .then(response => {
                this.slide = response.data.data
                this.bigT = response.data.big_title
            })
            .catch(e => {
                this.errors.push(e)
            })
    },
    computed :{
        ...mapState(['cdn']),
    },
};
</script>

<style lang="scss" scoped>
.top{
    padding: 0px!important;
    position: relative;
}
.swiper{
    width: 100%;
    // margin-left: -10%;
    swiper-slide{
        
        .a{
            margin: 0;
                margin-left: 10px;
            margin-right: 6px;
            .mySwiper__img{
                margin: 0;
                
            }
        }
    }
}
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 769px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    .container {
        width: 90%;
    }
    .navigation-btn {
        width: 90%;
        height: 20px;
        position: absolute;
        top: 46%;
        display: flex;
        justify-content: space-between;
    }
    .top {
        background: #EFEFEF;
        &-title {
            @include fontStyle(Roboto, normal, 500, 36px, 119.69%, #373737);
            letter-spacing: 0.03em;
            text-transform: uppercase;
            padding-bottom: 30px;
            &-mobile {
                display: none;
            }
            span {
                background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
        &-sub {
            @include fontStyle(Roboto, normal, 300, 20px, 119.69%, #373737);
            padding-bottom: 48px;
        }
        @include mobile() {
            background: #EFEFEF;
            //changes
            padding-top: 0px;
            padding-bottom: 0px;
            &-title {
                display: none;
                &-mobile {
                    padding-top: 35px;
                    padding-bottom: 5px;
                    display: block;
                    @include fontStyle(Roboto, normal, 500, 14px, 17px, transparent);
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    background: linear-gradient(90deg, #FF143F 0%, #C600DD 10.97%, #1B27FF 25%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    // padding-bottom: 14px;
                }
            }
            &-sub {
                display: none;
            }
        }
        @include tablet() {
            padding-top: 30px;
            padding-bottom: 30px;
            &-title {
                display: none;
                &-mobile {
                    display: block;
                    @include fontStyle(Roboto, normal, 500, 16px, 18px, transparent);
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    background: linear-gradient(90deg, #FF143F 0%, #C600DD 10.97%, #1B27FF 25%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    padding-bottom: 20px;
                    padding-top: 15px;
                }
            }
            &-sub {
                display: none;
            }
        }
        @include tablet-1024() {
            &-title {
                font-size: 26px;
            }
            &-sub {
                font-size: 15px;
            }
        }
    }
    .swiper {
        // max-width: 1320px;
        // width: 100%;
        // height: 100%;
        // top: -130px;
        // bottom: -130px;
        // width: 90%;
        &-button {
            &-next1, &-prev1 {
                top: 40% !important;
            }
            &-next1 {
                right: 0px;
                // background: url('../assets/images/right1.png');
                background-repeat: no-repeat;
                &::after {
                    opacity: 0;
                }
            }
            &-prev1 {
                left: 4px;
                // background: url('../assets/images/left1.png');
                background-repeat: no-repeat;
                &::after {
                    opacity: 0;
                }
            }
        }
        &-slide {
            text-align: center;
            font-size: 18px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            flex-wrap: wrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            img {
                display: block;
                object-fit: cover;
            }
            .mySwiper {
                align-items: center;
                &__img {
                    // max-width: 80%;
                    // height: 300px;
                    margin-left: 30px;
                    margin-left: 30px;
                    // margin-top: 120px;
                    &:hover {
                        filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.35));
                        border-radius: 15px;
                    }
                }
                &__title {
                    text-align: center;
                    width: 100%;
                    @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
                    text-align: center;
                    margin-top: 30px;
                }
                &__sub {
                    @include fontStyle(Roboto, normal, 300, 14px, 22px, #373737);
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 25px;
                }
                &__btn {
                    width: 174px;
                    height: 67px;
                    margin: auto;
                    text-align: end;
                    button {
                        width: 174px;
                        height: 67px;
                        border-radius: 15px;
                        border: none;
                        @include fontStyle(Roboto, normal, 500, 18px, 136.69%, #373737);
                        background: #FFFFFF;
                        &:hover {
                            background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
                            border-radius: 7px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
        @include mobile() {
            padding-bottom: 25px;
            &-slide {
                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .mySwiper {
                    &__img {
                        max-width: 73px;
                        min-width: 66px;
                        height: 82px;
                        margin-right: 5px;
                        margin-left: 5px;
                        border-radius: 15px;
                    }
                    &__title {
                        //change
                        font-size: 12px;
                        line-height: 14px;
                        margin-top: 5px;
                        text-align: center;
                    }
                    &__sub, &__btn {
                        display: none;
                    }
                }
            }
            &-button {
                &-next1::after, &-prev1::after {
                    font-size: 15px;
                }
            }
        }
        @include tablet() {
            &-slide {
                .mySwiper {
                    width: 90px;
                    display: flex;
                    flex-wrap: wrap;
                    &__img {
                        width: 90px;
                        height: 100px;
                        margin-left: 12px;
                        margin-right: 12px;
                        border-radius: 15px;
                    }
                    &__title {
                        font-size: 12px;
                        line-height: 15px;
                        margin-top: 5px;
                    }
                    &__sub, &__btn {
                        display: none;
                    }
                }
            }
            &-button {
                &-next1::after, &-prev1::after {
                    font-size: 15px;
                }
            }
        }
        @include tablet-1024() {
            &-slide {
                .mySwiper {
                    width: 165px;
                    align-items: center;
                    &__img {
                        width: 160px;
                        height: 160px;
                    }
                    &__title {
                        font-size: 14px;
                        line-height: 18px;
                        margin-top: 0;
                    }
                    &__sub {
                        font-size: 10px;
                        line-height: 12px;
                        margin-top: 5px;
                        margin-bottom: 45px;
                    }
                    &__btn {
                        button {
                            width: 100px;
                            height: 30px;
                            border-radius: 5px;
                            font-size: 12px;
                            margin: auto;
                        }
                    }
                }
            }
        }
        @include desktop() {
            &-slide {
                .mySwiper {
                    align-items: center;
                    &__img {
                        width: 200px;
                        height: 240px;
                        border-radius: 15px;
                    }
                    &__btn {
                        display: none;
                    }
                }
            }
        }
    }
    
</style>