<template>
    <div class="slider">
        <div class="slider-back">
            <div class="container-slider">
                <h4 class="slider-title" v-html="progressTitle.title"></h4>
                <!-- <h4 class="slider-title">Отлаженный бизнес-процесс перехода <br> под нашу <span>страховую защиту</span></h4> -->
                <swiper
                    :spaceBetween="30"
                    :centeredSlides="true"
                    :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                    }"
                    :pagination="{
                    clickable: true,
                    }"
                    :navigation="false"
                    :modules="modules"
                    class="mySwiper">

                    <swiper-slide>
                        <div class="box">
                            <div class="box-left" >01.</div>
                            <div class="box-right" v-html="progress1"></div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="box">
                            <div class="box-left">02.</div>
                            <div class="box-right" v-html="progress2"></div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="box">
                            <div class="box-left">03.</div>
                            <div class="box-right" v-html="progress3"></div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="box">
                            <div class="box-left">04.</div>
                            <div class="box-right"  v-html="progress4"></div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from 'axios';
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data () {
        return {
            scrollAsd: null,
            reached: false,
            scaleClass: false,
            progressTitle: [],
            progress1: [],
            progress2: [],
            progress3: [],
            progress4: [],
        }
    },
    created() {
        axios.get('V1/business_process')
        .then(response => {
            this.progressTitle = response.data.data.title
            this.progress1 = response.data.data.descriptions[0].description
            this.progress2 = response.data.data.descriptions[1].description
            this.progress3 = response.data.data.descriptions[2].description
            this.progress4 = response.data.data.descriptions[3].description
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    setup() {
        return {
        modules: [Autoplay, Pagination, Navigation],
        };
    },
};
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 769px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1025px) and (max-width: 1440px) {
            @content;
        }
    }
    div {
        p {
            margin-bottom: 0;
        }
    }
    .container-slider {
        width: 90%;
        margin: 0 auto;
        @include tablet() {
            width: 80%;
        }
        @include tablet-1024() {
            width: 80%;
        }
    }
    .slider {
        background: #EFEFEF;
        margin-top: 30px;
        // padding-top: 30px;
        &-back {
            background-image: url('../assets/images/cargo-wave2.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
        &-title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #373737;
            padding-bottom: 15px;
            padding-top: 30px;
            width: 100%;
            // margin: 0 auto;
            span {
                background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
        @include tablet() {
            &-title {
                font-size: 15px;
                line-height: 18px;
                padding-bottom: 25px;
                width: 90%;
            }
        }
        @include tablet-1024() {
            &-title {
                font-size: 24px;
                line-height: 32px;
                padding-bottom: 35px;
                padding-top: 50px;
                width: 90%;
            }
        }
    }
    .box {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        &-left {
            @include fontStyle(Roboto, normal, 900, 45px, 62px, #FFFFFF);
            text-transform: uppercase;
            // backdrop-filter: blur(7px);
        }
        &-right {
            //change
            @include fontStyle(Roboto, normal, 400, 12px, 14px, #373737);
            text-align: left;
        }
        @include tablet() {
            &-left {
                font-size: 50px;
            }
            &-right {
                font-size: 14px;
                line-height: 17px;
            }
        }
        @include tablet-1024() {
            &-left {
                font-size: 60px;
            }
            &-right {
                font-size: 20px;
                line-height: 27px;
            }
        }
    }
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        // -webkit-justify-content: center;
        // justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

</style>