<template>
	<div :class="open ? 'faq open' : 'faq'" @click="$emit('toggleOpen', index)">
		<div class="question" v-html="faq.year"></div>
		<!-- <a :href="cdn + fq.doc" download ><div class="answer" ><img class="download-img" src="@/assets/images/download.svg" alt="">{{ fq.description}}</div></a> -->
		<div v-for="f in faq.date" :key="f">
			<a :href="cdn + f.doc" download ><div class="answer" ><img class="download-img" src="@/assets/images/download.svg" alt="">{{ f.description}}</div></a>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex';
// import axios from 'axios';
export default {
	name: 'FAQ',
	props: ['faq', 'index', 'open'],
	computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
	.faq.open .answer{
		padding-bottom: 15px;
		display: flex;
    	align-items: center;
		&:hover {
			background: linear-gradient(90deg, #FF143F 15%, #C600DD 32.97%, #1B27FF 48%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
		}
	}
	a {
		text-decoration: none;
	}
	.download-img {
		width: 20px;
	}
</style>