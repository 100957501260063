<template>
  <div id="app">
    <div class="container">
        <div class="director-title" v-html="directorTitle"></div>
        <div class="director-sub" v-html="directorDesc1"></div>
    </div>

    <swiper
    :slidesPerView="3"
    :spaceBetween="30"
    :centeredSlides="true"
    :pagination="{
      clickable: false,
    }"
    :loop="true"
    :navigation="navigation1"
    :modules="[Thumbs]" :thumbs="{ swiper: thumbsSwiper }"
    
    class="mySwiper"
  >
  
    <swiper-slide v-for="dr in director" :key="dr">
      <button class="slide-btn">
        <h4 v-html="dr.title"></h4>
        <p v-html="dr.position"></p>
      </button>
    </swiper-slide>
    <div class="nav-btn">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
  </swiper>
  <swiper
    :pagination="{
      dynamicBullets: true,
    }"
    :modules="[Thumbs]"
    watch-slides-progress
    @swiper="setThumbsSwiper"
    class="mySwiper2"
  >
    <swiper-slide v-for="ds in director" :key="ds">
      <div class="team">
        <div class="team-left" v-if="ds.image"><img :src="cdn + ds.image" alt=""></div>
        <div class="team-right">
          <p v-html="ds.description"></p>
        </div>
      </div>
    </swiper-slide>
  </swiper>

  </div>
</template>

<script scoped>
  import { ref } from 'vue';
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Thumbs } from 'swiper';
  import "swiper/css";
  import "swiper/css/pagination";
  import "swiper/css/navigation";
  import SwiperCore, { Navigation } from "swiper";
  import { Autoplay, Pagination} from "swiper";
  import axios from "axios";
  import {mapState} from 'vuex';
  SwiperCore.use([Navigation, Autoplay]);
  export default {
  name: "App",
  components: {
    Pagination,
    Swiper,
    SwiperSlide,
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
      modules: [Autoplay, Pagination, Navigation],
    };
  },

  data() {
    return {
      showLoader: false,
      items: [],
      page: 2,
      totalPages: 5,
      totalRecords: 10,
      recordsPerPage: 10,
      activeBtn:'btn1',
      director: [],
      directorTitle:[],
      directorDesc1:[],
      // manager: [],
      navigation1: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      }
    };
  },
  created() {
    axios.get('V1/company-managements')
      .then(response => {
        this.director = response.data.data.directors
        this.directorTitle = response.data.big_title
        this.directorDesc1 = response.data.desc_1
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  computed :{
      ...mapState(['cdn']),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
    @media(min-width: 320px) and (max-width: 425px) {
        @content;
    }
}

@mixin tablet {
    @media(min-width: 425px) and (max-width: 767px) {
        @content;
    }
}

@mixin tablet-1024 {
    @media(min-width: 767px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media(min-width: 1024px) and (max-width: 1620px) {
        @content;
    }
}

@mixin desktop-max {
    @media(min-width: 1621px) and (max-width: 2560px) {
        @content;
    }
}
.swiper-button-disabled{
    display:none;   
}
.swiper {
  &-button {
    &-prev {
      left: 0%;
      background: url('@/assets/images/left.png');
      &::after{
        content: '';
      }
    } 
    &-next {
      right: 0%;
      background: url('@/assets/images/right.png');
            &::after{
        content: '';
      }
    }
  }
}
.mySwiper2 {
  width: 80%;
  max-width: 1320px;
  margin: 0 auto;
}
.container {
    padding-left: 0;
}
.active{
  background-color:red;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.director {
    &-title {
      @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-align: left;
      padding-top: 75px;
      padding-bottom: 20px;
    }
    &-sub {
      @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
      text-align: left;
    }
    @include tablet-1024() {
      &-title {
        font-size: 18px;
        line-height: 22px;
      }
      &-sub {
        font-size: 14px;
        line-height: 18px;
      }
    }
    @include desktop() {
      &-title {
        font-size: 22px;
        line-height: 28px;
      }
      &-sub {
        font-size: 20px;
        line-height: 23px;
      }
    }
}
.team {
  display: flex;
  margin-top: 44px;
  margin-bottom: 45px;
  &-right {
    margin-left: 48px;
    p {
      @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
      text-align: left;
      padding-top: 15px;
    }
  }
  &-left {
    img {
      // max-width: 250px;
      // max-height: 287px;
      width: 250px;
      height: 287px;
      object-fit: cover;
      float: left;
      border-radius: 15px;
    }
  }
  @include tablet-1024() {
    margin-top: 10px;
    margin-bottom: 15px;
    &-left {
      img {
        width: 175px;
      }
    }
    &-right {
      margin-left: 25px;
      p {
        font-size: 12px;
        line-height: 15px;
        padding-top: 0;
      }
    }
  }
  @include desktop() {
    margin-top: 20px;
    margin-bottom: 25px;
    &-left {
      img {
        width: 200px;
      }
    }
    &-right {
      p {
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
}
.slide-btn {
  background: #FFF9F9;
  border-radius: 15px;
  border: none;
  width: 350px;
  height: 70px;
  margin-top: 35px;
  h4 {
    @include fontStyle(Roboto, normal, 400, 18px, 25px, #373737);
    

    padding-top: 10px;
    margin-bottom: 0;
  }
  p {
    @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
    p {
      margin-bottom: 0;
    }
  }
  @include desktop() {
    width: 220px;
    height: 60px;
    h4 {
      font-size: 14px;
      line-height: 18px;
    }
    p {
      font-size: 10px;
      line-height: 12px;
    }
  }
  @include tablet-1024() {
    border-radius: 8px;
    width: 170px;
    height: 60px;
    h4 {
      font-size: 13px;
      line-height: 15px;
    }
    p {
      font-size: 9px;
      line-height: 12px;
    }
  }
}
ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style-type: none;
}
li {
  border: 1px solid black;
}

.mySwiper{
  overflow: hidden;
  height: 130px;
  width: 75%;
  .swiper-slide-active{
    transform: scale(1.3);
      h4{
        background: linear-gradient(90deg, #FF143F 15%, #C600DD 46.97%, #1B27FF 77%);
            -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
      }
  }
}

</style>
