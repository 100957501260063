<template>
  <about-us-header />
  <about-back />
  <div class="news animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="container">
      <p class="news-title" v-html="title"></p>
      <div class="news-card" v-for="pr in press.data" :key="pr">
        <img :src="cdn + pr.icon" alt="" />
        <div class="news-card__par">
          <p class="news-card__par__date">{{ format_date(pr.created_at) }}</p>
          <p class="news-card__par__title" v-html="pr.title"></p>
          <p class="news-card__par__sub" v-html="pr.description"></p>
          <a v-if="pr.link" :href="pr.link" class="news-card__par__more">{{
            $t("buttons.moreBtn")
          }}</a>
          <router-link
            class="news-card__par__more"
            v-else
            :to="{ path: `/news/${pr.id}`, params: { id: pr.id } }"
            >{{ $t("buttons.moreBtn") }}</router-link
          >
        </div>
      </div>
      <paginate
        :page-count="press.last_page"
        :click-handler="clickCallback"
        :prev-text="'<span><</span>'"
        :next-text="'>'"
        :container-class="'className'"
      >
      </paginate>
      <!-- <p>Current page: {{ currentPage }}</p>
      <v-pagination v-model="currentPage" :page-count="30"></v-pagination> -->
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "../components/aboutUsHeader.vue";
import AboutBack from "./AboutBack.vue";
// import pressSlider from "./pressSlider.vue";
import Paginate from "vuejs-paginate-next";
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "./style.css";
import { Pagination } from "swiper";
export default {
  components: {
    aboutUsHeader,
    AboutBack,
    Swiper,
    SwiperSlide,
    paginate: Paginate,
  },
  data() {
    return {
      press: null,
      title: [],
      currentPage: 1,
      selectedPage: 1,
    };
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  mounted() {
    this.fetchAbout();
  },
  methods: {
    format_date(value) {
      const lang = localStorage.getItem('lang') || 'ru';
      if(lang == 'kz') {
        moment.locale('kk');
      }
      else if(lang == 'en') {
        moment.locale('en')
      }
      else {
        moment.locale('ru')
      }
      if (value) {
        return moment(String(value)).format('LL');
      }
    },
    clickCallback(pageNum) {
      console.log(pageNum);
      this.selectedPage = pageNum;
    },
    fetchAbout() {
      axios
        .get(`V1/company-news?page=${this.selectedPage}`)
        .then((response) => {
          this.press = response.data.data;
          this.title = response.data.bit_title;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  computed: {
    ...mapState(["cdn"]),
  },
  watch: {
    selectedPage() {
      this.fetchAbout();
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.swiper-slide {
  padding-bottom: 20px !important;
}
.className {
  list-style: none;
  display: flex;
  justify-content: center;
}
.page-link {
  color: #8948b1 !important;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #c977de;
  border-color: #c977de;
}
.back {
  @include mobile() {
    background-color: #efefef;
  }
  @include tablet() {
    background-color: #efefef;
  }
}
.container {
  padding-left: 0;
}
.info {
  padding: 50px 0 70px 0;
  &-title {
    @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    padding-bottom: 25px;
  }
  &-bottom {
    display: flex;
    &__name,
    &__mail {
      padding: 13px 0 16px 10px;
      width: 330px;
      margin-right: 30px;
      background: #efefef;
      border-radius: 15px;
      border: none;
      @include fontStyle(Roboto, normal, 300, 18px, 21px, #959595);
    }
    &__btn {
      button {
        width: 182px;
        padding: 23px 35px 26px 35px;

        border-radius: 15px;
        @include fontStyle(Roboto, normal, 700, 18px, 21px, #8948b1);
        border: none;
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #fff;
        }
      }
    }
  }
  @include mobile() {
    padding: 20px 0 30px 0;
    &-title {
      font-size: 10px;
      line-height: 12px;
    }
    &-bottom {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      flex-wrap: wrap;
      width: 80%;
      margin: 0 auto;
      &__name,
      &__mail {
        width: 200px;
        height: 28px;
        background: #fff;
        border: 2px solid #efefef;
        border-radius: 3px;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 20px;
      }
      &__btn {
        button {
          width: 80px;
          height: 28px;
          font-size: 9px;
          line-height: 12px;
          padding: 0;
          border-radius: 3px;
        }
      }
    }
  }
  @include tablet() {
    padding: 20px 0 30px 0;
    &-title {
      font-size: 10px;
      line-height: 12px;
    }
    &-bottom {
      flex-wrap: wrap;
      width: 100%;
      margin: 0 auto;
      &__name,
      &__mail {
        width: 200px;
        height: 28px;
        background: #fff;
        border: 2px solid #efefef;
        border-radius: 3px;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 20px;
      }
      &__btn {
        button {
          width: 80px;
          height: 28px;
          font-size: 9px;
          line-height: 12px;
          padding: 0;
          border-radius: 3px;
        }
      }
    }
  }
}
.news {
  padding-bottom: 50px;
  background: #efefef;
  &-title {
    padding: 50px 0 70px 0;
    margin-bottom: 0;
    @include fontStyle(Roboto, normal, 600, 24px, 29px, #373737);
  }
  &-card {
    background: #ffffff;
    display: flex;
    margin-bottom: 60px;
    padding: 40px;
    img {
      object-fit: cover;
    }
    &__par {
      position: relative;
      padding-left: 30px;
      &__date {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #959595);
      }
      &__title {
        @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        // height: 34px;
      }
      &__sub {
        @include fontStyle(Roboto, normal, 300, 18px, 23px, #373737);
        padding: 10px 0 55px 0;
      }
      &__more {
        position: absolute;
        bottom: 0;
        @include fontStyle(Roboto, normal, 300, 18px, 22px, transparent);
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        cursor: pointer;
      }
    }
    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    }
  }
  @include mobile() {
    &-title {
      padding: 24px 0 10px 0;
      font-size: 14px;
      line-height: 17px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-card {
      padding: 10px;
      margin-bottom: 20px;
      img {
        // min-width: 125px;
        width: 140px;
        height: 95px;
      }
      &__par {
        padding-left: 10px;

        &__date {
          font-size: 10px;
          line-height: 10px;
          margin-bottom: 5px;
        }
        &__title {
          font-size: 14px;
          line-height: 15px;
          margin-bottom: 0;
        }
        &__sub {
          display: none;
        }
        &__more {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
  @include tablet() {
    &-title {
      padding: 24px 0 10px 0;
      font-size: 18px;
      line-height: 20px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-card {
      padding: 10px;
      margin-bottom: 20px;
      width: 100%;
      img {
        width: 140px;
        height: 95px;
      }
      &__par {
        padding-left: 10px;
        &__date {
          font-size: 10px;
          line-height: 10px;
        }
        &__title {
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 0;
        }
        &__sub {
          display: none;
        }
        &__more {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
  @include tablet-1024() {
    &-title {
      padding: 24px 0 10px 0;
      font-size: 25px;
      line-height: 28px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-card {
      padding: 10px;
      margin-bottom: 20px;
      width: 100%;
      img {
        width: 186px;
        height: 107px;
      }
      &__par {
        padding-left: 10px;
        &__date {
          font-size: 10px;
          line-height: 12px;
        }
        &__title {
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 0;
        }
        &__sub {
          display: none;
        }
        &__more {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
  @include desktop() {
    &-card {
      img {
        height: 260px;
        width: 387px;
      }
      &__par {
        &__date {
          font-size: 12px;
          line-height: 14px;
        }
        &__title {
          font-size: 16px;
          line-height: 20px;
        }
        &__sub {
          font-size: 14px;
          line-height: 18px;
          padding: 10px 0 25px 0;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          height: 155px;
        }
        &__more {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
  @include desktop-max() {
    &-card {
      img {
        height: 223px;
        max-width: 392px;
        min-width: 390px;
        width: 100%;
      }
      &__par {
        &__sub {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          height: 80px;
        }
      }
    }
  }
}
</style>
