<template>
  <div>
    <head-bottom />
    <div class="cargo">
      <div class="container">
        <div class="cargo-left">
          <p class="cargo-left__title" v-html="cargoT"></p>
          <hr class="cargo-left__line" />
          <p class="cargo-left__sub" v-html="cargo1.description"></p>
          <div class="cargo-left__btn">
            <button @click="isOpen = true, this.$refs.myForm.resetForm()" id="click12">
              {{ cargo1.button }}
            </button>
          </div>
          <modal :open="isOpen" @close="isOpen = !isOpen">
            <get-discount />
            <Form ref="myForm" @submit="Send">
            <div class="modal-inputs">
              <div class="err-message">
                <Field
                  v-model="GuestName"
                  name="username"
                  type="text"
                  class="name"
                  :placeholder="$t('placeholder.name')"
                  :rules="requiredName"
                  @keyup="onlyText()"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="username" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-mask="'+7(7##)-###-##-##'"
                  v-model="GuestPhone"
                  type="text"
                  :placeholder="$t('placeholder.phone')"
                  name="phone"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="phone" />
                </div>
              </div>
              <div class="err-message">
                <Field
                  v-model="organization_name"
                  type="text"
                  :placeholder="$t('placeholder.company')"
                  name="organization"
                  class="name"
                  :rules="requiredNum"
                />
                <div :class="{ remove: IsRemove }" class="">
                  <ErrorMessage name="organization" />
                </div>
              </div>
              <textarea
                v-model="guest_desc"
                :placeholder="$t('placeholder.com')"
              ></textarea>
            </div>
            <div class="modal-btn">
              <button
                type="submit"
                :class="{isDisabled : disabled}"
                @click="buttonClicked"
              >
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                ></div>
                {{ $t("buttons.send") }}
              </button>
            </div>
            <div class="modal-rule">
              <input type="checkbox" id="check" v-model="checked" />
              <label for="check"
                >{{ $t("modal.check1") }}
                <a href="/condition">{{ $t("modal.check2") }}</a></label
              >
            </div>
          </Form>
          </modal>
        </div>
        <div class="cargo-right">
          <div class="cargo-right__line"></div>
          <!-- <hr class="cargo-right__line"> -->
          <div class="cargo-right__card">
            <ul>
              <li v-html="cargo1.mini_description_first"></li>
              <li v-html="cargo1.mini_description_second"></li>
              <li v-html="cargo1.mini_description_third"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <hr class="middle-line" />
      <div class="container">
        <div class="middle-box">
          <p class="middle-box__title" v-html="tabs[0].big_title"></p>
          <div class="cargo-slider">
            <cargo-slider />
          </div>
          <div class="middle-box__bottom">
            <div class="middle-box__bottom-1">
              <button
                v-for="t in tabs.slice(0, 3)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>

            <div class="middle-box__bottom-3">
              <button
                v-for="t in tabs.slice(3, 6)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>
            <div class="middle-box__bottom-2" v-if="tabContent">
              <img :src="cdn + tabContent.icon" alt="" />
              <div class="par" v-html="tabContent.description"></div>
              <div class="btn">
                <button
                  @click="isOpen = true, this.$refs.myForm.resetForm()"
                  id="click12"
                  v-html="tabContent.button"
                ></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
              </div>
            <div class="middle-box__bottom-2" v-else>
              <img :src="cdn + tabs[0].icon" alt="" />
              <div class="par" v-html="tabs[0].description"></div>
              <div class="btn">
                <button @click="isOpen = true, this.$refs.myForm.resetForm()" v-html="tabs[0].button"></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="risk">
      <div class="container1">
        <div class="risk-title" v-html="cargo3[0].big_title"></div>
        <div class="risk-name">
          <div class="risk-list" v-for="(cr, i) in cargo3" :key="cr">
            <div class="risk-list__num" v-if="i < 10">0{{ i + 1 }}.</div>
            <div class="risk-list__num" v-else>{{ i + 1 }}.</div>
            <div class="risk-list__par">
              <span v-html="cr.title"></span>
              <p v-html="cr.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="range">
      <range />
    </div>
    <div class="sliderRange">
      <slider-range />
    </div>
    <div class="reverse1">
      <div class="cargo-accordion">
        <accordion />
      </div>
      <discount :p_name="p_name" />
    </div>
    <SliderMain1 />
    <div class="slide-btm">
      <client-say />
      <div class="d_none">
        <SliderMainMobile />
      </div>
    </div>
    <div class="wrap">
      <slider-main-2 />
      <div class="d_none">
        <review-slide />
      </div>
    </div>
  </div>
</template>

<script>
import Discount from "../components/Discount.vue";
import HeadBottom from "../components/HeadBottom.vue";
import SliderMain1 from "../components/SliderMain1-1.vue";
import SliderMain2 from "../components/SliderMain2.vue";
import accordion from "../components/accordion.vue";
import range from "../components/range.vue";
import sliderRange from "../components/sliderRange.vue";
import cargoSlider from "../components/box/cargoSlider.vue";
import reviewSlide from "../components/box/reviewSlide.vue";
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { mapState } from "vuex";
import { Buffer, resolveObjectURL } from "buffer";
import { Field, Form, ErrorMessage } from "vee-validate";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
import first from "../assets/images/kasko2.png";
import second from "../assets/images/level.png";
import third from "../assets/images/cargo3.png";
import fourth from "../assets/images/kasko-img.png";
import fifth from "../assets/images/health-img.png";
import sixth from "../assets/images/cargo6.png";
import getDiscount from "@/components/getDiscount.vue";
import clientSay from "@/components/clientSay.vue";

export default {
  components: {
    SliderMain1,
    SliderMain2,
    HeadBottom,
    Discount,
    accordion,
    range,
    modal,
    sliderRange,
    cargoSlider,
    reviewSlide,
    getDiscount,
    clientSay,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },

  data() {
    return {
      loading: false,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      tabContent: null,
      disabled: false,
      cargo: [],
      product_name: "Грузы",
      p_name: "Грузы",
      resp: [],
      IsRemove: false,
      tab: "",
      checked: false,
      organization_name: null,
      guest_desc: null,
      cargo1: [],
      // cargo2: [],
      cargo3: [],
      cargoT: [],
      tabs: [
        {
          title: "Защита",
          img: first,
          text: "Дополнительная защита договором перестрахования от международной организации Hannover Re с высоким рейтингом финансовой устойчивости",
        },
        {
          title: "На всех этапах",
          img: second,
          text: "Защита на всех этапах перевозки: отправка, погрузка, перегрузка, временное хранение и доставка",
        },
        {
          title: "Все виды",
          img: third,
          text: "Страхуем все виды наземного и морского транспорта",
        },
        {
          title: "Покрываем риски",
          img: fourth,
          text: "Покрываем риски в результате стихийных бедствий; пожара, взрыва; нарушения погрузочно-разгрузочных работ; кражи со взломом, грабежа, разбоя; затопления; пожаротушения",
        },
        {
          title: "24/7",
          img: fifth,
          text: "Личный менеджер 24/7",
        },
        {
          title: "Дополнительно",
          img: sixth,
          text: "Специальные условия на легковоспламеняемые и бьющиеся объекты",
        },
      ],
    };
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    tabButton(t) {
      if (t == null) {
        // console.log("test", this.tabs[0]);
        // this.tabs[0] = t
      } else {
        this.tabContent = t;
        // console.log("tab", t);
      }
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "consultant",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          description: this.guest_desc,
          product_name: this.product_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 20000);
              toaster.success(this.$t('notification.success'));
              resetForm();
              setTimeout(() => {
                this.isOpen = false;
              }, 500);
            } else {
              toaster.error(this.$t('notification.req'));
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree')
        );
      }
    },
    getCargo() {
      axios
        .get("V1/top-products")
        .then((response) => {
          // console.log("asdasdasdsada", response);
          this.cargo1 = response.data.data[4].first_block;
          this.cargoT = response.data.data[4].first_block.title;
          this.tabs = response.data.data[4].second_block;
          this.cargo3 = response.data.data[4].third_block;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  created() {
    axios
      .get("V1/insurances_cargo")
      .then((response) => {
        this.cargo = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
    this.getCargo();
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.checked = false;
      this.IsRemove = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background: #ffffff;
}
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 426px) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.middle-line {
  top: 40px;
}
.container {
  display: flex;
  // @include tablet-1024() {
  //     justify-content: space-around;
  // }
  @include desktop() {
    justify-content: space-around;
  }
}
.container1 {
  margin: 0 auto;
  max-width: 1320px;
  width: 78%;
  @include mobile() {
    width: 90%;
  }
}
.sliderMain1-title {
  @include mobile() {
    p {
      font-size: 14px;
      line-height: 17px;
      padding-top: 0;
      padding-bottom: 0;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  @include tablet() {
    p {
      font-size: 17px;
      line-height: 25px;
      padding-top: 0;
      padding-bottom: 0;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  @include tablet-1024() {
    p {
      padding-top: 0;
    }
  }
}
.slide-btm {
  @include mobile() {
    padding-top: 25px;
    padding-bottom: 30px;
    &__title {
      display: none;
    }
  }
  @include tablet() {
    padding-top: 30px;
    padding-bottom: 30px;
    &__title {
      display: none;
    }
  }
}
.cargo {
  padding-bottom: 272px;
  background: url(../assets/images/cargo-back.png);
  background-repeat: no-repeat;
  background-size: auto;
  &-slider {
    display: none;
    @include mobile() {
      display: block;
    }
    @include tablet() {
      display: block;
    }
    @include tablet-1024() {
      display: block;
    }
  }
  &-left {
    &__line {
      border-bottom: 1px solid #ffffff;
      margin-left: -49%;
      margin-right: -11%;
      z-index: 2;
    }
    &__title {
      margin-top: 95px;
      @include fontStyle(Roboto, normal, 500, 36px, 49px, #141414);
      text-transform: uppercase;
    }
    &__sub {
      padding-top: 20px;
      padding-bottom: 50px;
      @include fontStyle(Roboto, normal, 400, 20px, 23px, #373737);
      letter-spacing: 0.01em;
      text-shadow: 0px 4px 50px rgba(0, 0, 0, 0.45);
    }
    &__btn {
      width: 405px;
      height: 70px;
      border-radius: 15px;
      background-color: #ffffff;
      button {
        background-color: #fff;
        width: 405px;
        height: 70px;
        border-radius: 15px;
        @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
        border: none;
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #ffffff;
          button {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #ffffff;
            background-clip: text;
          }
        }
      }
      // &:hover {
      //     background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
      //     color: #FFFFFF;
      //     button {
      //         -webkit-background-clip: text;
      //         -webkit-text-fill-color: #FFFFFF;
      //         background-clip: text;
      //     }
      // }
    }
  }
  &-right {
    &__line {
      z-index: 222 !important;
      margin-left: 101px;
      position: absolute;
      border-left: 1px solid #ffffff;
      height: 619px;
    }
    &__card {
      margin-top: 340px;
      margin-left: 70px;
      width: 340px;
      height: 339px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.28);
      backdrop-filter: blur(20px);
      border-radius: 15px;
      z-index: 1;
      ul {
        padding-left: 45px;
        padding-top: 35px;
        list-style: none;
        li {
          @include fontStyle(Roboto, normal, 400, 18px, 16px, #ffffff);
          text-shadow: 0px 4px 43px rgba(0, 0, 0, 0.15);
          padding-bottom: 0px;
          height: 84px;
        }
      }
      ul li::before {
        content: "\2022";
        color: #ffffff;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -18px;
        font-size: 28px;
      }
    }
  }
  &-accordion {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @include mobile() {
    background: url("../images/cargo-back22.png");
    background-size: cover;
    background-color: #efefef;
    padding-bottom: 0;
    height: 377px;
    &-accordion {
      margin-top: 0;
      margin-bottom: 0;
    }
    &-left {
      width: 100%;
      &__line {
        display: none;
      }
      &__title {
        //change
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        padding: 35px 0 20px 0;
        margin: 0;
      }
      &__sub {
        //change
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 70px;
      }
      &__btn {
        width: 100%;
        height: 0px;
        border-radius: 3px;
        background-color: #ffffff;
        margin-top: 39%;
        button {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          line-height: 16px;
          // background-color: #FFFFFF;
        }
      }
    }
    &-right {
      display: none;
    }
  }
  @include tablet() {
    background: url("../images/cargo-back22.png");
    background-size: cover;
    background-color: #efefef;
    padding-bottom: 0;
    height: 405px;
    &-accordion {
      margin-top: 0;
      margin-bottom: 0;
    }
    &-left {
      width: 100%;
      &__line {
        display: none;
      }
      &__title {
        //change
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        padding: 35px 0 20px 0;
        margin: 0;
      }
      &__sub {
        //change
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 70px;
      }
      &__btn {
        width: 100%;
        height: 0px;
        border-radius: 3px;
        background-color: #ffffff;
        margin-top: 39%;
        button {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          line-height: 16px;
          // background-color: #FFFFFF;
        }
      }
    }
    &-right {
      display: none;
    }
  }
  @include tablet-1024() {
    background-size: cover;
    padding-bottom: 70px;
    &-left {
      width: 100%;
      &__line {
        display: none;
      }
      &__title {
        margin-top: 35px;
        font-size: 17px;
        line-height: 20px;
      }
      &__sub {
        font-size: 14px;
        line-height: 17px;
        padding-top: 10px;
        padding-bottom: 20px;
      }
      &__btn {
        width: 270px;
        height: 50px;
        margin-bottom: 70px;
        margin: 0 auto;
        button {
          width: 270px;
          height: 50px;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
    &-right {
      display: none;
      &__card {
        margin-top: 200px;
        margin-left: 20px;
        margin-bottom: 30px;
        width: 200px;
        height: 140px;
        ul {
          padding-left: 25px;
          padding-top: 20px;
          li {
            font-size: 12px;
            line-height: 17px;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
  @include desktop() {
    background-size: cover;
    padding-bottom: 0;
    &-right {
      &__card {
        margin-top: 180px;
        margin-left: 70px;
        margin-bottom: 20px;
        width: 300px;
        height: 240px;
        ul {
          li {
            padding-bottom: 0px;
            font-size: 16px;
            line-height: 16px;
            height: 63px;
          }
        }
      }
      &__line {
        height: 409px;
      }
    }
    &-left {
      &__title {
        margin-top: 55px;
        font-size: 19px;
        line-height: 28px;
      }
      &__sub {
        font-size: 16px;
        line-height: 18px;
        padding-top: 0;
        padding-bottom: 0;
      }
      &__line {
        margin-left: -49%;
        margin-right: -13%;
      }
    }
    &__btn {
      width: 300px;
      height: 6px;
      border-radius: 8px;
      button {
        width: 300px;
        height: 6px;
        border-radius: 8px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
.middle {
  &-line {
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    height: 1px;
    margin-top: -170px;
    position: relative;
    z-index: 3;
  }
  &-box {
    z-index: 2;
    background-color: #f6f6f6;
    padding: 100px 100px;
    border-radius: 15px;
    margin-top: -180px;
    max-width: 1320px;
    width: 100%;
    &__title {
      @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      &-1 {
        text-align: center;
      }
      &-1,
      &-3 {
        max-width: 300px;
        flex-wrap: wrap;
        button {
          width: 170px;
          height: 60px;
          border: 1px solid #c4c4c4;
          border-radius: 15px;
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
          margin-top: 40px;
          background-color: #f6f6f6;
          &:hover {
            filter: drop-shadow(0px 4px 43px rgba(0, 0, 0, 0.15));
            background-color: #fff;
            border: none;
          }
        }
      }
      &-2 {
        height: 380px;
        text-align: center;
        img {
          max-width: 106px;
          max-height: 106px;
        }
        .par {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, transparent);
          width: 330px;
          background: #373737;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          padding-top: 20px;
          padding-bottom: 60px;
          text-align: center;
          margin: 0 auto;
          height: 180px;
        }
        .btn {
          width: 380px;
          height: 70px;
          background: #ffffff;
          border-radius: 15px;
          button {
            padding-top: 15px;
            background: #ffffff;
            border: none;
            @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
          }
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
            button {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #ffffff;
              background-clip: text;
            }
          }
        }
      }
      &-3 {
        text-align: center;
      }
    }
  }
  @include mobile() {
    background-color: #f6f6f6;
    padding-bottom: 20px;
    &-box {
      margin-top: 45px;
      padding: 0px;
      &__title {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 20px;
        text-align: left;
      }
      &__bottom {
        display: none;
      }
    }
    &-line {
      display: none;
    }
  }
  @include tablet() {
    background-color: #f6f6f6;
    &-box {
      margin-top: 25px;
      padding: 10px;
      &__title {
        text-align: left;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 20px;
      }
      &__bottom {
        display: none;
      }
    }
    &-line {
      display: none;
    }
  }
  @include tablet-1024() {
    background: #f6f6f6;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0px;
      padding: 50px 0;
      &__title {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }
      &__bottom {
        display: none;
        // display: flex;
        // justify-content: center;
        // &-1,&-3 {
        //     button {
        //         width: 120px;
        //         height: 40px;
        //         border-radius: 8px;
        //         font-size: 12px;
        //         line-height: 18px;
        //     }
        // }
        &-2 {
          width: 50%;
          img {
            width: 50px;
            height: 70px;
          }
          .par {
            font-size: 13px;
            line-height: 18px;
          }
          .btn {
            width: 150px;
            height: 50px;
            background: #ffffff;
            border-radius: 8px;
            button {
              font-size: 9px;
              line-height: 12px;
              padding-top: 0;
            }
          }
        }
      }
    }
  }
  @include desktop() {
    &-line {
      margin-top: 50px;
      height: 1px;
    }
    &-box {
      margin-top: -100px;
      padding: 50px;
      &__title {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  // @include desktop-max() {
  //     &-box {
  //         &__bottom {
  //             &-2 {
  //                 .par {
  //                     height: 180px;
  //                 }
  //             }
  //         }
  //     }
  // }
}
.risk {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-top: 90px;
    margin-bottom: 30px;
  }
  &-list {
    display: flex;
    align-items: center;
    margin-top: 40px;
    &__num {
      @include fontStyle(Roboto, normal, 900, 60px, 82px, transparent);
      background: -webkit-linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-stroke: 2px transparent;
      color: #ffffff;
      align-items: center;
    }
    &__par {
      @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
      background: #f6f6f6;
      border: 1px solid rgba(255, 255, 255, 0.05);
      box-sizing: border-box;
      backdrop-filter: blur(55px);
      border-radius: 15px;
      padding: 20px;
      margin-left: 12px;
      width: 1220px;
      display: flex;
      align-items: baseline;
      height: 70px;
      span {
        @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
        padding-right: 20px;
      }
    }
  }
  @include mobile() {
    margin-top: 35px;
    margin-bottom: 3px;
    &-title {
      //change
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
      margin-top: 0;
      margin-bottom: 0px;
    }
    &-list {
      align-items: baseline;
      margin-top: 0;
      &__num {
        //change
        font-size: 14px;
        line-height: 15px;
        font-weight: 700;
        letter-spacing: 0.03em;
        padding-left: 4px;
      }
      &__par {
        display: flex;
        flex-wrap: wrap;
        //change
        font-size: 14px;
        line-height: 15px;
        font-weight: 400;
        background: transparent;
        margin-left: 5px;
        padding: 0;
        span {
          width: 100%;
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          padding-right: 10px;
        }
        // p {
        //     margin-bottom: 0px;
        //     margin-top: -10px;
        // }
      }
    }
  }
  @include tablet() {
    margin-top: 25px;
    margin-bottom: 0;
    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
      margin-top: 0;
      margin-bottom: 20px;
    }
    &-list {
      align-items: baseline;
      margin-top: 10px;
      &__num {
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        letter-spacing: 0.03em;
        padding-left: 4px;
      }
      &__par {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        background: transparent;
        margin-left: 5px;
        padding: 0;
        span {
          width: 100%;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          padding-right: 10px;
        }
      }
    }
  }
  @include tablet-1024() {
    margin-top: 40px;
    margin-bottom: 0;
    &-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
      margin-top: 0;
      margin-bottom: -20px;
    }
    &-list {
      align-items: baseline;
      &__num {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: 0.03em;
        padding-left: 4px;
      }
      &__par {
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        background: transparent;
        margin-left: 5px;
        padding: 0;
        span {
          width: 100%;
          font-size: 18px;
          line-height: 20px;
          font-weight: 500;
          padding-right: 10px;
        }
      }
    }
  }
  @include desktop() {
    &-title {
      font-size: 28px;
      line-height: 35px;
    }
    &-list {
      &__num {
        font-size: 45px;
        line-height: 55px;
      }
      &__par {
        font-size: 14px;
        line-height: 18px;
        span {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
  @include desktop-max() {
    &-list {
      margin-top: 15px;
      &__par {
        height: 80px;
      }
    }
  }
}
.slide {
  &-top,
  &-btm {
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
      text-transform: uppercase;
      padding-bottom: 35px;
      padding-top: 100px;
      span {
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
  &-btm {
    background: #efefef;
    padding-bottom: 70px;
    p {
      text-align: center;
    }
  }
}
</style>
