<template>
	<div :class="open ? 'faq open' : 'faq'" @click="$emit('toggleOpen', index)">
		<div class="question" v-html="faq.title"></div>
		<div class="answer" v-html="faq.description"></div>
	</div>
</template>

<script>

export default {
	name: 'FAQ',
	props: ['faq', 'index', 'open'],
}
</script>