<template>
  <div id="app">
    <header-main v-if="!isIframe" />
    <transition name="fade" mode="in-out">
      <router-view />
    </transition>
    <footer-main v-if="!isIframe" />
  </div>
</template>

<script>
import HeaderMain from "./components/HeaderMain.vue";
import FooterMain from "./components/FooterV.vue";

export default {
  name: "App",
  components: {
    HeaderMain,
    FooterMain,
  },
  data() {
    return {
      isIframe: false
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const iframeParam = urlParams.get('iframe');
    if (iframeParam) {
        this.isIframe = true;
      }
  },
  watch: {
		'$route': function () {
			const iframeValue = this.$route.query.iframe;
			if (iframeValue) {
        this.isIframe = true;
      }
		},
	},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.box-right {
  p {
    margin-bottom: 0 !important;
  }
}
label {
  p {
    margin-bottom: 0 !important;
  }
}
span {
  p {
    margin-bottom: 0 !important;
  }
}
button {
  p {
    margin-bottom: 0 !important;
  }
}
h2,
h4 {
  p {
    margin-bottom: 0 !important;
  }
}
p {
  p {
    margin-bottom: 0 !important;
  }
}
* {
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
:root {
  --swiper-theme-color: #be31ce !important;
  --swiper-navigation-size: 24px !important;
}
button:active,
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}
input[type="checkbox"] {
  accent-color: #4a94ff;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.container {
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  max-width: 1320px;
  width: 80%;
  @include mobile() {
    width: 90%;
  }
  &-vacancy {
    margin: 0 auto;
    max-width: 1390px;
    width: 80%;
    @include desktop() {
      width: 85%;
    }
    @include tablet-1024() {
      width: 90%;
    }
    @include tablet() {
      width: 95%;
    }
    @include mobile() {
      width: 100%;
    }
  }
}
.wrap {
  background: #efefef;
  padding-bottom: 50px;
  @include mobile() {
    padding-bottom: 15px;
  }
  @include tablet() {
    padding-bottom: 15px;
  }
  @include desktop-max() {
    padding-bottom: 100px;
  }
}
.slider-container {
  width: 80%;
  max-width: 1320px;
  margin: 0 auto;
}
.sliderMain1-title {
  width: 80%;
  max-width: 1320px;
  margin: 0 auto;
  p {
    @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-top: 100px;
    padding-bottom: 30px;
    span {
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      background: -webkit-gradient(
        linear,
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  @include mobile() {
    p {
      font-size: 14px;
      line-height: 17px;
      padding-top: 0;
      padding-bottom: 0;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  @include tablet() {
    p {
      font-size: 17px;
      line-height: 25px;
      padding-top: 0;
      padding-bottom: 0;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  @include tablet-1024() {
    p {
      padding-top: 0;
    }
  }
}
.slide {
  &-btm {
    background: #efefef;
    padding-bottom: 0px !important;
    p {
      text-align: center;
      @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      padding-top: 50px;
      padding-bottom: 30px;
      margin-bottom: 0;
    }
    @include mobile() {
      padding-top: 30px;
      padding-bottom: 30px;
      &__title {
        display: none;
      }
    }
    @include tablet() {
      padding-top: 30px;
      padding-bottom: 30px;
      &__title {
        display: none;
      }
    }
    @include tablet-1024() {
      p {
        font-size: 28px;
        line-height: 32px;
        padding-bottom: 15px;
      }
    }
    @include desktop-max() {
      p {
        padding-bottom: 20px;
      }
    }
  }
}
.router-link-active,
.router-link-exact-active {
  color: #373737;
  font-weight: 700 !important;
}
.range {
  margin-top: 100px;
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
  @include tablet-1024() {
    display: none;
  }
}
.sliderRange {
  display: none;
  @include mobile() {
    display: block;
  }
  @include tablet() {
    display: block;
  }
  @include tablet-1024() {
    display: block;
  }
}
div {
  ul {
    margin-bottom: 0;
  }
}
.modal {
  width: 40%;
  z-index: 9999;
  &-title {
    @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
    text-align: center;
    padding-top: 20px;
  }
  &-res {
    @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    padding-top: 18px;
    text-align: center;
    @include mobile() {
      font-size: 14px;
      line-height: 17px;
      #file {
        font-size: 10px;
        margin-bottom: 10px;
      }
    }
    @include tablet() {
      font-size: 14px;
      line-height: 17px;
      #file {
        font-size: 10px;
        margin-bottom: 10px;
      }
    }
  }
  &-vacancy {
    margin-top: 10px;
    width: clamp(260px, 80%, 389px);
    background: #efefef;
    border-radius: 5px;
    border: none;
    padding: 10px 0 10px 10px;
    @include fontStyle(Roboto, normal, 300, 12px, 14px, #959595);
  }
  &-inputs {
    // padding-top: 20px;
    text-align: center;
    input,
    textarea {
      margin-top: 10px;
      width: clamp(200px, 80%, 389px);
      background: #efefef;
      border-radius: 5px;
      border: none;
      padding: 10px 0 10px 10px;
      @include fontStyle(Roboto, normal, 300, 12px, 14px, #959595);
    }
    textarea {
      max-height: 100px;
      min-height: 30px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  &-btn {
    text-align: center;
    margin-top: 20px;
    button {
      width: clamp(80px, 40%, 152px);
      background: linear-gradient(
        90deg,
        #ff4466 0%,
        #be31ce 49.97%,
        #3641ff 100%
      );
      border-radius: 5px;
      border: none;
      @include fontStyle(Roboto, normal, 600, 12px, 15px, #ffffff);
      padding: 12px 3px;
    }
  }
  &-check {
    width: 356px;
    margin: 0 auto;
    &-title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #959595;
      margin-top: 15px;
      margin-bottom: 10px;
      @include mobile() {
        font-size: 12px;
        line-height: 14px;
      }
      @include tablet() {
        font-size: 12px;
        line-height: 14px;
      }
      @include tablet-1024() {
        font-size: 14px;
        line-height: 17px;
      }
    }
    .check {
      select {
        width: clamp(200px, 100%, 250px);
        font-family: Roboto;
        color: #959595;
        font-size: 15px;
        font-weight: 400;
        padding-bottom: 10px;
        padding-top: 10px;
        border: 1px solid #959595;
        border-radius: 8px;
        option {
          width: clamp(200px, 100%, 250px);
        }
      }
    }
  }
  &-rule {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    .inp-custom {
      @include mobile() {
        width: 23px;
      }
    }
    label {
      @include fontStyle(Roboto, normal, 400, 12px, 14px, #959595);
      padding-left: 10px;
      cursor: pointer;
      margin-bottom: 0;
      a {
        color: #bd02ff;
      }
    }
  }
  @include mobile() {
    &-vacancy {
      width: 260px;
      background: #ffffff;
      border-radius: 3px;
      margin-top: 0;
      height: 38px;
      padding: 5px 0px 5px 10px;
      font-size: 14px;
    }
    &-check {
      padding: 0 20px 0 30px;
      width: 100%;
    }
    &-rule {
      margin: 0 auto;
      width: 67%;
      label {
        padding-left: 3px;
      }
    }
  }
  @include tablet() {
    &-vacancy {
      width: 260px;
      background: #ffffff;
      border-radius: 3px;
      margin-top: 0;
      height: 38px;
      padding: 5px 0px 5px 10px;
      font-size: 14px;
    }
    &-check {
      padding: 0 20px 0 23px;
      width: 100%;
    }
    &-rule {
      margin: 0 auto;
      width: 67%;
      label {
        padding-left: 3px;
      }
    }
  }
  @include tablet-1024() {
    &-check {
      padding: 0 20px 0 23px;
      width: 100%;
    }
  }
}
</style>
