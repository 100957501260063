<template>
  <div class="kasko-slider">
    <swiper
      :spaceBetween="30"
      :centeredSlides="true"
      :pagination="{
        clickable: true,
      }"
      :navigation="false"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="(cm, id) in comments" :key="id">
        <div class="box">
          <div class="box-quote">
            <img src="../../assets/images/quotes.svg" alt="" />
          </div>
          <div class="box-card">
            <div class="box-par">
              <h4 class="box-par__title" v-html="cm.title"></h4>
              <div class="box-par__inp">
                <div class="box-par__par" :class="{isActive : more.includes(id)}">
                  <p class="box-par__text" v-html="cm.description"></p>
                </div>
              </div>
              <button class="box-par__more" v-if="!more.includes(id)" @click="readMore(id)"><p>{{ $t("par.reviewMore") }}</p></button>
              <button class="box-par__more" v-else @click="readLess(id)"><p>{{ $t("par.less") }}</p></button>
            </div>
            <div class="box-person">
              <div class="box-person__img">
                <img :src="cdn + cm.icon" alt="" />
              </div>
              <p class="box-person__name" v-html="cm.name"></p>
              <p class="box-person__company" v-html="cm.company_name"></p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import axios from "axios";
import { mapState } from "vuex";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  data() {
    return {
      comments: [],
      more : []
    };
  },
  methods: {
    readMore(id) {
      this.more.push(id)
    },
    readLess(id) {
      this.more = this.more.filter(item => item !== id);
    }
  },
  created() {
    axios
      .get("V1/comments")
      .then((response) => {
        this.comments = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
.isActive {
  max-height:  none !important;
}
.box {
  width: 90%;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  padding: 40px 97px 63px 72px;
  &-quote {
    text-align: start;
  }
  &-card {
    display: flex;
    justify-content: space-between;
    // align-items: center
  }
  &-par {
    &__title {
      @include fontStyle(Roboto, normal, 400, 24px, 33px, #2e363e);
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: start;
    }
    &__text {
      @include fontStyle(Roboto, normal, 300, 18px, 25px, #2e363e);
      text-align: left;
    }
    &__more {
      @include fontStyle(Roboto, normal, 400, 14px, 21px, #2e363e);
      background: linear-gradient(
        90deg,
        #db3c59 0%,
        #b94cc6 41.97%,
        #4d54cc 36%
      );
      border: none;
      background-color: #ffffff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      cursor: pointer;
      text-align: left;
      p {
        border-bottom: 1px solid;
        border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47% 0%;
      }
    }
    &__inp {
      max-width: 300px;
      width: 100%;
    }
    &__par {
      max-height: 85px;
      overflow: hidden;
      // position: relative;
      &__title {
        font-size: 16px;
        line-height: 13px;
      }
      &__sub {
        font-size: 10px;
        line-height: 12px;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
        opacity: 1;
        transition: 3000ms;
      }
    }
  }
  &-person {
    &__img {
      display: flex;
      width: 248px;
      height: 248px;
      background: #c4c4c4;
      border: 1px solid #f2f6fa;
      border-radius: 1000px;
      overflow: hidden;
      img {
        max-width: 100%;
        width: 248px;
        background-size: cover;
        border-top-left-radius: 50% 50%;
        border-top-right-radius: 50% 50%;
        // border-bottom-right-radius: 50% 50%;
        border-bottom-left-radius: 50% 50%;
        object-fit: contain;
        padding: 5%;
      }
    }
    &__name {
      @include fontStyle(Roboto, normal, 500, 22px, 31px, #373737);
      text-align: center;
      padding-top: 20px;
      padding-bottom: 2px;
      margin-bottom: 0;
    }
    &__company {
      @include fontStyle(Roboto, normal, 500, 16px, 27px, #373737);
      text-align: center;
      margin-bottom: 0;
    }
  }
}
.mySwiper {
  @include mobile() {
    .box {
      padding: 0 13px 13px 13px;
      margin-bottom: 30px;
      &-card {
        display: flex;
        align-items: center;
      }
      &-quote {
        img {
          width: 19px;
        }
      }
      &-par {
        &__title {
          font-size: 14px;
          line-height: 15px;
          padding-top: 7px;
          padding-bottom: 10px;
          margin-bottom: 0 !important;
          font-weight: 500;
        }
        &__text {
          width: 220px;
          //changes
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 0;
        }
        &__more {
          font-size: 12px;
          line-height: 9px;
          text-align: left;
        }
      }
      &-person {
        &__img {
          display: flex;
          width: 68px;
          height: 68px;
          margin: 0 auto;
          img {
            max-width: 100%;
            width: 68px;
            background-size: cover;
            border-top-left-radius: 50% 50%;
            border-top-right-radius: 50% 50%;
            // border-bottom-right-radius: 50% 50%;
            border-bottom-left-radius: 50% 50%;
            padding: 5%;
          }
        }
        &__name {
          font-size: 12px;
          line-height: 17px;
          padding-top: 0px;
        }
        &__company {
          font-size: 10px;
          line-height: 17px;
        }
      }
    }
  }
  @include tablet() {
    .box {
      padding: 20px;
      margin-bottom: 40px;
      &-card {
        display: flex;
        // flex-wrap: wrap;
      }
      &-quote {
        img {
          width: 20px;
        }
      }
      &-par {
        &__title {
          font-size: 15px;
          line-height: 18px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &__text {
          font-size: 11px;
          line-height: 14px;
          margin-bottom: 0;
        }
        &__more {
          font-size: 12px;
          line-height: 9px;
        }
      }
      &-person {
        margin: 0 auto;
        &__img {
          display: flex;
          width: 90px;
          height: 90px;
          img {
            max-width: 100%;
            width: 90px;
            background-size: cover;
            border-top-left-radius: 50% 50%;
            border-top-right-radius: 50% 50%;
            // border-bottom-right-radius: 50% 50%;
            border-bottom-left-radius: 50% 50%;
            padding: 5%;
          }
        }
        &__name {
          font-size: 12px;
          line-height: 15px;
          padding-top: 10px;
        }
        &__company {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
  @include tablet-1024() {
    .box {
      padding: 20px;
      &-card {
        display: flex;
        justify-content: space-between;
      }
      &-quote {
        img {
          width: 30px;
        }
      }
      &-par {
        &__title {
          font-size: 18px;
          line-height: 22px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &__text {
          font-size: 12px;
          line-height: 15px;
        }
        &__more {
          font-size: 12px;
          line-height: 9px;
        }
      }
      &-person {
        &__img {
          width: 150px;
          height: 150px;
          display: flex;
          img {
            max-width: 100%;
            width: 150px;
            background-size: cover;
            border-top-left-radius: 50% 50%;
            border-top-right-radius: 50% 50%;
            border-bottom-left-radius: 50% 50%;
            padding: 5%;
          }
        }
        &__name {
          font-size: 16px;
          line-height: 20px;
          padding-top: 10px;
        }
        &__company {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  // text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>
