<template>
    <div>
        <head-bottom/>
        <div class="owner">
            <div class="container">
                <div class="owner-cards">
                    <div class="owner-cards-left">
                        <!-- <p class="owner-cards-left__title">{{owner[3]}}</p> -->
                        <p class="owner-cards-left__title" v-html="owner[3].sub_cats[0].title"></p>
                        <p class="owner-cards-left__sub" v-html="owner[3].sub_cats[0].desc"></p>
                        <div class="owner-cards-left__btn m_none">
                            <polis-button/>
                            <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
                        </div>
                    </div>
                    <div class="owner-cards-right">
                        <img src="../assets/images/res4.png" alt="">
                        <div class="owner-cards-left__btn-mob d_none">
                            <polis-button/>
                            <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="object">
                <p class="object-title" v-html="owner[3].sub_cats[1].title"></p>
                <div class="par">
                    <span>01.</span>
                    <!-- <span v-if="k<10">0{{ k+1 }}.</span>
                    <span v-else>{{ k+1 }}.</span> -->
                    <p class="object-par" v-html="owner[3].sub_cats[1].desc"></p>
                </div>
            </div>
            <div class="risk">
                <p class="risk-title" v-html="owner[3].sub_cats[2].title"></p>
                <div class="par" v-for="(own,k) in owner[3].sub_cats[2].cats" :key="own">
                    <span v-if="k<10">0{{ k+1 }}.</span>
                    <span v-else>{{ k+1 }}.</span>
                    <p class="object-par" v-html="own.mini_sub_cat"></p>
                </div>
            </div>
        </div>
        <!-- <timeBtn/> -->
        <div class="container">
            <div class="time">
                <p class="time-title" v-html="timeTitle"></p>
                <p class="time-sub" v-html="timeDesc"></p>
                <div class="time-btn">
                    <time-btn/>
                    <!-- <a href="https://kupipolis.kz/ru/paypartial"><button>Оформить полис</button></a> -->
                </div>
            </div>
        </div>
        <accordion/>
        <question :p_name="p_name"/>
        <SliderMain1 class="m_none"/>
        <slider-main-mobile-2 class="d_none"/>
    </div>
</template>

<script>
import Question from '../components/Question.vue'
import HeadBottom from '../components/HeadBottom.vue'
import SliderMain1 from '../components/SliderMain1-1.vue'
import accordion from '../components/accordion.vue'
import timeBtn from '../components/timeBtn.vue'
import PolisButton from '@/components/PolisButton.vue'
import SliderMainMobile2 from '@/components/SliderMainMobile2.vue'
import axios from 'axios'
import {mapState} from 'vuex'
export default {
    components: {HeadBottom, Question, SliderMain1,accordion,PolisButton,SliderMainMobile2,timeBtn},
        data() {
        return {
            p_name: 'Страхование ответственности работодателя',
            owner: [],
            timeTitle: [],
            timeDesc: [],
        }
    },
    created() {
        axios.get('V1/business/liabity')
        .then(response => {
            this.owner = response.data.data
            this.timeTitle = response.data.data[3].sub_cats[3].title
            this.timeDesc = response.data.data[3].sub_cats[3].desc
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/timeBtn.scss";
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    .container {
        width: 90%;
    }
    .time {
        &-sub {
            padding-top: 15px;
            padding-bottom: 10px;
        }
    }
    .par {
        display: flex;
        align-items: baseline;
    }
    p {
        margin-bottom: 0 !important;
    }
    .owner {
        background: #EFEFEF;
        padding-top: 70px;
        padding-bottom: 48px;
        &-cards {
            display: flex;
            // justify-content: space-between;
            &-left {
                &__title {
                    @include fontStyle(Roboto, normal, 500, 36px, 43px, transparent);
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                &__sub {
                    @include fontStyle(Roboto, normal, 400, 24px, 36px, #373737);
                    text-transform: uppercase;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
                &__btn, &__btn-mob {
                    button {
                        background: #FFFFFF;
                        border-radius: 15px;
                        width: 200px;
                        height: 70px;
                        border: none;
                        color: #373737;
                        @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
                        &:hover {
                            background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
                            color: #FFFFFF;
                        }
                    }
                }
            }
            &-right {
                margin-left: 15%;
                img {
                    width: 100%;
                    min-width: 250px;
                }
            }
        }
        @include mobile() {
            padding-top: 20px;
            padding-bottom: 20px;
            &-cards {
                flex-wrap: wrap;
                &-left {
                    // margin: 0 auto;
                    &__title {
                        width: 100%;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: left;
                        padding-bottom: 15px;
                    }
                    &__sub {
                        font-size: 12px;
                        line-height: 14px;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    &__btn-mob {
                        text-align: center;
                        button {
                            border-radius: 3px;
                            width: 96px;
                            height: 28px;
                            font-size: 9px;
                        }
                    }
                }
                &-right {
                    width: 100%;
                    margin-left: 0;
                    img {
                        min-width: 150px;
                        margin-top: 10px;
                        width: 40%;
                        margin-left: 23%;
                    }
                }
            }
        }
        @include tablet() {
            &-cards {
                flex-wrap: wrap;
                &-left {
                    margin: 0 auto;
                    &__title {
                        text-align: center;
                        width: 100%;
                        font-size: 21px;
                        line-height: 24px;
                    }
                    &__sub {
                        font-size: 16px;
                        line-height: 22px;
                    }
                    &__btn-mob {
                        text-align: center;
                        button {
                            border-radius: 3px;
                            width: 116px;
                            height: 38px;
                            font-size: 10px;
                        }
                    }
                }
                &-right {
                    width: 100%;
                    margin-left: 0;
                    img {
                        margin-top: 10px;
                        width: 50%;
                        min-width: 200px;
                        margin-left: 20%;
                    }
                }
            }
        }
        @include tablet-1024() {
            &-cards {
                &-right {
                    img {
                        min-width: 200px;
                        width: 100%;
                    }
                }
                &-left {
                    &__title {
                        font-size: 20px;
                        line-height: 25px;
                    }
                    &__sub {
                        font-size: 14px;
                        line-height: 20px;
                    }
                    &__btn {
                        button {
                            width: 170px;
                            height: 50px;
                            border-radius: 7px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .object {
        padding-top: 85px;
        padding-bottom: 55px;
        @include mobile() {
            padding-top: 25px;
            padding-bottom: 20px;
        }
        @include tablet() {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        @include tablet-1024() {
            padding-top: 40px;
            padding-bottom: 25px;
        }
    }
    .object,.risk {
        &-title {
            @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
            text-transform: uppercase;
            padding-bottom: 10px;
        }
        &-par {
            @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
            span {
                @include fontStyle(Roboto, normal, 900, 24px, 33px, #FFFFFF);
                letter-spacing: 0.03em;
                background: -webkit-linear-gradient(180deg, #1B27FF 39%, #C600DD 44%, #FF143F 69%);
                -webkit-background-clip: text;
                -webkit-text-stroke: 2px transparent;
                color: #FFFFFF;
                padding-right: 10px;
                padding-left: 2px;
            }
        }
        span {
            @include fontStyle(Roboto, normal, 900, 24px, 33px, #FFFFFF);
            letter-spacing: 0.03em;
            background: -webkit-linear-gradient(180deg, #1B27FF 39%, #C600DD 44%, #FF143F 69%);
            -webkit-background-clip: text;
            -webkit-text-stroke: 2px transparent;
            color: #FFFFFF;
            padding-right: 10px;
            padding-left: 2px;
        }
        @include mobile() {
            &-title {
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 0.03em;
                padding-bottom: 0;
            }
            &-par {
                font-size: 12px;
                line-height: 14px;
                // margin-top: -10px;
                padding-bottom: 10px;
                span {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 700;
                    -webkit-text-stroke: 1px transparent;
                }
            }
            span {
                font-size: 10px;
                line-height: 12px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }
        @include tablet() {
            &-title {
                font-size: 16px;
                text-transform: uppercase;
                letter-spacing: 0.03em;
            }
            &-par {
                font-size: 12px;
                line-height: 14px;
                // margin-top: -10px;
                padding-bottom: 10px;
                span {
                    font-size: 14px;
                    line-height: 12px;
                    font-weight: 700;
                    -webkit-text-stroke: 1px transparent;
                }
            }
            span {
                font-size: 14px;
                line-height: 12px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }
        @include tablet-1024() {
            &-title {
                font-size: 20px;
                text-transform: uppercase;
                letter-spacing: 0.03em;
            }
            &-par {
                font-size: 14px;
                line-height: 14px;
                span {
                    font-size: 14px;
                    line-height: 12px;
                    font-weight: 700;
                    -webkit-text-stroke: 1px transparent;
                }
            }
            span {
                font-size: 14px;
                line-height: 12px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }
        @include desktop() {
            &-title {
                padding-bottom: 10px;
            }
        }
    }
</style>