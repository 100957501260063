<template>
  <div class="title">
    <h4>{{ $t("modal.more") }}</h4>
    <p>{{ $t("modal.present") }}</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
.title {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #373737;
  padding-top: 20px;
  padding-bottom: 10px;
  @include mobile() {
    font-size: 14px;
    line-height: 17px;
  }
  @include tablet() {
    font-size: 14px;
    line-height: 16px;
  }
  @include tablet-1024() {
    padding-bottom: 10px;
    font-size: 17px;
    line-height: 20px;
  }
}
p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #959595;
  @include mobile() {
    font-size: 12px;
    line-height: 14px;
  }
  @include tablet() {
    font-size: 12px;
    line-height: 14px;
  }
  @include tablet-1024() {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
