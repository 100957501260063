<template>
    <div class="animate__animated animate__fadeIn" style="animation-duration: 3s;">
        <div class="about-back">
            <div class="container">
                <div class="about-back__title" v-html="about"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            about: [],
        }
    },
    created() { 
        axios.get('V1/abouts')
        .then(response => {
            this.about = response.data.data[0].text
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 769px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1560px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1561px) and (max-width: 2560px) {
            @content;
        }
    }
    .about-back {
        background: url(../assets/images/aboutBack.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: -275px;
        &__title {
            width: 75%;
            @include fontStyle(Roboto, normal, 500, 42px, 58px, transparent);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background: linear-gradient(90deg, #FF143F -14%, #C600DD 40.97%, #1B27FF 50%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            padding-top: 76px;
            padding-bottom: 80px;
        }
        @include mobile() {
            background: url(../assets/images/about-little.png);
            background-position: right;
            background-size: cover;
            background-repeat: no-repeat;
            &__title {
                width: 60%;
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
                padding-top: 60px;
                padding-bottom: 40px;
                background: #373737;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        @include tablet() {
            background: url(../assets/images/about-little.png);
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;
            &__title {
                width: 66%;
                font-size: 16px;
                line-height: 19px;
                padding-top: 70px;
                padding-bottom: 70px;
                font-weight: 600;
                background: #373737;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 0 !important;
            }
        }
        @include tablet-1024() {
            background-position-x: 0;
            background-size: cover;
            &__title {
                width: 42%;
                font-size: 15px;
                line-height: 20px;
                padding-top: 20px;
                padding-bottom: 30px;
                background: #373737;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        @include desktop() {
            background-position-x: 0;
            &__title {
                width: 46%;
                padding-top: 50px;
                padding-bottom: 50px;
                font-size: 22px;
                line-height: 28px;
            }
            &-par p {
                padding-top: 20px;
            }
        }
        @include desktop-max() {
            background-position-x: 0px;
        }
    }
</style>