<template>
  <div class="wrapper-back m_none">
    <swiper
      :slidesPerView="2"
      :spaceBetween="30"
      :loop="true"
      :centeredSlides="true"
      :autoplay="{
        delay: 2500,
        transition: 2500,
        // disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }"
      :pagination="false"
      :navigation="navigation2"
      :modules="[Thumbs]"
      :thumbs="{ swiper: thumbsSwiper }"
      class="mySwiper"
    >
      <!-- :autoplay="{
        delay: 6000,
        transition: 2500,
        disableOnInteraction: false,
        // pauseOnMouseEnter: true,
      }" -->
      <swiper-slide v-for="cm in comments" :key="cm">
        <div class="box">
          <div class="box-quote">
            <img src="../assets/images/quotes.svg" alt="" />
          </div>
          <div class="box-card">
            <div class="box-par">
              <h4 class="box-par__title" v-html="cm.title"></h4>
              <p class="box-par__text" v-html="cm.description"></p>
            </div>
            <div class="box-person">
              <div class="box-person__img">
                <v-lazy-image :src="cdn + cm.icon" alt="" />
              </div>
              <p class="box-person__name" v-html="cm.name"></p>
              <p class="box-person__company" v-html="cm.company_name"></p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script scoped>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Pagination } from "swiper";
import axios from "axios";
import { mapState } from "vuex";
import VLazyImage from "v-lazy-image";

SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    VLazyImage
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
      modules: [Autoplay, Navigation],
    };
  },
  data() {
    return {
      showLoader: false,
      items: [],
      page: 2,
      totalPages: 5,
      totalRecords: 10,
      recordsPerPage: 10,
      activeBtn: "btn1",
      comments: [],
      errors: [],
      navigation2: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  },
  created() {
    axios
      .get("V1/comments")
      .then((response) => {
        this.comments = response.data.data;
        // console.log(response)
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
.mySwiper__img {
  margin-left: 35px;
  margin-right: 35px;
}
@media (max-width: 576px) {
  .mySwiper__img {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1600px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1600px) {
    @content;
  }
}
.swiper {
  &-slide {
    &-next,
    &-prev {
      width: 25%;
      .box {
        height: 300px;
        margin-top: 12%;
        padding: 15px 25px;
        width: 60%;
        &-card {
          width: 106%;
        }
        &-person {
          margin-right: 20px;
          &__img {
            width: 132px;
            height: 132px;
          }
          &__name {
            font-size: 16px;
            line-height: 20px;
          }
        }
        &-quote {
          img {
            width: 32px;
            height: 23px;
          }
        }
        &-par {
          &__title {
            font-size: 16px;
            line-height: 23px;
            padding: 10px 0;
          }
          &__text {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    &-active {
      .box {
        width: 109%;
        margin-left: -4%;
      }
    }
  }
  @include desktop() {
    height: 365px !important;
    &-slide {
      &-next,
      &-prev {
        .box {
          height: 213px;
          margin-top: 10%;
          padding: 15px 25px !important;
          width: 47%;
          &-card {
            width: 106%;
          }
          &-person {
            margin-right: 20px;
            &__img {
              width: 100px !important;
              height: 100px !important;
            }
            &__name {
              font-size: 10px !important;
              line-height: 12px !important;
            }
          }
          &-quote {
            img {
              width: 32px;
              height: 23px;
            }
          }
          &-par {
            &__title {
              font-size: 12px !important;
              line-height: 14px !important;
              padding: 10px 0;
            }
            &__text {
              font-size: 7px !important;
              line-height: 10px !important;
            }
          }
        }
      }
      &-active {
        .box {
          width: 122%;
          margin-left: -11%;
        }
      }
    }
  }
  @include tablet-1024() {
    height: 370px !important;
    &-slide {
      &-next,
      &-prev {
        .box {
          height: 160px;
          margin-top: 10%;
          padding: 15px 25px !important;
          width: 47%;
          &-card {
            width: 106%;
          }
          &-person {
            margin-right: 20px;
            &__img {
              width: 70px !important;
              height: 70px !important;
            }
            &__name {
              font-size: 10px !important;
              line-height: 12px !important;
            }
          }
          &-quote {
            img {
              width: 32px;
              height: 23px;
            }
          }
          &-par {
            &__title {
              font-size: 12px !important;
              line-height: 14px !important;
              padding: 10px 0;
            }
            &__text {
              font-size: 7px !important;
              line-height: 10px !important;
            }
          }
        }
      }
      &-active {
        .box {
          width: 122%;
          margin-left: -11%;
        }
      }
    }
  }
}
.container1440 {
  // max-width: 1440px;
  width: 80%;
  margin: 0 auto;
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
}
.swiper {
  &-button {
    &-prev {
      left: 10%;
      background: url("@/assets/images/left.png");
    }
    &-next {
      right: 10%;
      background: url("@/assets/images/right.png");
    }
  }
}
.box {
  width: 70%;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  padding: 40px 97px 63px 72px;
  overflow: hidden;
  &-card {
    display: flex;
    justify-content: space-between;
  }
  &-par {
    &__title {
      @include fontStyle(Roboto, normal, 400, 24px, 33px, #2e363e);
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &__text {
      @include fontStyle(Roboto, normal, 300, 18px, 25px, #2e363e);
      width: 90%;
    }
    &__more {
      @include fontStyle(Roboto, normal, 300, 14px, 21px, #2e363e);
      background: linear-gradient(
        90deg,
        #db3c59 0%,
        #b94cc6 41.97%,
        #4d54cc 36%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      cursor: pointer;
    }
  }
  &-person {
    &__img {
      display: flex;
      width: 248px;
      height: 248px;
      background: #c4c4c4;
      border: 1px solid #f2f6fa;
      border-radius: 1000px;
      overflow: hidden;
      img {
        max-width: 100%;
        width: 248px;
        background-size: cover;
        border-top-left-radius: 50% 50%;
        border-top-right-radius: 50% 50%;
        // border-bottom-right-radius: 50% 50%;
        padding: 5%;
        border-bottom-left-radius: 50% 50%;
        object-fit: contain;
      }
    }
    &__name {
      @include fontStyle(Roboto, normal, 400, 22px, 31px, #373737);
      text-align: center;
      padding-top: 20px;
      padding-bottom: 6px;
      margin-bottom: 0;
    }
    &__company {
      @include fontStyle(Roboto, normal, 300, 16px, 27px, #373737);
      text-align: center;
      margin-bottom: 0;
    }
  }
  @include desktop-max() {
    height: 535px;
  }
}
.mySwiper {
  @include mobile() {
    .box {
      padding: 20px;
      &-card {
        display: flex;
        flex-wrap: wrap;
      }
      &-quote {
        img {
          width: 20px;
        }
      }
      &-par {
        &__title {
          font-size: 13px;
          line-height: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &__text {
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 0;
        }
        &__more {
          font-size: 8px;
          line-height: 10px;
        }
      }
      &-person {
        margin: 0 auto;
        &__img {
          width: 90px;
          height: 90px;
          margin-top: 10px;
        }
        &__name {
          font-size: 13px;
          line-height: 15px;
          padding-top: 10px;
        }
        &__company {
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
  }
  @include tablet() {
    .box {
      padding: 20px;
      &-card {
        display: flex;
        flex-wrap: wrap;
      }
      &-quote {
        img {
          width: 20px;
        }
      }
      &-par {
        &__title {
          font-size: 13px;
          line-height: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &__text {
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 0;
        }
        &__more {
          font-size: 8px;
          line-height: 10px;
        }
      }
      &-person {
        margin: 0 auto;
        &__img {
          width: 90px;
          height: 90px;
          margin-top: 10px;
        }
        &__name {
          font-size: 13px;
          line-height: 15px;
          padding-top: 10px;
        }
        &__company {
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
  }
  @include tablet-1024() {
    .box {
      padding: 20px;
      &-card {
        display: flex;
        justify-content: space-between;
      }
      &-quote {
        img {
          width: 30px;
        }
      }
      &-par {
        &__title {
          font-size: 18px;
          line-height: 22px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &__text {
          font-size: 12px;
          line-height: 15px;
        }
        &__more {
          font-size: 9px;
          line-height: 12px;
        }
      }
      &-person {
        &__img {
          width: 150px;
          height: 150px;
        }
        &__name {
          font-size: 16px;
          line-height: 20px;
          padding-top: 10px;
        }
        &__company {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
  @include desktop() {
    .box {
      padding: 30px 37px 33px 32px;
      &-card {
        display: flex;
        justify-content: space-between;
      }
      &-quote {
        img {
          width: 30px;
        }
      }
      &-par {
        &__title {
          font-size: 20px;
          line-height: 24px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &__text {
          font-size: 14px;
          line-height: 18px;
        }
        &__more {
          font-size: 10px;
          line-height: 12px;
        }
      }
      &-person {
        &__img {
          width: 200px;
          height: 200px;
        }
        &__name {
          font-size: 18px;
          line-height: 22px;
          padding-top: 10px;
        }
        &__company {
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }
}
</style>
