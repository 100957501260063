<template>
  <about-us-header />
  <about-back />
  <about-buttons />
  <div class="requisite">
    <div class="container">
      <div class="req">
        <div class="requisite-left">
          <p class="requisite-title" v-html="req[0].title"></p>
          <!-- <div class="requisite-client"><p>Заказчик: </p></div> -->
          <div class="requisite-address" v-html="req[0].description"></div>
        </div>
        <div class="requisite-right">
          <img :src="cdn + req[0].icon" alt="" />
          <div class="requisite-right__btn m_none">
            <a :href="cdn + req[0].icon" download
              ><button>{{ $t("buttons.down") }}</button></a
            >
          </div>
          <div class="requisite-right__btn d_none">
            <a class="mob-link" :href="cdn + req[0].icon" download
              >{{ $t("buttons.down") }}
              <img
                class="download-img"
                src="../assets/images/download.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "./aboutUsHeader.vue";
import AboutBack from "./AboutBack.vue";
import aboutButtons from "./aboutButtons.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { aboutUsHeader, AboutBack, aboutButtons },
  data() {
    return {
      req: null,
    };
  },
  created() {
    axios
      .get("V1/requisites")
      .then((response) => {
        this.req = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1620px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.req {
  display: flex;
  justify-content: space-between;
  @include mobile() {
    flex-wrap: wrap;
  }
  @include tablet() {
    flex-wrap: wrap;
  }
}
.requisite {
  background: #efefef;
  padding: 85px 0;
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-client,
  &-address {
    padding-top: 35px;
    @include fontStyle(Roboto, normal, 200, 24px, 29px, #373737);
    p {
      font-weight: 400;
    }
  }
  &-about {
    padding-top: 35px;
    @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
  }
  &-right {
    padding-top: 80px;
    &__btn {
      margin-top: 50px;
      .mob-link {
        display: flex;
        align-items: center;
        @include fontStyle(Roboto, normal, 300, 14px, 14px, #373737);
        img {
          width: 19px;
          margin-left: 9px;
        }
      }
      button {
        padding: 23px 35px;
        @include fontStyle(Roboto, normal, 700, 18px, 21px, #8948b1);
        background-color: #fff;
        border-radius: 15px;
        border: none;
        &:hover {
          background: linear-gradient(
            90deg,
            #ff4466 0%,
            #be31ce 49.97%,
            #3641ff 100%
          );
          color: #fff;
        }
      }
    }
  }
  @include mobile() {
    padding: 20px 0 35px 0;
    &-title {
      font-size: 14px;
      line-height: 16px;
    }
    &-client,
    &-address,
    &-about {
      font-size: 14px;
      line-height: 16px;
      padding-top: 0px;
      font-weight: 400;
      p {
        margin-bottom: 0;
      }
    }
    &-right {
      padding-top: 5px;
      img {
        width: 150px;
      }
      &__btn {
        margin-top: 20px;
        button {
          border-radius: 7px;
          padding: 10px 15px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
  @include tablet() {
    padding: 20px 0 35px 0;
    &-title {
      font-size: 14px;
      line-height: 16px;
    }
    &-client,
    &-address,
    &-about {
      font-size: 14px;
      line-height: 16px;
      padding-top: 0px;
      font-weight: 400;
      p {
        margin-bottom: 0;
      }
    }
    &-right {
      padding-top: 5px;
      img {
        width: 150px;
      }
      &__btn {
        margin-top: 20px;
        button {
          border-radius: 7px;
          padding: 10px 15px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
  @include tablet-1024() {
    padding: 35px 0;
    &-title {
      font-size: 19px;
      line-height: 24px;
    }
    &-client,
    &-address,
    &-about {
      font-size: 14px;
      line-height: 18px;
      padding-top: 20px;
      p {
        margin-bottom: 0;
      }
    }
    &-right {
      padding-top: 30px;
      img {
        width: 150px;
      }
      &__btn {
        margin-top: 20px;
        button {
          border-radius: 7px;
          padding: 10px 15px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
  @include desktop() {
    &-right {
      &__btn {
        button {
          font-size: 16px;
          line-height: 18px;
        }
      }
      img {
        width: 300px;
      }
    }
  }
  @include desktop-max() {
    &-right {
      img {
        width: 363px;
      }
    }
  }
}
</style>
