<template>
  <div class="container">
    <div class="video" v-for="v in visibleCompanies" :key="v.id">
      <iframe
        width="560"
        height="315"
        :src="v.video_url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <!-- <div :class="{activeButton: i === IsShow}" class="video display_none" v-for="v in video.slice(1,20)" :key="v">
            <iframe width="560" height="315" :src="v.video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div> -->
    <div
      class=""
      @click="companiesVisible += step"
      v-if="companiesVisible < video.length"
    >
      <span class="video-more"
        >{{ $t("buttons.show") }}<img src="../images/arrow-bottom.png" alt=""
      /></span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      video: [],
      IsShow: false,
      companiesVisible: 1,
      step: 1,
    };
  },
  created() {
    axios
      .get("V1/company-videos")
      .then((response) => {
        this.video = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    IsShowButton(i) {
      this.IsShow = i;
    },
  },
  computed: {
    ...mapState(["cdn"]),
    visibleCompanies() {
      return this.video.slice(0, this.companiesVisible);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
.display_none {
  display: none;
}
.activeButton {
  display: block;
  transition: all 2s;
}
.video {
  iframe {
    width: 100%;
    height: 150px;
  }
  &-more {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include fontStyle(Roboto, normal, 300, 12px, 14px, #373737);
    img {
      margin-left: 6px;
    }
  }
  @include tablet() {
    iframe {
      height: 180px;
    }
  }
  @include tablet-1024() {
    iframe {
      height: 200px;
    }
  }
}
</style>
