<template>
  <div>
    <div class="service">
      <div class="container">
        <h2 class="service-title" v-html="bigT"></h2>
        <!-- <h2 class="service-title">Быстрые онлайн сервисы на нашем втором сайте- <a href="https://kupipolis.kz/ru"><span>kupipolis.kz</span></a></h2> -->
        <h2 class="service-title-mobile" v-html="mobileT"></h2>
        <div class="service-item">
          <a :href="s.link" class="service-cards" v-for="s in service" :key="s">
            <v-lazy-image :src="cdn + s.icon" alt="" />
            <p class="service-cards-par" v-html="s.title"></p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import VLazyImage from "v-lazy-image";
export default {
  components: {VLazyImage},
  data() {
    return {
      service: [],
    };
  },
  created() {
    axios
      .get("V1/online-services")
      .then((response) => {
        this.service = response.data.data;
        this.bigT = response.data.big_title;
        this.mobileT = response.data.mobile_title;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 426px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 426px) and (max-width: 769px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  @include mobile() {
    width: 90%;
  }
  @include tablet() {
    width: 90%;
  }
  @include tablet-1024() {
    width: 80%;
  }
  @include desktop() {
    width: 80%;
  }
}
.service {
  margin-top: 114px;
  margin-bottom: 114px;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 49px, #373737);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 50px;
    &-mobile {
      display: none;
    }
    a {
      text-decoration: none;
    }
    span {
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // border-bottom: 1px solid #000;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 2px solid;
      -o-border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47%
        0%;
      border-image: linear-gradient(to right, #ff143f, #c600dd, #1b27ff) 47% 0%;
    }
  }
  &-item {
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
  }
  &-cards {
    max-width: 300px;
    width: 100%;
    height: 324px;
    background: #efefef;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(35px);
    border-radius: 15px;
    margin-right: 15px;
    img {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: 260px;
      height: 260px;
      padding-top: 10px;
    }
    &-par,
    &-par-mobile {
      @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
      text-align: center;
      &:hover {
        color: #8948b1;
      }
    }
    &:hover {
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.2);
    }
  }
  @media (max-width: 374px) {
    &-cards {
      max-width: 155px !important;
    }
  }
  @include mobile() {
    background-color: #efefef;
    margin-top: 0;
    margin-bottom: 40px;
    &-title {
      display: none;
      &-mobile {
        // width: 100%;
        // margin: 0 auto;
        padding-bottom: 5px;
        display: block;
        @include fontStyle(Roboto, normal, 500, 14px, 14px, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 9.97%,
          #1b27ff 30%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-top: 40px;
      }
    }
    &-cards {
      // min-width: 136px;
      // max-width: 165px;
      width: 48%;
      min-height: 136px;
      max-height: 155px;
      margin-top: 5px;
      margin-right: 0px;
      margin-bottom: 10px;
      background: #ffffff;
      border-radius: 6px;
      &:last-child {
        img {
          margin-left: 20px;
        }
      }
      img {
        width: 94px;
        height: 94px;
        // padding-left: 15px;
        // padding-top: 15px;
        margin: 0 auto;
      }
      &-par,
      &-par-mobile {
        //changes
        font-size: 12px;
        line-height: 14px;
        color: #373737;
        font-weight: 500;
        text-transform: uppercase;
        padding-top: 25px;
      }
    }
    &-item {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      flex-wrap: wrap;
      //   justify-content: center;
    }
  }
  @include tablet() {
    background-color: #efefef;
    margin-top: 0;
    margin-bottom: 40px;
    &-title {
      display: none;
      &-mobile {
        // width: 100%;
        // margin: 0 auto;
        padding-bottom: 5px;
        display: block;
        @include fontStyle(Roboto, normal, 500, 14px, 14px, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 9.97%,
          #1b27ff 30%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-top: 40px;
      }
    }
    &-cards {
      // min-width: 136px;
      // max-width: 165px;
      width: 48%;
      min-height: 136px;
      max-height: 155px;
      margin-top: 5px;
      margin-right: 0px;
      margin-bottom: 10px;
      background: #ffffff;
      border-radius: 6px;
      &:last-child {
        img {
          //   margin-left: 20px;
        }
      }
      img {
        width: 94px;
        height: 94px;
        // padding-left: 15px;
        // padding-top: 15px;
        margin: 0 auto;
      }
      &-par,
      &-par-mobile {
        //changes
        font-size: 12px;
        line-height: 14px;
        color: #373737;
        font-weight: 500;
        text-transform: uppercase;
        padding-top: 25px;
      }
    }
    &-item {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      flex-wrap: wrap;
      //   justify-content: center;
    }
  }
  @include tablet-1024() {
    &-title {
      font-size: 30px;
      line-height: 32px;
    }
    &-item {
      flex-wrap: wrap;
    }
    &-cards {
      margin-top: 10px;
      padding-top: 10px;
      min-width: 220px;
      width: 100%;
      height: 200px;
      img {
        width: 170px;
        height: 150px;
        margin: auto;
      }
      &-par {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  @include desktop() {
    &-cards {
      margin-top: 10px;
      width: 200px;
      height: 220px;
      img {
        width: 170px;
        height: 150px;
        margin: auto;
      }
      &-par {
        font-size: 20px;
        margin-top: 15px;
      }
    }
  }
}
</style>
