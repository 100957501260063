<template>
  <div class="accc">
    <div class="container">
      <h2 class="accordion-title">{{ $t("buttons.ans") }}</h2>
    </div>
    <div class="faqs">
      <FAQ
        v-for="(faq, i) in faqs"
        :faq="faq"
        :index="i"
        :key="i"
        :open="faq.open"
        @toggleOpen="toggleOpen"
      />
    </div>
  </div>
</template>

<script>
import FAQ from "./FAQ";
import axios from "axios";
export default {
  name: "accordion",
  components: {
    FAQ,
  },
  data() {
    return {
      open: false,
      question: [],
      faqs: [],
    };
  },
  created() {
    axios
      .get("V1/question-answer")
      .then((response) => {
        this.faqs = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    toggleOpen: function (index) {
      this.faqs = this.faqs.map((faq, i) => {
        if (index === i) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      });
    },
  },
};
</script>

<style lang="scss">
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.faqs {
  overflow: hidden;
}
.container {
  padding-left: 0;
  padding-right: 0;
}
.accc {
  margin-top: 80px;
  margin-bottom: 80px;
  @include mobile() {
    background: #efefef;
    margin: 0px 0;
    padding: 30px 0 !important;
  }
  @include tablet() {
    background: #efefef;
    margin: 0;
    padding: 30px 0 !important;
  }
  @include tablet-1024() {
    margin: 35px 0;
  }
}
.accordion-title {
  width: 100%;
  @include fontStyle(Roboto, normal, 500, 36px, 38px, #373737);
  text-align: left;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 30px;
  @include mobile() {
    font-size: 14px;
    line-height: 14px;
    background: linear-gradient(90deg, #ff143f 0%, #c600dd 13.97%, #1b27ff 59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 5px;
  }
  @include tablet() {
    font-size: 18px;
    line-height: 20px;
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  @include tablet-1024() {
    font-size: 24px;
  }
  @include desktop() {
    font-size: 30px;
    line-height: 32px;
  }
}
.faq {
  display: block;
  width: 80%;
  max-width: 1320px;
  margin: auto;
  padding: 15px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  .question {
    position: relative;
    @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    font-size: 18px !important;
    font-weight: 300 !important;
    transition: all 0.4s linear;
    padding: 10px 0 10px 112px;
    &:hover,
    &:focus {
      font-weight: 600 !important;
    }
    &::after {
      margin-right: 62px;
      content: "+";
      color: #d3d3d3;
      font-size: 57px;
      font-weight: 200;
      border-radius: 50%;
      background: #efefef;
      padding: 9px 5px 4px 6px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotate(0deg);
      width: 42px;
      height: 42px;
    }
  }
  @include mobile() {
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
    padding: 5px 7px;
    .question {
      //change
      padding: 5px 28px 0px 12px !important;
      font-size: 14px !important;
      line-height: 15px !important;
      font-weight: 500 !important;
      &::after {
        background: #ffffff !important;
        width: 20px !important;
        height: 20px !important;
        font-size: 28px !important;
        font-weight: 400;
        margin-right: 0;
        padding: 3px 2px 4px 2px;
      }
    }
  }
  @include tablet() {
    width: 100%;
    background-color: transparent !important;
    border: none !important;
    padding: 5px 5px 5px 5px;
    .question {
      padding: 5px 35px 5px 12px !important;
      font-size: 16px !important;
      line-height: 20px !important;
      &::after {
        background: #ffffff !important;
        width: 24px !important;
        height: 24px !important;
        font-size: 26px !important;
        font-weight: 400;
        margin-right: 0;
        padding: 3px 5px 4px 5px;
      }
    }
  }
  @include tablet-1024() {
    .question {
      padding-left: 22px !important;
      padding-right: 22px !important;
      &::after {
        margin-right: 0;
        padding: 9px 6px 7px 5px;
      }
    }
  }
}
.faq.open .question::after {
  content: "-";
  transition: 400ms;
  color: #d3d3d3;
  font-size: 110px;
  font-weight: 200;
  padding: 9px 7px 7px 5px;
  margin-right: 62px;
  @include mobile() {
    margin-right: 0;
    padding: 1px 7px 7px 3px;
    font-size: 49px !important;
    // font-size: 37px !important;
    font-weight: 400 !important;
  }
  @include tablet() {
    margin-right: 0;
    padding: 1px 3px 8px 4px;
    font-size: 57px !important;
    font-weight: 300 !important;
  }
  @include tablet-1024() {
    margin-right: 0;
    padding: 9px 7px 7px 5px;
  }
}
.faq .answer {
  @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
  padding-left: 112px;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
  @include mobile() {
    padding: 0px 0 0px 12px !important;
    //change
    font-size: 14px !important;
    line-height: 15px !important;
  }
  @include tablet() {
    padding: 5px 0 5px 32px !important;
    font-size: 15px !important;
    line-height: 18px !important;
  }
  @include tablet-1024() {
    padding-left: 32px;
  }
}
.faq.open .answer {
  opacity: 1;
  max-height: 1000px;
  padding-bottom: 20px;
  @include mobile() {
    padding: 5px 0 5px 12px !important;
    //change
    font-size: 14px !important;
    line-height: 15px !important;
  }
}
</style>
