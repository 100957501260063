<template>
  <div id="app">
    <swiper
    :slidesPerView="3"
    :spaceBetween="0"
    :centeredSlides="true"
    :pagination="{
      clickable: false,
    }"
    :loop="true"
    :navigation="navigation2"
    :modules="[Thumbs]" :thumbs="{ swiper: thumbsSwiper }"
    
    class="mySwiper"
  >
        <!-- <swiper-slide v-for="v in video" :key="v">
            <iframe width="333" height="183" :src="v.video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide> -->
        
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/3qvVHVcPWqw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/NGd9iogZVZE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/vzh_NisTSeQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/9mtWLY6ZhPc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/VTZzFSGc8XU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/ESOidRBE01c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/gawX7EVz5ro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <swiper-slide>
            <iframe width="333" height="183" src="https://www.youtube.com/embed/BO2YW2URw6s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </swiper-slide>
        <div class="nav-btn">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
  </swiper>
</div>
</template>

<script scoped>
// Import Swiper Vue.js components
  import { ref } from 'vue';
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Thumbs } from 'swiper';

  import "swiper/css";
  import "swiper/css/pagination";
  import "swiper/css/navigation";

  import SwiperCore, { Navigation } from "swiper";
  import { Autoplay, Pagination} from "swiper";
  // import Pagination from "./Pagination.vue";
  import axios from 'axios';
  import {mapState} from 'vuex';

SwiperCore.use([Navigation, Autoplay]);
export default {
  name: "App",
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
  },
  setup() {
      const thumbsSwiper = ref(null);
      const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
      };
      return {
        Thumbs,
        thumbsSwiper,
        setThumbsSwiper,
        modules: [Autoplay, Pagination, Navigation],
      };
    },
  
  data() {
    return {
      showLoader: false,
      items: [],
      page: 2,
      totalPages: 5,
      totalRecords: 10,
      recordsPerPage: 10,
      activeBtn:'btn1',
      navigation2: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      video: [],
    };
  },
  created(){
        axios.get('V1/company-videos')
            .then(response => {
                this.video = response.data.data
            })
            .catch(e => {
                this.errors.push(e)
            })
    },
    computed :{
        ...mapState(['cdn']),
    },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
    @media(min-width: 320px) and (max-width: 425px) {
        @content;
    }
}

@mixin tablet {
    @media(min-width: 425px) and (max-width: 767px) {
        @content;
    }
}

@mixin tablet-1024 {
    @media(min-width: 767px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
  @media(min-width: 1024px) and (max-width: 1620px) {
      @content;
  }
}

@mixin desktop-max {
    @media(min-width: 1621px) and (max-width: 2560px) {
        @content;
    }
}
.swiper-button-disabled{
    display:none;   
}
.gallery-top .swiper-slide{
  padding-top: 100%;
}  
.swiper {
  &-button {
    &-prev {
      left: 31%;
      background: url('../assets/images/leftg.png');
      &::after{
        content: '';
      }
    } 
    &-next {
      right: 31%;
      background: url('../assets/images/rightg.png');
            &::after{
        content: '';
      }
    }
  }
}
.container {
    padding-left: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.mySwiper{
  overflow: hidden;
  align-items: center !important;
  .swiper-slide-active{
    // transform: scale(1.3);
    iframe {
      width: 100%;
      // width: 919px;
      height: 384px;
    }
    &:first-child {
      iframe {
        margin-left: -50px;
      }
    }
  }
  .swiper-slide-prev {
    margin-top: 5%;
  }
  .swiper-slide-next {
    margin-top: 5%;
  }
  .swiper-slide-duplicate {
    iframe {
      margin-top: 14%;
    }
  }
  .swiper-wrapper {
    align-items: center !important;
  }
}
</style>
