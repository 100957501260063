<template>
    <about-us-header/>
    <about-back/>
    <about-buttons/>
    <div class="container">
        <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque voluptas nisi recusandae enim natus voluptatibus nihil, incidunt minus cumque iusto omnis soluta velit atque autem, esse excepturi quos porro nam?
        </p>
    </div>
</template>

<script>
import aboutUsHeader from './aboutUsHeader.vue';
import AboutBack from './AboutBack.vue';
import aboutButtons from './aboutButtons.vue';
export default {
    components: {aboutUsHeader,AboutBack,aboutButtons}
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    p {
        @include fontStyle(Roboto, normal, 400, 14px, 18px, #373737);
    }
</style>