<template>
    <div>
        <footer-main/>
    </div>
</template>

<script>
import FooterMain from '../components/FooterMain.vue'
export default {
    components: {FooterMain}
}
</script>