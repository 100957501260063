<template>
        <div class="range">
            <div class="range-back">
                <div class="container1">
                    <div class="range-cards">
                        <div class="range-left">
                            <p class="range-title" v-html="progressTitle.title"></p>
                            <!-- <p class="range-title">Отлаженный бизнес-процесс <br> перехода под нашу <br> <span>страховую защиту</span></p> -->
                            <div class="range-left-card">
                                <div class="range-left-card__box" data-aos="fade-right" v-html="progress2"></div>
                                <div class="range-left-card__num">02.</div>
                            </div>
                            <div class="range-left-card">
                                <div class="range-left-card__box" data-aos="fade-right" v-html="progress4"></div>
                                <div class="range-left-card__num">04.</div>
                            </div>
                        </div>
                        <div class="range-vertical">
                            <!-- <Progressbar :progress="progress" /> -->
                            <!-- {{ scrollAsd }} -->
                            <div class="progress-bar">
        <!-- <div :class="(scrollAsd == 0) ? 'progress-inner complete' : 'progress-inner'" :style="`will-change: width, height; height: ${scrollAsd}%`"><span :class="(scrollAsd > 50 ) ? 'white' : ''">{{ scrollAsd }}%</span></div> -->
        <div :class="(scrollAsd == 45) ? 'progress-inner complete' : 'progress-inner'" :style="`will-change: width, height; height: ${scrollAsd}%`"></div>
    </div>
                        </div>
                        <div class="range-right">
                            <div class="range-right-card">
                                <div class="range-right-card__num">01.</div>
                                <div class="range-right-card__box" data-aos="fade-left" v-html="progress1"></div>
                            </div>
                            <div class="range-right-card">
                                <div class="range-right-card__num">03.</div>
                                <div class="range-right-card__box" data-aos="fade-left" v-html="progress3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
// import Progressbar from '../components/Progressbar';
// import Post from './components/Post';
// import Progressbar from '../components/Progressbar.vue';
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    components: {
        // Progressbar
    },
    data () {
        return {
            // progress: 0,
            scrollAsd: null,
            reached: false,
            scaleClass: false,
            progressTitle: [],
            progress1: [],
            progress2: [],
            progress3: [],
            progress4: [],
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        axios.get('V1/business_process')
        .then(response => {
            this.progressTitle = response.data.data.title
            this.progress1 = response.data.data.descriptions[0].description
            this.progress2 = response.data.data.descriptions[1].description
            this.progress3 = response.data.data.descriptions[2].description
            this.progress4 = response.data.data.descriptions[3].description
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    computed :{
        ...mapState(['cdn']),
    },
    methods: {
            setProgress(progress){
                this.scrollPercentRounded = progress
            },
            handleScroll(event) {
            const offsetTop = window.scrollY || 0;
            const percentage = (offsetTop * 100) / document.body.scrollHeight;
            if (percentage >= 30) {
                const newss = (percentage-30)
                const newsss = ((newss)*10)
                this.scrollAsd = newsss
            }
        },
    },
    mounted () {
        this.$el.addEventListener('scroll', function() {
            let scrollTop = this.$el.scrollTop;
            let p_height = this.$el.clientHeight;
            let c_height = this.$el.scrollHeight;
            
            let scrollPercent = (scrollTop) / (c_height - p_height);
            let scrollPercentRounded = Math.round(scrollPercent*100);
            // this.scrollAsd = scrollPercentRounded
            // this.progress = scrollPercentRounded;
            // console.log(scrollPercentRounded)
            // this.$emit('progressUpdate', scrollPercentRounded);
        }.bind(this));
    }
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 426px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 426px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media (min-width: 1441px) {
            @content;
        }
    }
    .progress-bar {
        width: 28px;
        height: 60vw;
        border-radius: 15px;
        background: #EEE;
        position: relative;
        justify-content: start;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        /* margin: 40px; */
        @include desktop() {
            height: 80vh;
            width: 22px;
        }
    }
    .progress-inner {
        height: 100%;
        // background-image: linear-gradient(to right, #2484E4, #44C444);
        background-image: linear-gradient(180deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
        // border-radius: 0px 10px 10px 0px;
        border-radius: 15px;
    }
    .progress-inner.complete {
        border-radius: 15px;
    }
    article {
        height: 100%;
        flex: 1 1 100%;
        max-height: calc(100vh - 100px);
        background-color: #FFF;
        // overflow: scroll;
    
    }
    .container1 {
        margin: 0 auto;
        max-width: 1320px;
        width: 80%;
        padding: 50px 0;
    }
    .range {
        background: radial-gradient(54.37% 218.61% at 47.56% 45.63%, #E7E7E7 0%, #DADADA 100%);
        max-height: 600px;
        &-back {
            background: url(../assets/images/cargo-wave.png);
        }
        &-vertical {
            margin-left: 18px;
            margin-right: 18px;
        }
        &-title {
            padding-bottom: 41px;
            @include fontStyle(Roboto, normal, 700, 36px, 49px, #373737);
            text-transform: uppercase;
            span {
                background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
        &-cards {
            display: flex;
            justify-content: space-evenly;
            // top: 500px;
        }
        &-left, &-right {
            // display: flex;
            &-card {
                display: flex;
                align-items: center;
                margin-bottom: 100px;
                &:first-child {
                    margin-bottom: 100px;
                }
                &__box {
                    width: 536px;
                    background: rgba(255, 255, 255, 0.4);
                    border: 1px solid rgba(255, 255, 255, 0.4);
                    box-sizing: border-box;
                    backdrop-filter: blur(40px);
                    border-radius: 15px;
                    padding: 54px 56px;
                    @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
                }
                &__num {
                    @include fontStyle(Roboto, normal, 900, 60px, 82px, #FFFFFF);
                    text-transform: uppercase;
                    // backdrop-filter: blur(7px);
                    padding-left: 7px;
                }
            }
        }
        &-right {
            margin-top: 50px;
        }
        // &-left {
            
        // }
        @include tablet-1024() {
            margin-top: 40px;
            &-title {
                font-size: 16px;
                line-height: 22px;
            }
        }
        @include desktop() {
            overflow: hidden;
            &-back {
                background-size: contain;
                background-repeat: no-repeat;
            }
            &-title {
                font-size: 25px;
                line-height: 32px;
                padding-bottom: 15px;
            }
            &-left {
                width: 40%;
                &-card {
                    // margin-bottom: 60px;
                }
            }
            // &-right {
            //     &-card {
                    
            //     }
            // }
            &-left, &-right {
                &-card {
                    // margin-bottom: 70px !important;
                    margin-bottom: 60px;
                    &__box {
                        width: 380px;
                        padding: 35px 20px;
                        font-size: 14px;
                        line-height: 18px;
                        border-radius: 8px;
                    }
                    &__num {
                        font-size: 28px;
                        line-height: 35px;
                    }
                }
            }
        }
        @include desktop-max() {
            padding-top: 30px;
            max-height: 927px;
            overflow: hidden;
        }
    }
</style>