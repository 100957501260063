<template>
  <div>
    <div class="top">
      <div class="container3">
        <h2 class="top-title" v-html="title"></h2>
        <p class="top-sub" v-html="desc"></p>
      </div>
      <div class="container2 m_none">
        <SliderMain1 />
      </div>
      <div class="d_none">
        <SliderMainMobile />
      </div>
    </div>
    <NewsCompany />
  </div>
</template>

<script>
import SliderMain1 from "./SliderMain1.vue";
import axios from "axios";
import SliderMainMobile from "./SliderMainMobile.vue";
import NewsCompany from "./NewsCompany.vue";
export default {
  name: "ProductMain",
  components: {
    SliderMain1,
    SliderMainMobile,
    NewsCompany,
  },
  data() {
    return {
      title: [],
      desc: [],
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/top-products")
      .then((response) => {
        this.title = response.data.big_title;
        this.desc = response.data.big_description;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.container2 {
  margin: 0 auto;
  max-width: 1380px;
  width: 80%;
  position: relative;
  @include desktop() {
    // width: 84%;
  }
  // @include desktop-max() {
  //     width: 76%;
  // }
}
.container3 {
  margin: 0 auto;
  max-width: 1320px;
  width: 80%;
}
.top {
  padding-top: 100px;
  background: #efefef;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 119.69%, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-bottom: 30px;
    &-mobile {
      display: none;
    }
    // span {
    //     background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //     background-clip: text;
    // }
  }
  &-sub {
    @include fontStyle(Roboto, normal, 300, 20px, 119.69%, #373737);
    padding-bottom: 18px;
    text-align: justify;
    @include desktop-max() {
      width: 105%;
    }
  }
  @include mobile() {
    //changes
    padding-top: 0px;
    padding-bottom: 0px;
    background: #efefef;
    &-title {
      display: none;
      &-mobile {
        display: block;
        @include fontStyle(Roboto, normal, 500, 12px, 14px, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-bottom: 14px;
      }
    }
    &-sub {
      display: none;
    }
  }
  @include tablet() {
    padding-top: 30px;
    padding-bottom: 30px;
    background: #efefef !important;
    &-title {
      display: none;
      &-mobile {
        display: block;
        @include fontStyle(Roboto, normal, 500, 16px, 18px, transparent);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        padding-bottom: 20px;
      }
    }
    &-sub {
      display: none;
    }
  }
  @include tablet-1024() {
    &-title {
      font-size: 26px;
    }
    &-sub {
      font-size: 15px;
    }
  }
  // @include desktop-max() {
  //     padding-bottom: 40px;
  // }
}
.news {
  // padding-top: 122px;
  background-color: #efefef;
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 119.69%, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-bottom: 34px;
  }
  &-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;
    &__card {
      display: flex;
      justify-content: space-around;
      &__words {
        padding-left: 20px;
        .date {
          @include fontStyle(Roboto, normal, 300, 18px, 119.69%, #959595);
        }
        .par {
          @include fontStyle(Roboto, normal, 300, 18px, 119.69%, #373737);
        }
        .more {
          @include fontStyle(Roboto, normal, 300, 18px, 119.69%, transparent);
          background: linear-gradient(90deg, #f5010a 0%, #1621e8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
  }
  @include mobile() {
    padding-top: 20px;
    background: #ffffff;
    &-title {
      font-size: 12px;
      line-height: 14px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-item {
      padding-bottom: 0px;
      flex-wrap: wrap;
      &__card {
        &:last-child {
          padding-top: 35px;
        }
        img {
          width: 125px;
          height: 80px;
        }
        &__words {
          .date {
            font-size: 7px;
            line-height: 8px;
          }
          .par,
          .more {
            font-size: 8px;
            line-height: 10px;
          }
          .par {
            margin-top: -10px;
            margin-bottom: 0;
          }
          .more {
            span img {
              width: 12px;
              height: 5px;
            }
          }
        }
      }
    }
  }
  @include tablet() {
    padding-top: 20px;
    background: #ffffff;
    &-title {
      font-size: 12px;
      line-height: 14px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-item {
      padding-bottom: 30px;
      flex-wrap: wrap;
      &__card {
        &:last-child {
          padding-top: 15px;
        }
        img {
          width: 155px;
          height: 100px;
        }
        &__words {
          .date {
            font-size: 7px;
            line-height: 8px;
          }
          .par,
          .more {
            font-size: 10px;
            line-height: 12px;
          }
          .more {
            span img {
              width: 12px;
              height: 5px;
            }
          }
        }
      }
    }
  }
  @include tablet-1024() {
    padding-top: 30px;
    &-item {
      flex-wrap: wrap;
      &__card {
        padding-top: 20px;
        img {
          width: 200px;
          height: 160px;
        }
        &__words {
          .date {
            font-size: 12px;
          }
          .par {
            font-size: 15px;
            line-height: 17px;
          }
          .more {
            font-size: 15px;
            line-height: 17px;
            span {
              img {
                width: 15px;
                height: 4px;
              }
            }
          }
        }
      }
    }
  }
  @include desktop() {
    &-item {
      &__card {
        &__words {
          .date {
            font-size: 12px;
          }
          .par {
            font-size: 15px;
            line-height: 17px;
          }
          .more {
            font-size: 15px;
            line-height: 17px;
            span {
              img {
                width: 15px;
                height: 4px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
