<template>
  <div class="rating">
    <div class="container">
      <div class="rating-title">{{ $t("buttons.rating") }}</div>
      <div class="rating-top">
        <div
          class="rating-top__box"
          v-for="rat in ratings.slice(0, 4)"
          :key="rat"
        >
          <div class="img"><img :src="cdn + rat.icon" alt="" /></div>
          <p v-html="rat.description"></p>
        </div>
      </div>
      <div class="rating-bottom">
        <div
          class="rating-bottom__box"
          v-for="rat in ratings.slice(4, 8)"
          :key="rat"
        >
          <div class="img">
            <img :src="cdn + rat.icon" alt="" />
          </div>
          <p v-html="rat.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      ratings: [],
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/ratings")
      .then((response) => {
        this.ratings = response.data.data;
        // console.log(response)
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 767px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.rating {
  margin-top: 75px;
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-bottom: 50px;
  }
  &-top,
  &-bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    &__box {
      text-align: center;
      align-items: center;
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60%;
        max-width: 300px;
        width: 100%;
      }
      p {
        @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
        text-align: center;
        padding-top: 15px;
      }
    }
  }
  @include mobile() {
    display: none;
  }
  @include tablet() {
    display: none;
  }
  @include tablet-1024() {
    &-title {
      font-size: 12px;
      line-height: 15px;
      padding-bottom: 40px;
    }
    &-top,
    &-bottom {
      align-items: end;
      &__box {
        margin: 0 auto;
        img {
          max-width: 80px;
          width: 100%;
        }
        p {
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
    &-top {
      margin-bottom: 30px;
    }
  }
}
</style>
