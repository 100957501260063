<template>
  <about-us-header />
  <about-back />
  <about-buttons />
  <div class="acc">
    <div class="container">
      <div class="acc-card">
        <input id="check00" type="checkbox" name="menu" />
        <label for="check00"
          >{{ $t("documents.rule1") }} <i class="arrow down"></i
        ></label>
        <ul class="submenu" v-for="rl in rule" :key="rl">
          <a :href="cdn + rl.doc"
            ><img src="@/assets/images/download.svg" alt="" />
            <p v-html="rl.title"></p
          ></a>
        </ul>
      </div>
      <div class="acc-card">
        <input id="check01" type="checkbox" name="menu" />
        <label for="check01"
          >{{ $t("documents.rule2") }} <i class="arrow down"></i
        ></label>
        <ul class="submenu" v-for="rl in rule1" :key="rl">
          <a :href="cdn + rl.doc"
            ><img src="@/assets/images/download.svg" alt="" />
            <p v-html="rl.title"></p
          ></a>
        </ul>
      </div>
      <div class="acc-card">
        <input id="check02" type="checkbox" name="menu" />
        <label for="check02"
          >{{ $t("documents.rule3") }} <i class="arrow down"></i
        ></label>
        <ul class="submenu" v-for="rl in rule2" :key="rl">
          <a :href="cdn + rl.doc"
            ><img src="@/assets/images/download.svg" alt="" />
            <p v-html="rl.title"></p
          ></a>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "../components/aboutUsHeader.vue";
import AboutBack from "../components/AboutBack.vue";
import aboutButtons from "../components/aboutButtons.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "financeMobile",
  components: {
    aboutUsHeader,
    AboutBack,
    aboutButtons,
  },
  data() {
    return {
      rule: [],
      rule1: [],
      rule2: [],
    };
  },
  created() {
    axios
      .get("V1/constdoc")
      .then((response) => {
        this.rule = response.data.mandotary;
        this.rule1 = response.data.voluntary;
        this.rule2 = response.data.other;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.arrow {
  margin: 0 0 2px 5px;
  border: solid #959595;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
input,
ul.submenu {
  display: none;
}

/*position the label*/
label {
  position: relative;
  display: block;
  cursor: pointer;
  @include fontStyle(Roboto, normal, 500, 12px, 12px, #959595);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    margin-left: 10px;
    padding-bottom: 5px;
  }
}

/*show the submenu when input is checked*/
input:checked ~ ul.submenu {
  display: block;
  transition: all 0.4s linear;
}
.submenu a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 20px;
  img {
    width: 19px;
  }
  p {
    @include fontStyle(Roboto, normal, 300, 13px, 17px, #373737);
    margin-bottom: 0;
    padding-left: 9px;
  }
  @include mobile() {
    padding-top: 10px;
  }
  @include tablet() {
    padding-top: 10px;
  }
}
.container {
  padding-left: 0;
}
.acc {
  padding: 80px 0;
  background: #efefef;
  &-card {
    margin-bottom: 30px;
    ul {
      margin-bottom: 0;
    }
  }
  @include mobile() {
    padding: 25px 0 10px 0;
  }
  @include tablet() {
    padding: 25px 0 15px 0;
  }
}
</style>
