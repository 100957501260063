<template>
  <div class="kasko-slider">
    <swiper
      :spaceBetween="30"
      :centeredSlides="true"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: true,
      }"
      :navigation="false"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="job in vac" :key="job">
        <div class="vacancy-top__card">
          <div class="vacancy-top__card__left">
            <h4 v-html="job.title"></h4>
            <h4 v-html="job.salary"></h4>
            <p v-html="job.description"></p>
          </div>
          <div class="vacancy-top__card__right">
            <p>{{ format_date(job.created_at) }}</p>
            <div class="btn">
              <button>
                <a href="https://hh.kz/employer/138706">{{
                  $t("buttons.moreBtn")
                }}</a>
              </button>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide v-for="vc in vac.slice(2, 4)" :key="vc">
        <div class="vacancy-bottom__card">
          <div class="vacancy-bottom__card__left">
            <h4 v-html="vc.title"></h4>
            <h4 v-html="vc.salary"></h4>
            <p v-html="vc.description"></p>
          </div>
          <div class="vacancy-bottom__card__right">
            <p>{{ format_date(vc.created_at) }}</p>
            <div class="btn">
              <button>
                <a href="https://hh.kz/employer/138706">{{
                  $t("buttons.moreBtn")
                }}</a>
              </button>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      vac: [],
    };
  },
  created() {
    axios
      .get("V1/vacancies")
      .then((response) => {
        this.vac = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    format_date(value) {
      const lang = localStorage.getItem('lang');
      if(lang == 'kz') {
        moment.locale('kk');
      }
      else if(lang == 'ru') {
        moment.locale('ru')
      }
      if (value) {
        return moment(String(value)).format('LL');
      }
    },
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
.vacancy {
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-sub {
    @include fontStyle(Roboto, normal, 300, 18px, 23px, #373737);
    padding: 30px 0;
  }
  &-top,
  &-bottom {
    display: flex;
    justify-content: space-between;
    &__card {
      padding: 40px 0 40px 50px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      // border: 2px solid #D3D3D3;
      box-sizing: border-box;
      border-radius: 15px;
      max-width: 600px;
      width: 100%;
      &__left {
        h4 {
          @include fontStyle(Roboto, normal, 400, 24px, 33px, #373737);
          text-align: left;
        }
        p {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
          text-align: left;
        }
      }
      &__right {
        text-align: center;
        p {
          @include fontStyle(Roboto, normal, 300, 14px, 17px, #959595);
        }
        .btn {
          a {
            color: #8948b1;
            &:hover {
              color: #ffffff;
            }
          }
          button {
            margin-top: 20px;
            margin-right: 20px;
            width: 154px;
            height: 69px;
            border: none;
            background-color: #efefef;
            border-radius: 15px;
            @include fontStyle(Roboto, normal, 600, 18px, 25px, #8948b1);
            &:hover {
              background: linear-gradient(
                90deg,
                #ff143f 0%,
                #c600dd 49.97%,
                #1b27ff 100%
              );
            }
          }
        }
      }
    }
  }
  @include mobile() {
    margin: 25px 0;
    padding-top: 20px;
    &-title {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
    &-top,
    &-bottom {
      &__card {
        max-width: 320px;
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        margin-bottom: 30px;
        &__left {
          width: 70%;
          h4,
          p {
            font-size: 14px;
            line-height: 16px;
          }
        }
        &__right {
          width: 30%;
          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
          }
          .btn {
            padding: 0px;
            margin-top: 10px;
            button {
              min-width: 70px;
              max-width: 80px;
              height: 38px;
              font-size: 12px;
              line-height: 13px;
              border-radius: 3px;
            }
          }
        }
      }
    }
    &-sub {
      display: none;
    }
  }
  @include tablet() {
    margin: 25px 0;
    &-title {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
    &-top,
    &-bottom {
      &__card {
        max-width: 320px;
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        margin-right: 10px;
        margin-bottom: 30px;
        &__left {
          width: 70%;
          h4,
          p {
            font-size: 14px;
            line-height: 16px;
          }
        }
        &__right {
          width: 30%;
          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
          }
          .btn {
            padding: 0px;
            margin-top: 10px;
            button {
              min-width: 70px;
              max-width: 90px;
              height: 38px;
              font-size: 12px;
              line-height: 13px;
              border-radius: 3px;
            }
          }
        }
      }
    }
    &-sub {
      display: none;
    }
  }
  @include tablet-1024() {
    &-title {
      font-size: 22px;
      line-height: 24px;
    }
    &-sub {
      font-size: 15px;
      line-height: 18px;
      padding: 15px 0;
    }
    &-top,
    &-bottom {
      &__card {
        max-width: 285px;
        width: 100%;
        // height: 110px;
        padding: 15px;
        border-radius: 6px;
        margin-right: 10px;
        &__left {
          width: 70%;
          h4,
          p {
            font-size: 11px;
            line-height: 14px;
          }
        }
        &__right {
          width: 30%;
          p {
            font-size: 9px;
            line-height: 10px;
            margin-bottom: 0;
          }
          .btn {
            padding: 0px;
            margin-top: 10px;
            button {
              width: 80px;
              height: 32px;
              font-size: 9px;
              line-height: 13px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
// .kasko-card {
//     p {
//         @include fontStyle(Roboto, normal, 300, 10px, 12px, #373737);
//         background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         background-clip: text;
//         text-align: center;
//         padding-top: 10px;
//     }
//     @include tablet() {
//         p {
//             font-size: 14px;
//             line-height: 17px;
//         }
//     }
// }
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>
