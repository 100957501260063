<template>
  <div id="app">
    <div class="container">
      <p class="history-title">{{ $t("buttons.history") }}</p>
      <div class="gradient-line">
        <hr class="line" />
      </div>
    </div>
    <swiper
      :slidesPerView="5"
      :spaceBetween="70"
      :centeredSlides="true"
      :pagination="{
        clickable: false,
      }"
      :navigation="navigation"
      :modules="[Thumbs]"
      :thumbs="{ swiper: thumbsSwiper }"
      class="mySwiper"
    >
      <swiper-slide class="slide" v-for="h in history" :key="h">
        <h2 class="slide-year" v-html="h.year"></h2>
        <button class="slide-btn">
          <span></span>
        </button>
      </swiper-slide>
      <div class="nav-btn">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </swiper>
    <swiper
      :pagination="{
        dynamicBullets: true,
      }"
      :modules="[Thumbs]"
      watch-slides-progress
      @swiper="setThumbsSwiper"
      class="mySwiper2"
    >
      <swiper-slide v-for="hs in history" :key="hs">
        <div class="team">
          <div class="team-right">
            <p v-html="hs.data[0].title"></p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script scoped>
// Import Swiper Vue.js components
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import { Pagination } from "swiper";
import axios from "axios";
import { mapState } from "vuex";
SwiperCore.use([Navigation]);
export default {
  name: "App",
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      history: [],
      showLoader: false,
      items: [],
      page: 2,
      totalPages: 5,
      totalRecords: 10,
      recordsPerPage: 10,
      activeBtn: "btn1",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  },
  created() {
    axios
      .get("V1/histories")
      .then((response) => {
        this.history = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 425px) and (max-width: 767px) {
    @content;
  }
}

@mixin tablet-1024 {
  @media (min-width: 767px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1620px) {
    @content;
  }
}

@mixin desktop-max {
  @media (min-width: 1621px) and (max-width: 2560px) {
    @content;
  }
}
#app {
  margin-top: 90px;
}
.history-title {
  @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 60px;
  // margin-left: 4%;
}
.swiper-button,
.swiper-button {
  &-next {
    top: 71%;
    background: url("@/assets/images/right.png");
  }
  &-prev {
    top: 71%;
    background: url("@/assets/images/left.png");
  }
}
.gradient-line {
  width: 95%;
  margin: 0 auto;
}
.line {
  height: 2px;
  background: linear-gradient(90deg, #ff143f 0%, #c600dd 49.97%, #1b27ff 100%);
  opacity: 1;
  margin-bottom: -94px;
}
.mySwiper2 {
  width: 80%;
  max-width: 1320px;
  margin: 0 auto;
  height: 220px;
}
.container {
  padding-left: 0;
  padding-right: 0;
}

.team {
  top: 25px;
  max-width: 550px;
  margin: 0 auto;
  border-radius: 15px !important;
  background-clip: padding-box;
  background: #fff;
  border: 1px solid transparent;
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background: linear-gradient(
      to left,
      #1b27ff 20%,
      #c600dd 35%,
      #ff143f 100%
    );
    border-radius: 16px;
    z-index: -1;
    background-clip: padding-box;
  }
  &-right {
    ul {
      li {
        @include fontStyle(Roboto, normal, 400, 18px, 22px, #373737);
        padding-bottom: 10px;
        text-align: initial;
        margin-left: 26px;
        // list-style: none;
        padding: 15px 10px 0 10px;
        // &::before {
        //     content: "\2022";
        //     color: #959595;
        //     font-weight: bold;
        //     display: inline-block;
        //     width: 7px;
        //     margin-right: 5px;
        //     font-size: 12px;
        // }
      }
    }
    p {
      @include fontStyle(Roboto, normal, 400, 18px, 22px, #373737);
      text-align: center;
      margin-bottom: 0;
      padding: 20px;
    }
  }
}
.slide {
  &-year {
    @include fontStyle(Roboto, normal, 400, 20px, 17px, #959595);
  }
}
.slide-btn {
  border: none;
  width: 19px;
  height: 19px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 50%;
  background: rgba(225, 0, 135, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    width: 9px;
    height: 9px;
    background: #e20a90;
    border-radius: 50%;
  }
  p {
    @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
  }
}
.mySwiper {
  overflow: hidden;
  height: 130px;
  padding-top: 50px;
  width: 70%;
  .swiper-slide {
    text-align: center;
    margin-top: 8px;
    .slide-btn {
      margin: 0 auto;
    }
  }
  .swiper-slide-active {
    margin-top: -35px;
    .slide {
      &-year {
        font-size: 65px;
        line-height: 47px;
        margin-left: -25px;
      }
    }
    .slide-btn {
      margin: 0 auto;
      width: 46px;
      height: 46px;
      span {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.active {
  margin-top: -30px;
}
</style>
