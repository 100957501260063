<template>
  <swiper
    :slidesPerView="4"
    :spaceBetween="0"
    :slidesPerGroup="1"
    :pagination="false"
    :navigation="navigation"
    :modules="modules"
    :loop="true"
    class="mySwiper"
  >
    <swiper-slide v-for="slider in sliderMain" :key="slider">
      <a :href="slider.link">
        <v-lazy-image class="mySwiper__img" :src="cdn + slider.image" alt="" />
        <div class="par">
          <p class="mySwiper__title" v-html="slider.title"></p>
          <p class="mySwiper__sub" v-html="slider.description"></p>
        </div>
        <div class="mySwiper__btn">
          <button>{{ $t("buttons.moreBtn") }}</button>
        </div>
      </a>
    </swiper-slide>
  </swiper>
  <div class="nav-btn">
    <div class="swiper-button-prev">
      <img src="../images/left.svg" alt="" />
    </div>
    <div class="swiper-button-next">
      <img src="../images/right.svg" alt="" />
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";
import { mapState } from "vuex";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import VLazyImage from "v-lazy-image";

export default {
  name: "SliderMain1",
  props: ["thirdblock"],
  components: {
    Swiper,
    SwiperSlide,
    VLazyImage
  },
  data() {
    return {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      sliderMain: [],
    };
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  created() {
    axios
      .get("V1/top-products")
      .then((response) => {
        this.sliderMain = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .swiper {
    top: 0px !important;
    bottom: 0px !important;
  }
  .container2 {
    width: 95%;
    position: relative;
  }
  .swiper-slide .mySwiper__img[data-v-5b5777b7] {
    margin-left: 0px;
  }
}
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1025px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.container2 {
  position: relative !important;
  @include desktop() {
    width: 80% !important;
  }
}
.nav-btn {
  width: 110%;
  height: 20px;
  position: absolute;
  top: 23%;
  display: flex;
  justify-content: space-between;
  @include desktop() {
    top: 21%;
  }
  @include tablet-1024() {
    top: 8%;
  }
}
.swiper {
  &-button {
    &-prev {
      left: -40px !important;
    }
    &-prev,
    &-next {
      img {
        width: 27px;
        height: 45px;
      }
      &:after {
        opacity: 0;
      }
    }
  }
  @include tablet-1024() {
    &-next {
      right: 27px;
    }
  }
  @include desktop() {
    &-prev,
    &-next {
      img {
        width: 18px;
        height: 33px;
      }
    }
  }
}
.box1 {
  align-items: center;
}
.par {
  height: 170px;
  @include tablet() {
    height: 0;
  }
  @include tablet-1024() {
    height: 50px;
    margin-left: 0px;
  }
  @include desktop() {
    height: 160px;
  }
  @include desktop-max() {
    margin-left: 30px;
    height: 155px;
    width: 82%;
    margin-bottom: 10px;
  }
  @media (min-width: 1441px) and (max-width: 1650px) {
    margin-left: 0px;
  }
}
.container {
  width: 100%;
}
.top {
  padding-top: 100px;
}

.swiper {
  // max-width: 1370px;
  width: 105%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  padding-bottom: 120px;
  &-button {
    &-next,
    &-prev {
      top: 42% !important;
      color: #c4c4c4;
    }
    &-prev {
      left: 0px;
    }
    &-next {
      right: 10px;
    }
    @include tablet-1024() {
      &-next,
      &-prev {
        top: 53% !important;
      }
      &-next {
        right: 0px;
      }
    }
    @include desktop() {
      &-next,
      &-prev {
        top: 38% !important;
      }
      &-next {
        right: 44px;
      }
    }
    @media (min-width: 1441px) and (max-width: 1650px) {
      &-next {
        right: 60px;
      }
      &-prev {
      }
    }
  }
  &-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 323px;
    a {
      text-decoration: none !important;
    }
    img {
      display: block;
      object-fit: cover;
    }
    .mySwiper {
      align-items: center;
      &__img {
        max-width: 76%;
        // height: 300px;
        margin-left: 0px;
        margin-top: 0px;
        height: 323px;
        &:hover {
          filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.35));
          border-radius: 15px !important;
        }
        @include desktop-max() {
          max-width: 91%;
        }
      }
      &__title {
        width: 80%;
        @include fontStyle(Roboto, normal, 400, 23px, 22px, #373737);
        text-align: left;
        padding-top: 30px;
        // margin: 0 auto;
      }
      &__sub {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        text-align: left;
        margin-top: 20px;
        margin-bottom: 25px;
        margin-left: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        height: 38px;
      }
      &__btn {
        width: 174px;
        height: 67px;
        margin: auto;
        text-align: end;
        button {
          width: 174px;
          height: 67px;
          border-radius: 15px;
          border: none;
          @include fontStyle(Roboto, normal, 500, 18px, 136.69%, #373737);
          background: #ffffff;
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            border-radius: 15px;
            color: #ffffff;
          }
        }
      }
    }
  }
  @include mobile() {
    &-slide {
      .mySwiper {
        top: 0px;
        bottom: 0px;
        &__img {
          width: 62px;
          height: 70px;
          // margin-left: 0;
          border-radius: 15px;
          margin-top: 0px !important;
        }
        &__title {
          font-size: 8px;
          line-height: 12px;
          margin-top: 5px;
        }
        &__sub,
        &__btn {
          display: none;
        }
      }
    }

    &-button {
      &-next::after,
      &-prev::after {
        font-size: 15px;
      }
    }
  }
  @include tablet() {
    &-slide {
      .mySwiper {
        width: 90px;
        display: flex;
        flex-wrap: wrap;
        &__img {
          width: 90px;
          height: 90px;
          // margin-left: 0;
          border-radius: 15px;
          margin-top: 0px !important;
        }
        &__title {
          font-size: 8px;
          line-height: 12px;
          margin-top: 5px;
          padding-top: 30px;
        }
        &__sub,
        &__btn {
          display: none;
        }
      }
    }
    &-button {
      &-next::after,
      &-prev::after {
        font-size: 15px;
      }
    }
  }
  @include tablet-1024() {
    &-slide {
      .mySwiper {
        width: 165px;
        align-items: center;
        &__img {
          width: 140px;
          height: 160px;
          max-width: 100%;
          border-radius: 15px;
          margin-right: 20px;
        }
        &__title {
          font-size: 14px;
          line-height: 18px;
          margin-top: 0;
          padding-top: 15px;
        }
        &__sub {
          display: none;
          font-size: 8px;
          line-height: 10px;
          margin-top: 5px;
          margin-bottom: 0px;
          margin-left: 0px;
        }
        // .par {
        //     height: 110px;
        //     margin-left: 10px;
        // }
        &__btn {
          display: none;
          button {
            width: 100px;
            height: 30px;
            border-radius: 5px;
            font-size: 12px;
            margin: auto;
          }
        }
      }
    }
  }
  @include desktop() {
    &-slide {
      .mySwiper {
        align-items: center;
        .swiper-slide {
          width: 257px;
        }
        &__img {
          width: 262px;
          height: 264px;
          border-radius: 15px;
          margin-left: 0px;
          max-width: 86%;
        }
        &__btn {
          // display: none;
          margin-left: 0px;
        }
        &__title {
          height: 80px;
          font-size: 23px;
          line-height: 25px;
          margin-left: 0px;
        }
        &__sub {
          font-size: 14px;
          width: 90%;
          line-height: 18px;
          margin-left: 0px;
          margin-top: 5px;
        }
      }
    }
  }
  @include desktop-max() {
    &-slide {
      .mySwiper {
        &__btn {
          margin-left: 30px;
          margin-top: 10px;
        }
        &__title {
          margin-left: 0;
          width: 100%;
          height: 60px;
        }
        &__sub {
          margin-left: 0;
          height: 47px;
          margin-top: 20px;
        }
        &__img {
          width: 299px;
          height: 323px;
          border-radius: 15px;
          margin-left: 30px;
        }
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1650px) {
    &-slide {
      .mySwiper {
        &__btn {
          margin-left: 0px;
        }
        &__title {
          margin-left: 0;
        }
        &__sub {
          margin-left: 0;
        }
        &__img {
          margin-left: 0;
          max-width: 85%;
          height: 300px;
        }
      }
    }
  }
}
@include tablet-1024() {
  .swiper {
    padding-bottom: 50px;
  }
}
</style>
