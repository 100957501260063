<template>
  <about-us-header />
  <about-back />
  <div class="info-back animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="container">
      <div class="info">
        <p class="info-title" v-html="compl2.title"></p>
        <!-- <p class="info-title">Информация для инсайдеров</p> -->
        <div class="info-download">
          <img class="img" src="../assets/images/download.svg" alt="" />
          <div class="info-download__par">
            <a :href="cdn + compl2.doc_ru" download
              ><p>
                {{ $t("documents.ruleRu") }}
              </p></a
            >
            <a :href="cdn + compl2.doc_kz" download
              ><p>
                {{ $t("documents.ruleKz") }}
              </p></a
            >
          </div>
          <div class="info-download__par__mobile">
            <span
              ><img src="../assets/images/download.png" /><a
                :href="cdn + compl2.doc_ru"
                download
                ><p>
                  {{ $t("documents.ruleRu") }}
                </p></a
              ></span
            >
            <span
              ><img src="../assets/images/download.png" /><a
                :href="cdn + compl2.doc_kz"
                download
                ><p>
                  {{ $t("documents.ruleKz") }}
                </p></a
              ></span
            >
          </div>
        </div>
      </div>
      <div class="control">
        <div class="control-title" v-html="compl3.title"></div>
        <p class="mobile" v-html="compl3.first"></p>
        <p v-html="compl3.second"></p>
      </div>
    </div>
  </div>
  <div class="form">
    <div class="container">
      <div class="form-title">{{ $t("buttons.form") }}</div>
      <div class="form-check">
        <div class="form-check__auto">
          <input type="radio" id="public" value="public" v-model="picked" />
          <label for="public">{{ $t("buttons.public") }}</label>
        </div>
        <div class="form-check__anon">
          <input type="radio" id="anon" value="anon" v-model="picked" />
          <label for="anon">{{ $t("buttons.anon") }}</label>
        </div>
      </div>
      <Form ref="myForm" @submit="Send">
      <div class="form-input" v-if="picked == 'public'">
        <!-- <input type="text" placeholder="Имя" v-model="name" /> -->
        <div class="err-message">
          <Field
          @click="IsRemove = false"
            v-model="name"
            name="username"
            type="text"
            class="name"
            :placeholder="$t('placeholder.name')"
            :rules="requiredName"
            @keyup="onlyText()"
          />
          <div :class="{ remove: IsRemove }" class="">
            <ErrorMessage name="username" />
          </div>
        </div>
        <div class="err-message">
          <Field
          @click="IsRemove = false"
            v-model="email"
            type="email"
            :rules="validateEmail"
            name="email"
            class="mail mobile"
            id=""
            :placeholder="$t('placeholder.email')"
          />
          <div :class="{ remove: IsRemove }" class="">
            <ErrorMessage name="email" />
          </div>
        </div>
        <div class="err-message">
          <Field
          @click="IsRemove = false"
            v-mask="'+7(7##)-###-##-##'"
            v-model="phone"
            type="text"
            name="phone"
            class="tel"
            :placeholder="$t('placeholder.phone')"
            :rules="requiredNum"
          />
          <div :class="{ remove: IsRemove }" class="">
            <ErrorMessage name="phone" />
          </div>
        </div>
      </div>
      <div class="form-text">
        <!-- <input type="text" placeholder="Текст обращения..."> -->
        <textarea
          name=""
          id=""
          cols="90"
          rows="10"
          :placeholder="$t('placeholder.text')"
          v-model="textarea"
        ></textarea>
      </div>
      <div class="form-btn">
        <button
          type="submit"
          :class="{isDisabled : disabled}"
          @click="buttonClicked"
        >
          <div v-if="loading" class="spinner-border spinner-border-sm"></div>
          {{ $t("buttons.send") }}
        </button>
      </div>
    </Form>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "../components/aboutUsHeader.vue";
import AboutBack from "../components/AboutBack.vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import axios from "axios";
import { mapState } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
// const rules = computed( ()=> ({
//     name: {
//         minLength: minLength(3)
//     },
// }))
// const v = useVuelidate(rules, {name})
// console.log(v);
export default {
  components: { aboutUsHeader, AboutBack, Field, Form, ErrorMessage },
  data() {
    return {
      loading: false,
      disabled: false,
      picked: "public",
      name: null,
      email: null,
      phone: null,
      text: null,
      compl1: [],
      compl2: [],
      compl3: [],
      IsRemove: false,
    };
  },
  created() {
    axios
      .get("V1/complience")
      .then((response) => {
        this.compl1 = response.data.data.first_block;
        this.compl2 = response.data.data.second_block;
        this.compl3 = response.data.data.third_block;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    onlyText() {
      this.name = this.name.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    Send(values, {resetForm}) {
      console.log(values);
      this.form = {
        type: this.picked,
        name: this.name,
        email: this.email,
        phone: this.phone,
        text: this.textarea,
      };
      axios
        .post("V1/feedback", this.form, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.status == 201) {
            toaster.success(this.$t("notification.compl"));
            this.loading = !false;
            setTimeout(() => (this.loading = !true), 3000);
            this.name = "";
            this.email = "";
            this.phone = "";
            this.textarea = "";
            this.IsRemove = true;
            this.$refs.myForm.resetForm();
            resetForm()
          } else {
            // console.log('asdasdasdasd',res.data)
            toaster.error(this.$t("notification.req"));
          }
        })
        .catch((err) => {
          console.log(err);
          // console.log(auth);
          toaster.error(this.$t("notification.req"));
        });
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      //All is good
      return true;
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const checkForNumbers = /^[а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z]+$/i;
      if (!checkForNumbers.test(value)) {
        return "Неправильно введённые данные!";
      }
      //All is good
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      //All is good
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.remove {
  display: none;
  margin-bottom: -15px;
}
// .err-message {
//   display: flex !important;
//   @include mobile() {
//     display: block !important;
//   }
//   @include tablet() {
//     display: block !important;
//   }
// }
textarea {
  resize: none;
}
.info {
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-download {
    &__par {
      padding-left: 10px;
      &__mobile {
        display: none;
      }
    }
    margin-bottom: 75px;
    display: flex;
    align-items: center;
    img {
      width: 43px;
    }
    a {
      text-decoration: none;
    }
    p {
      @include fontStyle(Roboto, normal, 300, 20px, 24px, #373737);
      text-decoration-line: underline;
      margin-bottom: 0;
      &:first-child {
        padding-bottom: 10px;
      }
    }
  }
  @include mobile() {
    &-back {
      background: #efefef;
    }
    &-title {
      font-size: 14px;
      line-height: 17px;
      padding-top: 35px;
      font-weight: 500;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: 100%;
      padding-bottom: 0px;
    }
    &-download {
      margin-bottom: 15px;
      p {
        padding-bottom: 0 !important;
      }
      .img {
        display: none;
      }
      &__par {
        display: none;
        &__mobile {
          display: inline;
          span {
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            img {
              width: 20px;
              // padding-right: 5px;
            }
            p {
              @include fontStyle(Roboto, normal, 400, 12px, 14px, #373737);
              margin-bottom: 0;
              margin-left: 5px;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  @include tablet() {
    &-back {
      background: #efefef;
    }
    &-title {
      font-size: 14px;
      line-height: 15px;
      padding-top: 35px;
      padding-bottom: 0;
      font-weight: 500;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      width: 100%;
      padding-bottom: 20px;
    }
    &-download {
      margin-bottom: 30px;
      .img {
        display: none;
      }
      &__par {
        display: none;
        &__mobile {
          display: inline;
          span {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            img {
              width: 20px;
              padding-right: 5px;
            }
            p {
              @include fontStyle(Roboto, normal, 300, 12px, 12px, #373737);
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  @include tablet-1024() {
    &-title {
      font-size: 15px;
      line-height: 18px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &-download {
      img {
        width: 28px;
      }
      &__par {
        padding-left: 10px;
        p {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 0;
          &:first-child {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  @include desktop() {
    &-title {
      font-size: 24px;
      line-height: 28px;
      padding-top: 40px;
      padding-bottom: 20px;
    }
    &-download {
      img {
        width: 35px;
      }
      &__par {
        padding-left: 10px;
        p {
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 0;
          &:first-child {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  @include desktop-max() {
    &-title {
      padding-top: 50px;
      padding-bottom: 30px;
    }
  }
}
.mobile {
  font-weight: 400 !important;
}
.control {
  padding-bottom: 80px;
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-bottom: 25px;
  }
  p {
    @include fontStyle(Roboto, normal, 300, 20px, 27px, #373737);
    a {
      color: #c600dd;
    }
  }
  @include mobile() {
    padding-bottom: 1px;
    &-title {
      font-size: 14px;
      line-height: 15px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-weight: 500;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: none;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      a {
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .mobile {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  @include tablet() {
    padding-bottom: 1px;
    &-title {
      font-size: 14px;
      line-height: 15px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-weight: 500;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform: none;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      a {
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .mobile {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  @include tablet-1024() {
    padding-bottom: 30px;
    &-title {
      font-size: 16px;
      line-height: 19px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.form {
  padding-bottom: 70px;
  background: url(../assets/images/girlBig.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 150px;
  background-color: #efefef;
  padding-top: 75px;
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding-bottom: 30px;
  }
  &-check {
    display: flex;
    align-items: center;
    &__auto,
    &__anon {
      align-items: center;
    }
    @include fontStyle(Roboto, normal, 300, 18px, 29px, #373737);
    label {
      padding-right: 30px;
      margin-bottom: 0;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + *::before {
      content: "";
      display: inline-block;
      vertical-align: center;
      width: 17px;
      height: 17px;
      margin-right: 0.3rem;
      border-radius: 50%;
      border-style: solid;
      border-width: 2px;
      border-color: #ffffff;
      background: #ffffff;
      @include mobile() {
        width: 10px;
        height: 10px;
      }
      @include tablet() {
        width: 10px;
        height: 10px;
      }
      @include tablet-1024() {
        width: 12px;
        height: 12px;
      }
      @include desktop() {
        width: 14px;
        height: 14px;
      }
    }
    input[type="radio"]:checked + *::before {
      background: #c600dd;
      border-color: #ffffff;
    }
  }
  &-input {
    margin: 30px 0;
    input {
      width: 304px;
      height: 70px;
      background: #ffffff;
      backdrop-filter: blur(15px);
      border-radius: 15px;
      border: none;
      @include fontStyle(Roboto, normal, 300, 18px, 29px, #373737);
      padding-left: 15px;
      margin-right: 30px;
    }
  }
  &-text {
    padding-bottom: 35px;
    textarea {
      width: 980px;
      max-height: 200px;
      background: #ffffff;
      backdrop-filter: blur(15px);
      border-radius: 15px;
      border: none;
      @include fontStyle(Roboto, normal, 300, 18px, 29px, #959595);
      padding-left: 20px;
      padding-top: 20px;
    }
  }
  &-btn {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 70px;
      // background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
      background-color: #fff;
      border-radius: 15px;
      border: none;
      @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
      &:hover {
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        color: #ffffff;
      }
    }
  }
  @include mobile() {
    background: #efefef;
    padding-top: 10px;
    padding-bottom: 40px;
    &-title {
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 15px;
    }
    &-check {
      padding-left: 0;
      &__auto,
      &__anon {
        display: flex;
        align-items: center;
        label {
          display: flex;
          font-size: 12px;
          line-height: 14px;
          padding-left: 8px;
        }
      }
    }
    &-input {
      margin: 0;
      input {
        width: clamp(200px, 75%, 300px);
        height: 38px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
        margin-top: 15px;
        padding-left: 15px;
      }
    }
    &-text {
      margin-top: 15px;
      padding-bottom: 15px;
      textarea {
        width: 280px;
        height: 176px;
        font-size: 12px;
        line-height: 14px;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 3px;
      }
    }
    &-btn {
      button {
        width: clamp(120px, 15%, 160px);
        height: 34px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  @include tablet() {
    background: #efefef;
    padding-top: 10px;
    padding-bottom: 40px;
    &-title {
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 15px;
    }
    &-check {
      padding-left: 0;
      &__auto,
      &__anon {
        display: flex;
        align-items: center;
        label {
          display: flex;
          font-size: 12px;
          line-height: 14px;
          padding-left: 8px;
        }
      }
    }
    &-input {
      margin: 0;
      input {
        width: clamp(200px, 75%, 300px);
        height: 38px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
        margin-top: 15px;
        padding-left: 15px;
      }
    }
    &-text {
      margin-top: 15px;
      padding-bottom: 15px;
      textarea {
        width: 280px;
        height: 176px;
        font-size: 12px;
        line-height: 14px;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 3px;
      }
    }
    &-btn {
      button {
        width: clamp(120px, 15%, 160px);
        height: 34px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  @include tablet-1024() {
    background: #efefef;
    padding-top: 25px;
    padding-bottom: 25px;
    &-title {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 15px;
    }
    &-check {
      padding-left: 0;
      &__auto,
      &__anon {
        display: flex;
        align-items: center;
        label {
          font-size: 12px;
          line-height: 14px;
          padding-left: 8px;
        }
      }
    }
    &-input {
      margin: 0;
      input {
        width: 200px;
        height: 28px;
        border-radius: 3px;
        font-size: 12px;
        line-height: 14px;
        margin-top: 15px;
        padding-left: 5px;
      }
    }
    &-text {
      margin-top: 15px;
      padding-bottom: 15px;
      textarea {
        width: 470px;
        height: 170px;
        font-size: 12px;
        line-height: 14px;
        padding-top: 10px;
        padding-left: 10px;
      }
    }
    &-btn {
      button {
        width: 110px;
        height: 48px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  @include desktop() {
    background-size: 53%;
    padding-top: 40px;
    background-position-y: 200px;
    &-title {
      font-size: 22px;
      line-height: 25px;
    }
    &-check {
      font-size: 15px;
      line-height: 20px;
      padding-left: 0;
    }
    &-input {
      display: flex;
      input {
        width: 250px;
        height: 60px;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
    &-text {
      textarea {
        width: 780px;
      }
    }
    &-btn {
      button {
        width: 170px;
        height: 60px;
        border-radius: 10px;
        font-size: 15px;
      }
    }
  }
  @include desktop-max() {
    height: auto;
    &-input {
      display: flex;
    }
  }
}
</style>
