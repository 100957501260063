<template>
  <div id="mainPage">
    <head-main :firstblock="firstblock" />
    <div class="modal-button">
      <button class="modal-button-btn" @click="isOpen = true, this.$refs.myForm.resetForm()">
        <img src="@/assets/images/letter.png" alt="" />
      </button>
      <modal :open="isOpen" @close="isOpen = !isOpen">
        <get-consult />
        <Form ref="myForm" @submit="Send">
        <div class="modal-inputs">
          <div class="err-message">
            <Field
              v-model="GuestName"
              name="username"
              type="text"
              class="name"
              :placeholder="$t('placeholder.name')"
              :rules="requiredName"
              @keyup="onlyText()"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="username" />
            </div>
          </div>
          <div class="err-message">
            <Field
              v-mask="'+7(7##)-###-##-##'"
              v-model="GuestPhone"
              type="text"
              :placeholder="$t('placeholder.phone')"
              name="phone"
              :rules="requiredNum"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="phone" />
            </div>
          </div>
          <div class="err-message">
            <Field
              v-model="GuestEmail"
              type="email"
              :rules="validateEmail"
              name="email"
              class="mail mobile"
              id=""
              :placeholder="$t('placeholder.email')"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="email" />
            </div>
          </div>
          <div class="err-message">
            <Field
              v-model="organization_name"
              type="text"
              :placeholder="$t('placeholder.company')"
              name="organization"
              class="name"
              :rules="requiredNum"
            />
            <div :class="{ remove: IsRemove }" class="">
              <ErrorMessage name="organization" />
            </div>
          </div>
          <textarea
            v-model="guest_desc"
            :placeholder="$t('placeholder.com')"
          ></textarea>
        </div>
        <div class="modal-check">
          <p class="modal-check-title">{{ $t("modal.type") }}</p>
          <div class="check">
            <select v-model="product_name" name="" id="">
              <option v-for="pr in product" :key="pr">
                {{ pr.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-btn">
          <button
            type="submit"
            :class="{isDisabled : disabled}"
            @click="buttonClicked"
          >
            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
            {{ $t("buttons.send") }}
          </button>
        </div>
        <div class="modal-rule">
          <input type="checkbox" id="check22" v-model="checked" />
          <label for="check22"
            >{{ $t("modal.check1") }}
            <a href="/condition"
              >{{ $t("modal.check2") }}</a
            ></label
          >
        </div>
      </Form>
      </modal>
    </div>
    <product-main :thirdblock="thirdblock" />
    <choose-main :fourthblock="fourthblock" :fifthblock="fifthblock" />
    <discount />
    <service-main />
    <client-main :sixthblock="sixthblock" />
    <footer-main />
  </div>
</template>

<script>
import ChooseMain from "../components/ChooseMain.vue";
import FooterMain from "../components/FooterMain.vue";
import HeadMain from "../components/HeadMain.vue";
import ProductMain from "../components/ProductMain.vue";
import ServiceMain from "../components/ServiceMain.vue";
import ClientMain from "../components/ClientMain.vue";
import Discount from "../components/Discount.vue";
import axios from "axios";
import { ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import modal from "@/components/windows/modalConst.vue";
import getConsult from "@/components/getConsult.vue";
import { Buffer, resolveObjectURL } from "buffer";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
// import loader from '@/components/loader/loader.vue'
// import HeadMobile from '../components/HeadMobile.vue'
export default {
  name: "MainPage",
  components: {
    HeadMain,
    ProductMain,
    FooterMain,
    ChooseMain,
    ServiceMain,
    ClientMain,
    Discount,
    modal,
    getConsult,
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      disabled: false,
      firstblock: [],
      thirdblock: [],
      fourthblock: [],
      fifthblock: [],
      sixthblock: [],
      tenblock: [],
      organization_name: null,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      checked: false,
      guest_desc: null,
      product: null,
      IsRemove: false,
      form_name: "consultant",
      product: [
        { name: this.$t("product.med") },
        { name: this.$t("product.kasko") },
        { name: this.$t("product.cargo") },
        { name: this.$t("product.property") },
        { name: this.$t("product.gpo") },
        { name: this.$t("product.law") },
        { name: this.$t("product.ns") },
        { name: this.$t("product.other") },
      ],
    };
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  created() {
    axios
      .get("V1/main-page")
      .then((response) => {
        this.firstblock = response.data;
        this.thirdblock = response.data.third;
        this.fourthblock = response.data.fourth;
        this.fifthblock = response.data.fifth;
        this.sixthblock = response.data.sixth;
        this.tenblock = response.data.tenth;
        // this.stateMapData = response.data.data[0]
        // this.stateMapAddress = response.data.data[0].translate.ru
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    validateEmail(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return this.$t("placeholder.reqEmail");
      }
      //All is good
      return true;
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    getAll() {
      axios
        .get("V1/main-page")
        .then((response) => {
          // console.log(response.data.first);
          this.firstblock = response.data.first;
          // this.stateMapData = response.data.data[0]
          // this.stateMapAddress = response.data.data[0].translate.ru
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "consultant",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          // description: "mainPage",
          // product_name: 'Другое',
          description: this.guest_desc,
          product_name: this.product_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = true;
              setTimeout(() => (this.loading = !true), 3000);
              toaster.success(this.$t('notification.success'));
              resetForm();
              setTimeout(()=> (this.isOpen = false),3500);
            } else {
              toaster.error(this.$t("notification.reqProduct"));
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree')
        );
      }
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestEmail = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.product_name = "";
      this.checked = false;
      this.IsRemove = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 426px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 426px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-mid {
  @media (min-width: 1440px) and (max-width: 1620px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.isDisabled {
  opacity: 0.2;
  backdrop-filter: sepia(90%);
}
.err-message {
  display: block;
  span {
    @include fontStyle(Roboto, normal, 400, 16px, 20px, red);
    padding-top: 5px;
  }
  @include tablet-1024() {
    span {
      font-size: 8px;
      line-height: 12px;
    }
  }
  @include desktop() {
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.modal-button {
  width: 100px;
  &-btn {
    z-index: 111;
    bottom: 10%;
    right: 15px;
    position: fixed;
    overflow-x: hidden;
    cursor: pointer;
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 50%;
    background-color: #8948b1;
    img {
      width: 50px;
    }
    background: rgb(238, 161, 184);
    // background: rgb(204, 38, 38);
    box-shadow: 0 0 0 0 rgb(255, 144, 113);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @include mobile() {
    &-btn {
      width: 75px;
      height: 75px;
      img {
        width: 30px;
      }
    }
  }
  @include tablet() {
    &-btn {
      width: 75px;
      height: 75px;
      img {
        width: 30px;
      }
    }
  }
}
@keyframes pulse {
  0% {
    /* opacity: 0.5; */
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(221, 68, 68, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(229, 85, 85, 0);
  }
  100% {
    /* opacity: 0; */
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(211, 31, 31, 0);
  }
}
#mainPage {
  overflow: hidden;
}
</style>
>
