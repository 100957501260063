<template>
  <div class="ratingSlide" className="{styles.SliderWrapper}">
    <div class="container">
      <div class="rating_title">{{ $t("buttons.rating") }}</div>
      <swiper
        :spaceBetween="30"
        :centeredSlides="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :pagination="{
          clickable: true,
        }"
        :navigation="false"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="rt in ratings" :key="rt">
          <div class="rating-top__box">
            <div class="rating-top__box-img">
              <img :src="cdn + rt.icon" alt="" />
            </div>
            <p v-html="rt.description"></p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
// :autoplay="{
//             delay: 2500,
//             disableOnInteraction: false,
//             }"
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";
import { mapState } from "vuex";
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      ratings: [],
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/ratings")
      .then((response) => {
        this.ratings = response.data.data;
        // console.log(response)
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 767px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 767px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.container {
  padding-bottom: 30px;
}
// .sliderWrapper {
//     :global(.swiper-pagination) {
//         bottom: -5px !important;
//     }
// }
.swiper {
  padding-top: 20px;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
}
.ratingSlide {
  @include tablet-1024() {
    display: none;
  }
  @include desktop() {
    display: none;
  }
  @include desktop-max() {
    display: none;
  }
}
.rating-bottom__box,
.rating-top__box {
  margin: 0 auto;
  &-img {
    height: 100px;
    display: flex;
    align-items: center;
  }
  img {
    max-width: 240px;
    min-width: 180px;
    width: 100%;
    min-height: 50px;
    max-height: 100px;
  }
  p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 0;
  }
}
.rating_title {
  text-align: center;
  padding-top: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 119.69%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #373737;
  @include mobile() {
    padding-top: 30px;
    text-align: center;
  }
}
.swiper-slide {
  height: 110px;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  object-fit: contain;
  margin: 0 auto;
}
</style>
>
