<template>
    <about-us-header/>
    <about-back/>
    <about-buttons/>
    <div class="acc">
        <div class="container">
            <div class="acc-card">
                <input id="check00" type="checkbox" name="menu" />
                <label for="check00">{{ $t("documents.rule1") }}</label>
                <ul class="submenu" v-for="rl in rule" :key="rl">
                    <a :href="cdn + rl.doc"><img src="@/assets/images/download.svg" alt=""><p v-html="rl.title"></p></a>
                </ul>
            </div>
            <div class="acc-card">
                <input id="check01" type="checkbox" name="menu" />
                <label for="check01">{{ $t("documents.rule2") }}</label>
                <ul class="submenu" v-for="rl in rule1" :key="rl">
                    <a :href="cdn + rl.doc"><img src="@/assets/images/download.svg" alt=""><p v-html="rl.title"></p></a>
                </ul>
            </div>
            <div class="acc-card">
                <input id="check02" type="checkbox" name="menu" />
                <label for="check02">{{ $t("documents.rule3") }} </label>
                <ul class="submenu" v-for="rl in rule2" :key="rl">
                    <a :href="cdn + rl.doc"><img src="@/assets/images/download.svg" alt=""><p v-html="rl.title"></p></a>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import aboutUsHeader from '../components/aboutUsHeader.vue';
import AboutBack from '../components/AboutBack.vue';
import aboutButtons from '../components/aboutButtons.vue';
import axios from 'axios';
import {mapState} from 'vuex';

export default {
    name: 'financeMobile',
    components: {
        aboutUsHeader,AboutBack,aboutButtons
    },
    data() {
        return {
            rule: [],
            rule1: [],
            rule2: [],
        }
    },
    created() {
        axios.get('V1/constdoc')
        .then(response => {
            this.rule = response.data.mandotary
            this.rule1 = response.data.voluntary
            this.rule2 = response.data.other
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    input, ul.submenu {
        display: none;
    }

    /*position the label*/
    label {
        position: relative;
        display: block;
        cursor: pointer;
        @include fontStyle(Roboto, normal, 500, 18px, 22px, #959595);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
            margin-left: 10px;
            padding-bottom: 5px;
        }
        @include tablet-1024() {
            font-size: 14px;
            line-height: 16px;
        }
    }
    p {
        margin-bottom: 0 !important;
    }
    /*show the submenu when input is checked*/
    input:checked~ul.submenu {
        display: block;
        transition: all 0.4s linear;
    }
    .submenu {
        margin-bottom: 0;
        p {
            margin-bottom: 0;
        }
    }
    .submenu a {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 24px 0px 24px 20px;
        background-color: #fff;
        margin-top: 1px;
        img {
            width: 22px;
        }
        p {
            @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
            margin-bottom: 0 !important;
            padding-left: 24px;
            &:hover {
                font-weight: 600;
            }
            p {
                margin-bottom: 0 !important;
            }
        }
        @include tablet-1024() {
            padding: 10px 0px 10px 6px;
            p {
                font-size: 13px;
                line-height: 16px;
                padding-left: 8px;
            }
        }
    }
    .container {
        padding-left: 0;
    }
    .acc {
        padding: 80px 0;
        background: #EFEFEF;
        &-card {
            margin-bottom: 30px;
        }
        @include mobile() {
            padding: 25px 0;
        }
    }
</style>