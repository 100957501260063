<template>
    <div class="job animate__animated animate__fadeIn" style="animation-duration: 3s;">
        <div class="container">
            <div class="job-title">
                <!-- <span>centras insurance -</span> <br> -->
                <p class="job-title-p" v-html="jobTitle"></p>
                <!-- <p class="job-title-p">Одна из крупнейших страховых <br> компаний Казахстана с 1997 года</p> -->
            </div>
            <div class="job-card">
                <div class="job-card__items" v-html="subTitle1"></div>
                <div class="job-card__items" v-html="subTitle2"></div>
                <div class="job-card__items" v-html="subTitle3"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            jobTitle: [],
            subTitle1: [],
            subTitle2: [],
            subTitle3: [],
        }
    },
    created() { 
        axios.get('V1/vacancy-info')
        .then(response => {
            this.jobTitle = response.data.data.first_block.big_title
            this.subTitle1 = response.data.data.first_block.descriptions[0].desc
            this.subTitle2 = response.data.data.first_block.descriptions[1].desc
            this.subTitle3 = response.data.data.first_block.descriptions[2].desc
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 425px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1025px) and (max-width: 1440px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1440px) and (max-width: 2560px) {
            @content;
        }
    }
    .job {
        background: url(../assets/images/job.png);
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 90px;
        &-title {
            @include fontStyle(Roboto, normal, 500, 38px, 52px, #373737);
            letter-spacing: 0.03em;
            text-transform: uppercase;
            padding-top: 80px;
            padding-bottom: 40px;
            span {
                background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                font-size: 48px;
                line-height: 72px;
            }
        }
        &-card {
            display: flex;
            &__items {
                background: rgba(255, 255, 255, 0.2);
                border: 1px solid rgba(255, 255, 255, 0.3);
                box-sizing: border-box;
                border-radius: 15px;
                padding: 50px 50px 50px 25px;
                @include fontStyle(Roboto, normal, 100, 22px, 26px, #373737);
                margin-right: 30px;
            }
        }
        @include mobile() {
            background-size: contain;
            padding-bottom: 10px;
            background-color: #EFEFEF;
            &-title {
                font-size: 14px;
                line-height: 17px;
                width: 60%;
                padding-top: 25px;
                padding-bottom: 10px;
                span {
                    font-size: 12px;
                    line-height: 17px;
                }
            }
            &-card {
                justify-content: center;
                &__items {
                    border: none;
                    background: transparent;
                    margin-right: 0;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    padding: 40px 10px 0px 10px;
                }
            }
        }
        @include tablet() {
            background-size: contain;
            padding-bottom: 10px;
            background-color: #EFEFEF;
            &-title {
                font-size: 14px;
                line-height: 17px;
                width: 65%;
                padding-top: 30px;
                padding-bottom: 30px;
                span {
                    font-size: 12px;
                    line-height: 17px;
                }
            }
            &-card {
                justify-content: center;
                &__items {
                    border: none;
                    background: transparent;
                    margin-right: 0;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 400;
                    padding: 20px 10px;
                }
            }
        }
        @include tablet-1024() {
            padding-bottom: 20px;
            margin-top: 0px;
            &-title {
                font-size: 18px;
                line-height: 22px;
                padding-top: 30px;
                padding-bottom: 30px;
                span {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            &-card {
                &__items {
                    border-radius: 8px;
                    padding: 15px 10px;
                    font-size: 11px;
                    line-height: 13px;
                }
            }
        }
        @include desktop() {
            &-title {
                font-size: 26px;
                line-height: 32px;
                span {
                    font-size: 26px;
                    line-height: 32px;
                }
            }
            &-card {
                &__items {
                    padding: 30px 30px 30px 20px;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
</style>