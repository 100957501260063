<template>
  <about-us-header />
  <about-back />
  <about-buttons />
  <div class="tarifs d_none">
    <div class="container">
      <div class="tarifs-box">
        <a href="/tarif.xlsx" download
          ><img
            class="download-img"
            src="../assets/images/download.svg"
            alt=""
          />
          <p>{{ $t("documents.tar") }} АО "СК "Сентрас Иншуранс"</p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "@/components/aboutUsHeader.vue";
import aboutBack from "@/components/AboutBack.vue";
import aboutButtons from "@/components/aboutButtons.vue";

export default {
  components: { aboutUsHeader, aboutBack, aboutButtons },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0;
}
.tarifs {
  background: #efefef;
  padding-bottom: 15px;
  &-box {
    margin-bottom: 20px;
    padding-top: 15px;
    a {
      display: flex;
      align-items: center;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #373737;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}
</style>
