<template>
  <div>
    <another-site />
    <div class="container">
      <div class="policy m_none">
        <div class="policy-box">
          <div class="policy-cards" v-for="pl in policy.slice(1, 4)" :key="pl">
            <a :href="pl.link">
              <img :src="cdn + pl.icon" alt="" />
              <p class="policy-cards__title" v-html="pl.title"></p>
              <div class="policy-cards__btn m_none">
                <a :href="pl.link"
                  ><button>{{ $t("buttons.go") }}</button></a
                >
              </div>
            </a>
          </div>
        </div>
        <div class="policy-box">
          <div class="policy-cards" v-for="pl in policy.slice(4, 7)" :key="pl">
            <a :href="pl.link">
              <img :src="cdn + pl.icon" alt="" />
              <p class="policy-cards__title" v-html="pl.title"></p>
              <div class="policy-cards__btn m_none">
                <a :href="pl.link"
                  ><button>{{ $t("buttons.go") }}</button></a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="policy d_none">
        <div class="policy-box">
          <div class="policy-cards" v-for="pl in policy.slice(1, 7)" :key="pl">
            <a :href="pl.link">
              <img :src="cdn + pl.icon" alt="" />
              <p class="policy-cards__title" v-html="pl.title"></p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="text-policy">
      <div class="container">
        <div class="text-policy__par" v-html="policy[7].title"></div>
      </div>
    </div>
  </div>
</template>

<script>
import anotherSite from "../components/anotherSite.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { anotherSite },
  data() {
    return {
      policy: null,
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/service_payments")
      .then((response) => {
        this.policy = response.data.data;
        // console.log(response)
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 760px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 760px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-mid {
  @media (min-width: 1441px) and (max-width: 1619px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.policy {
  &-box {
    display: flex;
    justify-content: space-between;
  }
  &-cards {
    width: 400px;
    height: 450px;
    background: #efefef;
    border-radius: 15px;
    margin-top: 60px;
    img {
      max-width: 300px;
      max-height: 280px;
      width: 100%;
      margin: auto;
      display: flex;
    }
    &__title {
      @include fontStyle(Roboto, normal, 500, 24px, 28px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-align: center;
    }
    &__btn {
      background: #ffffff;
      border-radius: 15px;
      width: 132px;
      height: 69px;
      margin: auto;
      button {
        background: #ffffff;
        border-radius: 15px;
        width: 132px;
        height: 69px;
        border: none;
        @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);
        a {
          color: #373737;
          text-decoration: none;
        }
      }
      &:hover {
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        color: #ffffff;
        button {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #ffffff;
          background-clip: text;
          a {
            color: #ffffff;
          }
        }
      }
    }
    &:last-child {
      img {
        width: 280px;
      }
    }
    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
    }
  }
  @include mobile() {
    &-box {
      margin-top: 15px;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
    }
    &-cards {
      width: 152px;
      height: 152px;
      margin: 0 auto;
      margin-top: 20px;
      border-radius: 6px;
      // margin-left: 15px;
      &__title {
        font-size: 12px;
        line-height: 14px;
        padding-top: 15px;
      }
      img {
        max-width: 90px;
        padding-top: 20px;
      }
      &__btn {
        width: 100px;
        height: 40px;
        border-radius: 5px;
        button {
          width: 100px;
          height: 40px;
          border-radius: 5px;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
  @include tablet() {
    &-box {
      margin-top: 15px;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
    }
    &-cards {
      width: 152px;
      height: 152px;
      margin: 0 auto;
      margin-top: 20px;
      border-radius: 6px;
      // margin-left: 15px;
      &__title {
        font-size: 12px;
        line-height: 14px;
        padding-top: 15px;
      }
      img {
        max-width: 90px;
        padding-top: 20px;
      }
      &__btn {
        width: 100px;
        height: 40px;
        border-radius: 5px;
        button {
          width: 100px;
          height: 40px;
          border-radius: 5px;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
  @include tablet-1024() {
    &-box {
      flex-wrap: wrap;
    }
    &-cards {
      width: 180px;
      height: 200px;
      margin: 0 auto;
      margin-top: 20px;
      &__title {
        font-size: 10px;
        line-height: 12px;
        padding-top: 3px;
      }
      img {
        max-width: 100px;
        padding-top: 10px;
      }
      &__btn {
        width: 100px;
        height: 40px;
        border-radius: 5px;
        button {
          width: 100px;
          height: 40px;
          border-radius: 5px;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
  @include desktop() {
    &-cards {
      width: 260px;
      height: 350px;
      &__title {
        font-size: 20px;
        line-height: 24px;
        height: 40px;
      }
      img {
        max-width: 200px;
        max-height: 200px;
        padding-top: 10px;
      }
    }
  }
  @include desktop-mid() {
    &-cards {
      width: 350px;
      height: 400px;
      &__title {
        font-size: 22px;
        line-height: 24px;
        height: 55px;
      }
      img {
        max-width: 220px;
        max-height: 240px;
        padding-top: 10px;
        margin-bottom: 15px;
      }
    }
  }
  @include desktop-max() {
    &-cards {
      padding: 15px;
      img {
        // height: 300px;
        max-width: 272px;
        max-height: 260px;
        margin-bottom: 7px;
      }
      &__title {
        height: 55px;
      }
    }
  }
}
.text-policy {
  background: #efefef;
  margin-top: 100px;
  &__par {
    @include fontStyle(Roboto, normal, 500, 42px, 58px, transparent);
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    padding: 50px 0;
    text-transform: uppercase;
  }
  @include mobile() {
    margin-top: 35px;
    &__par {
      padding: 30px 0 15px 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
  @include tablet() {
    margin-top: 35px;
    &__par {
      padding: 30px 0 20px 0;
      font-size: 22px;
      line-height: 24px;
    }
  }
  @include tablet-1024() {
    margin-top: 50px;
    &__par {
      font-size: 22px;
      line-height: 24px;
    }
  }
  @include desktop() {
    &__par {
      font-size: 34px;
      line-height: 38px;
    }
  }
}
</style>
