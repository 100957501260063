<template>
  <div class="container">
    <div class="video-card">
      <div class="card" v-for="v in visibleCompanies" :key="v.id" :id="v.id">
        <iframe
          width="333"
          height="183"
          :src="v.video_url"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="video-btn">
      <button
        class="video-add"
        @click="companiesVisible += step"
        v-if="companiesVisible < video.length"
      >
        {{ $t("buttons.show") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      IsShow: false,
      video: [],
      companiesVisible: 3,
      step: 3,
    };
  },
  created() {
    axios
      .get("V1/company-videos")
      .then((response) => {
        this.video = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
    IsShowButton(i) {
      this.IsShow = i;
    },
  },
  computed: {
    ...mapState(["cdn"]),
    visibleCompanies() {
      return this.video.slice(0, this.companiesVisible);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
.display_none {
  display: none;
}
.activeButton {
  display: block;
  transition: all 2s;
}
.video {
  &-card {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      width: 30%;
      margin-bottom: 40px;
      border: none;
      iframe {
        max-width: 400px;
        width: 100%;
        height: 230px;
      }
    }
  }
  &-add {
    @include fontStyle(Roboto, normal, 500, 18px, 25px, #ffffff);
    border: none;
    background: linear-gradient(
      90deg,
      #ff4466 0%,
      #be31ce 49.97%,
      #3641ff 100%
    );
    border-radius: 15px;
    width: 230px;
    height: 70px;
  }
  &-btn {
    display: flex;
    justify-content: center;
  }
}
</style>
>
