<template>
    <div class="digital-back">
        <about-us-header/>
        <div class="digital animate__animated animate__fadeIn" style="animation-duration: 3s;">
            <div class="container">
                <p class="digital-title" v-html="digit1.big_title"></p>
                <p class="digital-par m_none" v-html="digit1.big_description">
                </p>
                <p class="digital-mobile d_none" v-html="digit1.big_description"></p>
            </div>
        </div>
        <div class="projects animate__animated animate__fadeIn" style="animation-duration: 3s;">
            <div class="container">
                <div class="projects-card" v-for="dg in digit" :key="dg">
                    <div class="img">
                        <img :src="cdn + dg.icon" alt="" class="projects-card__img">
                    </div>
                    <div class="projects-card__inp">
                        <input type="checkbox" class="read-more-checker" :id="dg.id" />
                        <div class="projects-card__par">
                            <p class="projects-card__par__title" v-html="dg.title"></p>
                            <p class="projects-card__par__sub" v-html="dg.desc"></p>
                            <div class="bottom"></div>
                        </div>
                        <label :for="dg.id" class="read-more-button"></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import aboutUsHeader from '../components/aboutUsHeader.vue'
import axios from 'axios'
import { mapState } from 'vuex'
export default {
    components: {aboutUsHeader},
    data() {
        return {
            digit: [],
            digit1: [],
        }
    },
    created() { 
        axios.get('V1/digital-projects')
        .then(response => {
            this.digit = response.data.data
            this.digit1 = response.data.first
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
}
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin mobile {
        @media(min-width: 320px) and (max-width: 426px) {
            @content;
        }
    }
    @mixin tablet {
        @media(min-width: 427px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 769px) and (max-width: 1024px) {
            @content;
        }
    }
    @mixin desktop {
        @media(min-width: 1024px) and (max-width: 1620px) {
            @content;
        }
    }
    @mixin desktop-max {
        @media(min-width: 1620px) and (max-width: 2560px) {
            @content;
        }
    }
    p {
        ul {
        list-style: none !important;
    }
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    .digital {
        padding-top: 85px;
        padding-bottom: 80px;
        background: url(../assets/images/digital.png);
        // background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &-back {
            @include mobile() {
                background-color: #EFEFEF;
            }
        }
        &-title {
            @include fontStyle(Roboto, normal, 500, 42px, 58px, transparent);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background: linear-gradient(90deg, #FF143F 0%, #C600DD 18.97%, #1B27FF 38%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
        &-par, &-mobile {
            @include fontStyle(Roboto, normal, 400, 22px, 26px, #373737);
            width: 60%;
        }
        @include mobile() {
            background-image: url('../assets/images/digital-back.png');
            background-size: contain;
            padding-bottom: 0;
            &-title {
                font-size: 15px;
                line-height: 22px;
                margin-top: -30px;
                width: 40%;
                font-weight: 600;
                background: #373737;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &-mobile {
                font-size: 14px;
                line-height: 15px;
                margin-top: 25%;
                background-color: #EFEFEF;
                width: 100%;
            }
        }
        @include tablet() {
            background-image: url('../assets/images/digital-back.png');
            background-size: cover;
            padding-bottom: 0;
            &-title {
                font-size: 22px;
                line-height: 22px;
                margin-top: -60px;
                background: #373737;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &-par, &-mobile {
                font-size: 14px;
                line-height: 15px;
                padding-top: 15px;
                margin-bottom: 0;
                padding-bottom: 20px;
                width: 61%;
            }
        }
        @include tablet-1024() {
            padding-top: 35px;
            &-title {
                font-size: 30px;
            }
            &-par {
                font-size: 12px;
                line-height: 15px;
                width: 80%;
            }
        }
        // @include desktop() {
            
        // }
    }
    .projects {
        background: #EFEFEF;
        padding: 80px 0;
        align-items: center;
        &-card {
            margin-bottom: 55px;
            display: flex;
            &__par {
                margin-left: 50px;
                &:nth-child(3) {
                    margin-left: 30px;
                }
                &__title {
                    @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    width: 160px;
                    &:hover {
                        background: linear-gradient(90deg, #FF143F 0%, #C600DD 14.97%, #1B27FF 44%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
                &__sub {
                    @include fontStyle(Roboto, normal, 300, 20px, 27px, #373737);
                }
            }
            .img {
                img {
                    width: 144px;
                display: block;
                }
            }
            &__inp {
                .read-more-checker {
                    opacity: 0;
                    position: absolute;
                }
                .read-more-checker:checked ~ .projects-card__par .bottom {
                    opacity: 0;
                    transition: .3s;
                }
            }
        }

        //mobile dropdown text

        @include mobile() {
            padding: 20px 0;
            &-card {
                margin-bottom: 25px;
                align-items: flex-start;
                // max-width: 300px;
                width: 100%;
                .img {
                    width: 13%;
                    img {
                        // height: 98px;
                        // width: 17%;
                        margin-top: 30px;
                        width: 47px;
                        display: block;
                    }
                }
                &__inp {
                    max-width: 300px;
                    width: 100%;
                    .read-more-checker:checked ~ .projects-card__par {
                        max-height: none;
                    }
                    .read-more-checker ~ .read-more-button:before {
                        content: 'Подробнее';
                        margin-left: 15px;
                        font-size: 10px;
                        font-family: Roboto;
                        font-size: 12px;
                    }
                    .read-more-checker:checked ~ .read-more-button:before {
                        content: 'Меньше';
                        margin-left: 15px;
                        font-size: 10px;
                        font-family: Roboto;
                        font-size: 12px;
                    }
                    .read-more-button {
                        cursor: pointer;
                        display: inline-block;
                        // padding: 10px;
                        background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
                &__par {
                    max-height: 100px;
                    overflow: hidden;
                    position: relative;
                    margin-left: 15px;
                    &__title {
                        font-size: 16px;
                        line-height: 13px;
                    }
                    &__sub {
                        font-size: 13px;
                        line-height: 14px;
                    }
                    .bottom {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 60px;
                        opacity: 1;
                        transition: 3000ms;
                    }
                }
            }
        }
        @include tablet() {
            padding: 20px 0;
            &-card {
                margin-bottom: 5px;
                align-items: flex-start;
                &__img {
                    width: 15%;
                    max-width: 70px;
                }
                &__par {
                    margin-left: 20px;
                    &__title {
                        font-size: 16px;
                        line-height: 13px;
                    }
                    &__sub {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }
        @include tablet-1024() {
            padding: 20px 0;
            &-card {
                margin-bottom: 25px;
                align-items: center;
                &__img {
                    width: 15%;
                    max-width: 70px;
                }
                &__par {
                    &__title {
                        font-size: 16px;
                        line-height: 13px;
                    }
                    &__sub {
                        font-size: 10px;
                        line-height: 12px;
                    }
                }
            }
        }
        @include desktop() {
            &-card {
                &__img {
                    // width: 15%;
                    width: 116px;
                }
            }
        }
        @include desktop-max() {
            &-card {
                &__img {
                    // width: 15%;
                    width: 116px;
                }
                &__par {
                    width: 85%;
                }
            }
        }
    }
</style>