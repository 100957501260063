<template>
    <div id="app">
    <hr class="line">
    <swiper
    :slidesPerView="4"
    :spaceBetween="70"
    :centeredSlides="true"
    :pagination="{
        clickable: false,
    }"
    :modules="[Thumbs]" :thumbs="{ swiper: thumbsSwiper }"
    class="mySwiper">
        <swiper-slide class="slide" v-for="hs in history" :key="hs">
            <h2 class="slide-year" v-html="hs.year"></h2>
            <button class="slide-btn">
                <span></span>
            </button>
        </swiper-slide>
    </swiper>
    <swiper
    :pagination="{
        dynamicBullets: true,
    }"
    :modules="[Thumbs]"
    watch-slides-progress
    @swiper="setThumbsSwiper"
    class="mySwiper2">
    <swiper-slide v-for="h in history" :key="h">
        <div class="team">
            <div class="team-right">
                <p v-html="h.data[0].title"></p>
                <!-- <ul>
                    <li v-html="h.data[0].title"></li>
                </ul> -->
            </div>
        </div>
    </swiper-slide>
</swiper>
</div>
</template>

<script scoped>
// Import Swiper Vue.js components
import { ref } from 'vue';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import axios from 'axios';
import {mapState} from 'vuex'
export default {
    name: "App",
    components: {
        Pagination,
        Swiper,
        SwiperSlide,
    },
    setup() {
        const thumbsSwiper = ref(null);
        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };
        return {
            Thumbs,
            thumbsSwiper,
            setThumbsSwiper,
        };
        },
    
    data() {
        return {
            showLoader: false,
            items: [],
            page: 2,
            totalPages: 5,
            totalRecords: 10,
            recordsPerPage: 10,
            activeBtn:'btn1',
            history: [],
        };
    },
    created() { 
        axios.get('V1/histories')
        .then(response => {
            this.history = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
    methods: {
        onPageChange(page) {
        this.page = page;
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
    font-family: $family;
    font-style: normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $lheight;
    color: $color;
}
@mixin mobile {
    @media(min-width: 320px) and (max-width: 425px) {
        @content;
    }
}

@mixin tablet {
    @media(min-width: 425px) and (max-width: 767px) {
        @content;
    }
}

@mixin tablet-1024 {
    @media(min-width: 767px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media(min-width: 1024px) and (max-width: 1620px) {
        @content;
    }
}

@mixin desktop-max {
    @media(min-width: 1621px) and (max-width: 2560px) {
        @content;
    }
}
.swiper-wrapper {
    height: auto !important;
}
#app {
    margin-top: 90px;
}
.line {
    height: 2px;
    background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
    opacity: 1;
    margin-bottom: -84px;
}
.mySwiper2 {
    width: 80%;
    max-width: 1320px;
    margin: 0 auto;
}
.container {
    padding-left: 0;
    padding-right: 0;
}

.team {
    &-right {
        // text-align: center;
        ul {
            li {
                @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
                padding-bottom: 10px;
                text-align: start;
                margin-left: 26px;
                list-style: none;
                &::before {
                    content: "\2022";
                    color: #959595;
                    font-weight: bold;
                    display: inline-block;
                    width: 7px;
                    margin-right: 5px;
                    font-size: 12px;
                }
                p {
                    margin-top: -10px;

                }
            }
        }
        p {
            @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
            text-align: center;
        }
    }
    @include tablet() {
        &-right {
            p {
                font-size: 15px;
                line-height: 17px;
            }
        }
    }
}
.slide {
    &-year {
        @include fontStyle(Roboto, normal, 400, 14px, 17px, #959595);
    }
}
.slide-btn {
    border: none;
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    border-radius: 50%;
    background: rgba(225, 0, 135, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        width: 6px;
        height: 6px;
        background: #E20A90;
        border-radius: 50%;
    }
    p {
        @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
    }
}
.mySwiper{
    overflow: hidden;
    height: 130px;
    padding-top: 50px;
    .swiper-slide-active{
        margin-top: -35px;
        .slide {
            &-year {
                font-size: 45px;
                line-height: 47px;
                margin-left: -30px;
            }
        }
        .slide-btn {
            width: 26px;
            height: 26px;
            span {
                width: 12px;
                height: 12px;
            }
        }
    }
}
.active{
    margin-top: -30px;
}
</style>
