<template>
    <div class="kasko-slider">
        <swiper
            :spaceBetween="30"
            :centeredSlides="true"
            :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
            }"
            :pagination="{
            clickable: true,
            }"
            :navigation="false"
            :modules="modules"
            class="mySwiper">
            <swiper-slide v-for="j in job" :key="j">
                <div class="kasko-card">
                    <img :src="cdn + j.icon" alt="">
                    <p v-html="j.description"></p>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";
import { mapState } from "vuex";
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            job: [],
        }
    },
    setup() {
        return {
        modules: [Autoplay, Pagination, Navigation],
        };
    },
    created() {
        axios.get('V1/vacancies')
        .then(response => {
            this.job = response.data.info.third_block.body
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed :{
        ...mapState(['cdn']),
    },
};
</script>

<style lang="scss" scoped>
    @mixin fontStyle ($family,$style,$weight, $size, $lheight, $color) {
        font-family: $family;
        font-style: normal;
        font-weight: $weight;
        font-size: $size;
        line-height: $lheight;
        color: $color;
    }
    @mixin tablet {
        @media(min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }
    @mixin tablet-1024 {
        @media(min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }
    .kasko-card {
        padding-bottom: 30px;
        p {
            @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
            background: linear-gradient(90deg, #FF143F 0%, #C600DD 49.97%, #1B27FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-align: center;
            width: 85%;
            margin: 0 auto;
        }
        img {
            min-width: 80px;
            max-width: 200px;
            min-height: 80px;
        }
        @include tablet() {
            p {
                font-size: 14px;
                line-height: 17px;
            }
        }
        @include tablet-1024() {
            p {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
</style>
