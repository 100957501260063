<template>
  <div>
    <head-bottom />
    <div class="ogpo">
      <div class="container">
        <p class="ogpo-title" v-html="gpo.title"></p>
        <p class="ogpo-sub" v-html="gpo.description"></p>
        <div class="ogpo-cards">
          <div
            class="ogpo-cards__box"
            v-html="gpo.mini_description_first"
          ></div>
          <div
            class="ogpo-cards__box"
            v-html="gpo.mini_description_second"
          ></div>
          <div
            class="ogpo-cards__box"
            v-html="gpo.mini_description_third"
          ></div>
        </div>
        <div class="ogpo-btn">
          <button @click="isOpen = true, this.$refs.myForm.resetForm()" id="click10">{{ gpo.button }}</button>
          <!-- <button @click="isOpen=true">Получить <span>индивидуальную</span> информацию</button> -->
        </div>
        <modal :open="isOpen" @close="isOpen = !isOpen">
          <get-discount />
          <Form ref="myForm" @submit="Send">
          <div class="modal-inputs">
            <div class="err-message">
              <Field
                v-model="GuestName"
                name="username"
                type="text"
                class="name"
                :placeholder="$t('placeholder.name')"
                :rules="requiredName"
                @keyup="onlyText()"
              />
              <div :class="{ remove: IsRemove }" class="">
                <ErrorMessage name="username" />
              </div>
            </div>
            <div class="err-message">
              <Field
                v-mask="'+7(7##)-###-##-##'"
                v-model="GuestPhone"
                type="text"
                :placeholder="$t('placeholder.phone')"
                name="phone"
                :rules="requiredNum"
              />
              <div :class="{ remove: IsRemove }" class="">
                <ErrorMessage name="phone" />
              </div>
            </div>
            <div class="err-message">
              <Field
                v-model="organization_name"
                type="text"
                :placeholder="$t('placeholder.company')"
                name="organization"
                class="name"
                :rules="requiredNum"
              />
              <div :class="{ remove: IsRemove }" class="">
                <ErrorMessage name="organization" />
              </div>
            </div>
            <textarea
              v-model="guest_desc"
              :placeholder="$t('placeholder.com')"
            ></textarea>
          </div>
          <div class="modal-btn">
            <button
              type="submit"
              :class="{isDisabled : disabled}"
              @click="buttonClicked"
            >
              <div
                v-if="loading"
                class="spinner-border spinner-border-sm"
              ></div>
              {{ $t("buttons.send") }}
            </button>
          </div>
          <div class="modal-rule">
            <input type="checkbox" id="check" v-model="checked" />
            <label for="check"
              >{{ $t("modal.check1") }}
              <a href="/condition">{{ $t("modal.check2") }}</a></label
            >
          </div>
        </Form>
        </modal>
      </div>
    </div>
    <div class="middle">
      <hr class="middle-line" />
      <div class="container">
        <div class="middle-box">
          <p class="middle-box__title" v-html="tabs[0].big_title"></p>
          <div class="ogpo-slider"><ogpo-slider /></div>
          <div class="middle-box__bottom">
            <div class="middle-box__bottom-1">
              <button
                v-for="t in tabs.slice(0, 3)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>

            <div class="middle-box__bottom-3">
              <button
                v-for="t in tabs.slice(3, 6)"
                :value="t"
                :key="t"
                @click="tabButton(t)"
                v-html="t.title"
              ></button>
            </div>
            <div class="middle-box__bottom-2" v-if="tabContent">
              <img :src="cdn + tabContent.icon" alt="" />
              <div class="par" v-html="tabContent.description"></div>
              <div class="btn">
                <button
                  id="click10"
                  @click="isOpen = true, this.$refs.myForm.resetForm()"
                  v-html="tabContent.button"
                ></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
            </div>
            <div class="middle-box__bottom-2" v-else>
              <img :src="cdn + tabs[0].icon" alt="" />
              <div class="par" v-html="tabs[0].description"></div>
              <div class="btn">
                <button @click="isOpen = true, this.$refs.myForm.resetForm()" v-html="tabs[0].button"></button>
                <!-- <button @click="isOpen=true">Получить подробную информацию</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="about-ogpo">
        <p class="about-ogpo__title" v-html="gpo3[0].big_title"></p>
        <div class="about-ogpo__card" v-for="(g, i) in gpo3" :key="g">
          <p class="about-ogpo__card__num" v-if="i < 10">0{{ i + 1 }}.</p>
          <p class="about-ogpo__card__num" v-else>{{ i + 1 }}.</p>
          <div class="about-ogpo__card__par">
            <span v-html="g.title"></span>
            <p v-html="g.description"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="range">
      <range />
    </div>
    <div class="sliderRange">
      <slider-range />
    </div>
    <div class="reverse1">
      <accordion />
      <discount :p_name="p_name" />
    </div>
    <SliderMain1 />
    <client-say />
    <div class="wrap">
      <slider-main-2 />
      <div class="d_none">
        <review-slide />
      </div>
    </div>
  </div>
</template>

<script>
import HeadBottom from "../components/HeadBottom.vue";
import accordion from "../components/accordion.vue";
import Discount from "../components/Discount.vue";
import SliderMain1 from "../components/SliderMain1-1.vue";
import SliderMain2 from "../components/SliderMain2.vue";
import range from "../components/range.vue";
import sliderRange from "../components/sliderRange.vue";
import ogpoSlider from "../components/box/ogpoSlider.vue";
import reviewSlide from "../components/box/reviewSlide.vue";
import { ref } from "vue";
import modal from "../components/windows/modal.vue";
import axios from "axios";
import { mapState } from "vuex";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Buffer, resolveObjectURL } from "buffer";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
import first from "../assets/images/cargo3.png";
import second from "../assets/images/ogpo2.png";
import third from "../assets/images/ogpo3.png";
import fourth from "../assets/images/hammer.png";
import fifth from "../assets/images/kasko2.png";
import sixth from "../assets/images/ogpo6.png";
import getDiscount from "@/components/getDiscount.vue";
import clientSay from "@/components/clientSay.vue";

export default {
  components: {
    HeadBottom,
    accordion,
    Discount,
    SliderMain1,
    SliderMain2,
    modal,
    range,
    sliderRange,
    ogpoSlider,
    reviewSlide,
    getDiscount,
    clientSay,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      loading: false,
      disabled: false,
      GuestName: null,
      GuestPhone: null,
      GuestEmail: null,
      product_name: "Обязательное страхование юр. лиц",
      tabContent: null,
      resp: [],
      tab: "",
      checked: false,
      organization_name: null,
      guest_desc: null,
      gpo: [],
      gpo2: [],
      gpo3: [],
      IsRemove: false,
      p_name: "Обязательное страхование юр. лиц",
      tabs: [
        {
          title: "ГПО владельцев транспортных средств",
          img: first,
          text: "Сохраним финансовую стабильность вашей компании в случае причинения вреда жизни, здоровью или имуществу других лиц  в результате возникновения гражданско-правовой ответственности при эксплуатации транспортного средства, как источника повышенной опасности",
        },
        {
          title: "ГПО перевозчика",
          img: second,
          text: "Перевозка пассажиров – ответственная задача, требующая высоких профессиональных навыков и огромного опыта. При наступлении непредвиденных происшествий при перевозке в виде причинения вреда жизни, здоровью или имуществу  пассажирам, вашей компании лучше быть застрахованной от финансовых потерь",
        },
        {
          title: "ГПО владельцев опасных объектов",
          img: third,
          text: "Защитим ваши интересы и компенсируем убытки, нанесенные третьим лицам в результате аварий на опасных производственных объектах",
        },
        {
          title: "ГПО нотариусов",
          img: fourth,
          text: "Мы обеспечим обязательную страховую защиту на случай предъявления третьими лицами претензий о возмещении причиненного им вреда в результате совершения нотариальных действий",
        },
        {
          title: "ГПО аудиторов",
          img: fifth,
          text: "Защитим вас от затрат по возмещению имущественного вреда, причиненного аудируемым субъектам при проведении аудита ",
        },
        {
          title: "Экологическое страхование",
          img: sixth,
          text: "Если деятельность вашей компании связана с опасностью для экологии – возникает риск нанесения вреда окружающей среде в результате ее аварийного загрязнения. Экологическое страхование поможет компании избежать непредвиденных расходов в случае аварии",
        },
      ],
    };
  },
  created() {
    axios
      .get("V1/top-products")
      .then((response) => {
        this.gpo = response.data.data[2].first_block;
        // this.gpo2 = response.data.data[3].second_block
        this.tabs = response.data.data[2].second_block;
        this.gpo3 = response.data.data[2].third_block;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    onlyText() {
      this.GuestName = this.GuestName.replace(
        /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
        ""
      );
    },
    buttonClicked() {
      this.disabled = !false;
      // alert("clicked!");
      setTimeout(() => (this.disabled = !true), 5000);
    },
    tabButton(t) {
      if (t == null) {
        // console.log("test", this.tabs[0]);
        this.tabs[0] = t;
      } else {
        this.tabContent = t;
        // console.log("tab", t);
      }
    },
    requiredName(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    requiredNum(value) {
      if (!value) {
        return this.$t("placeholder.req");
      }
      return true;
    },
    Send(values, {resetForm}) {
      console.log(values);
      if (this.checked === true) {
        const auth = new Buffer("Nomad:Qwerty123", "utf-8").toString("base64");
        this.form = {
          username: this.GuestName,
          form_name: "consultant",
          organization_name: this.organization_name,
          phone: this.GuestPhone,
          email: this.GuestEmail,
          description: this.guest_desc,
          product_name: this.product_name,
        };
        axios
          .post("https://bitrix24.cic.kz/api/crm/leads/create/", this.form, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
              Authorization: "Basic " + auth,
            },
          })
          .then((res) => {
            if (res.data.status == true) {
              this.loading = !false;
              setTimeout(() => (this.loading = !true), 20000);
              toaster.success(this.$t('notification.success'));
              resetForm()
              setTimeout(() => {
                this.isOpen = false;
              }, 500);
            } else {
              toaster.error(this.$t('notification.req'));
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(auth);
          });
      } else {
        toaster.error(
          this.$t('notification.dontagree')
        );
      }
    },
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
      this.GuestName = "";
      this.GuestPhone = "";
      this.organization_name = "";
      this.guest_desc = "";
      this.checked = false;
      this.IsRemove = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background: #ffffff;
}
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 426px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1023px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1023px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1620px) and (max-width: 2560px) {
    @content;
  }
}
.wrap {
  @include mobile() {
    padding-top: 15px;
  }
}
.container {
  @include mobile() {
    padding-left: 0;
    padding-right: 0;
  }
}
.ogpo {
  background: url(../assets/images/backOgpo.png);
  background-repeat: no-repeat;
  padding-bottom: 120px;
  &-slider {
    display: none;
    @include mobile() {
      display: block;
    }
    @include tablet() {
      display: block;
    }
    @include tablet-1024() {
      display: block;
    }
  }
  &-title {
    @include fontStyle(Roboto, normal, 600, 36px, 49px, #ffffff);
    letter-spacing: 0.015em;
    text-transform: uppercase;
    padding-top: 100px;
    padding-bottom: 30px;
  }
  &-sub {
    @include fontStyle(Roboto, normal, 500, 24px, 33px, #ffffff);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding-bottom: 43px;
  }
  &-cards {
    display: flex;
    &__box {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      backdrop-filter: blur(30px);
      border-radius: 15px;
      width: 200px;
      height: 160px;
      padding: 30px 0 30px 20px;
      margin-right: 28px;
      @include fontStyle(Roboto, normal, 400, 17px, 23px, #ffffff);
    }
  }
  &-btn {
    width: 405px;
    height: 70px;
    background: #ffffff;
    border-radius: 15px;
    margin-top: 50px;
    margin-bottom: 120px;
    button {
      width: 405px;
      height: 70px;
      background: #ffffff;
      border-radius: 15px;
      border: none;
      @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
      &:hover {
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        color: #ffffff;
        button {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #ffffff;
          background-clip: text;
        }
      }
    }
  }
  @include mobile() {
    background: url("../images/ogpo-back22.png");
    background-size: cover;
    padding-bottom: 70px;
    background-color: #efefef;
    height: 378px;
    &-title {
      //change
      font-size: 20px;
      line-height: 26px;
      padding: 40px 0 44px 0;
      width: 65%;
    }
    &-sub {
      //change
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
      padding-bottom: 0;
      text-transform: none;
      font-weight: 400;
      width: 50%;
    }
    &-btn {
      width: 100%;
      height: 0px;
      border-radius: 8px;
      margin: 0 auto;
      button {
        width: 100%;
        height: 45px;
        border-radius: 3px;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0px;
        margin-top: 20%;
        span {
          display: none;
        }
      }
    }
    &-cards {
      display: none;
    }
  }
  @include tablet() {
    background: url("../images/ogpo-back22.png");
    background-size: cover;
    padding-bottom: 70px;
    background-color: #efefef;
    height: 378px;
    &-title {
      //change
      font-size: 20px;
      line-height: 26px;
      padding: 40px 0 44px 0;
      width: 65%;
    }
    &-sub {
      //change
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
      padding-bottom: 0;
      text-transform: none;
      font-weight: 400;
      width: 50%;
    }
    &-btn {
      width: 100%;
      height: 0px;
      border-radius: 8px;
      margin: 0 auto;
      button {
        width: 100%;
        height: 45px;
        border-radius: 3px;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0px;
        margin-top: 20%;
        span {
          display: none;
        }
      }
    }
    &-cards {
      display: none;
    }
  }
  @include tablet-1024() {
    background-size: cover;
    padding-bottom: 10px;
    &-title {
      font-size: 24px;
      line-height: 29px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &-sub {
      padding-bottom: 20px;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 0;
    }
    &-cards {
      &__box {
        width: 120px;
        height: 60px;
        padding: 24px 0 65px 20px;
        font-size: 10px;
        line-height: 14px;
      }
    }
    &-btn {
      width: 200px;
      height: 50px;
      border-radius: 8px;
      margin-top: 50px;
      button {
        width: 200px;
        height: 50px;
        border-radius: 8px;
        font-size: 9px;
        line-height: 14px;
      }
    }
  }
  @include desktop() {
    background-size: cover;
    &-title {
      font-size: 26px;
      line-height: 30px;
      padding-top: 30px;
      padding-bottom: 0;
    }
    &-sub {
      font-size: 14px;
      line-height: 18px;
      padding-bottom: 10px;
    }
    &-cards {
      &__box {
        font-size: 15px;
        line-height: 18px;
        padding-top: 50px;
        width: 160px;
        height: 120px;
      }
    }
    &-btn {
      width: 300px;
      height: 60px;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      button {
        width: 300px;
        height: 60px;
        border-radius: 8px;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}
.middle {
  &-line {
    background: linear-gradient(
      90deg,
      #ff143f 0%,
      #c600dd 49.97%,
      #1b27ff 100%
    );
    height: 1px;
    margin-top: -40px;
    position: relative;
    z-index: 3;
    top: 11px;
  }
  &-box {
    z-index: 2;
    background: #f6f6f6;
    padding: 100px 100px;
    border-radius: 15px;
    margin-top: -190px;
    max-width: 1320px;
    width: 100%;
    &__title {
      @include fontStyle(Roboto, normal, 500, 28px, 38px, #373737);
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 60px;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      &-1 {
        width: 250px;
        height: 80px;
        text-align: center;
      }
      &-1,
      &-3 {
        max-width: 300px;
        flex-wrap: wrap;
        button {
          background: #f6f6f6;
          width: 250px;
          height: 80px;
          border: 1px solid #c4c4c4;
          border-radius: 15px;
          @include fontStyle(Roboto, normal, 300, 18px, 25px, #373737);
          margin-top: 40px;
          &:hover {
            filter: drop-shadow(0px 4px 43px rgba(0, 0, 0, 0.15));
            background-color: #fff;
            border: none;
          }
        }
      }
      &-2 {
        text-align: center;
        height: 380px;
        width: 42%;
        img {
          max-width: 106px;
          max-height: 106px;
        }
        .par {
          @include fontStyle(Roboto, normal, 300, 18px, 25px, transparent);
          background: #373737;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          padding-top: 20px;
          padding-bottom: 60px;
          text-align: center;
          margin: 0 auto;
          height: 230px;
          width: 430px;
        }
        .btn {
          width: 380px;
          height: 70px;
          background: #ffffff;
          border-radius: 15px;
          button {
            padding-top: 15px;
            background: #ffffff;
            border: none;
            @include fontStyle(Roboto, normal, 500, 18px, 25px, #8948b1);
          }
          &:hover {
            background: linear-gradient(
              90deg,
              #ff4466 0%,
              #be31ce 49.97%,
              #3641ff 100%
            );
            color: #ffffff;
            button {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #ffffff;
              background-clip: text;
            }
          }
        }
      }
      &-3 {
        text-align: center;
      }
    }
  }
  @include mobile() {
    padding-top: 15px;
    padding-bottom: 20px;
    background: #f6f6f6;
    background-size: cover;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0;
      background: transparent;
      padding: 0;
      &__title {
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        letter-spacing: 0;
        margin-bottom: 10px;
        padding-top: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet() {
    background: #f6f6f6;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0;
      padding: 30px 0 0 0;
      &__title {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 5px;
        text-align: left;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include tablet-1024() {
    background: #f6f6f6;
    &-line {
      display: none;
    }
    &-box {
      margin-top: 0px;
      padding: 50px 70px;
      &__title {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }
      &__bottom {
        display: none;
      }
    }
  }
  @include desktop() {
    &-line {
      margin-top: 30px;
      height: 1px;
    }
    &-box {
      margin-top: -115px;
      // margin-top: -90px;
      padding: 50px;
      &__title {
        font-size: 22px;
        line-height: 26px;
      }
      &__bottom {
        &-1,
        &-3 {
          button {
            width: 190px;
            height: 60px;
            font-size: 14px;
          }
        }
        &-2 {
          .btn {
            width: 280px;
            height: 60px;
            border-radius: 10px;
            button {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.about {
  margin: 0 auto;
  max-width: 1320px;
  width: 80%;
  &-ogpo {
    margin-top: 95px;
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: 10px;
    }
    &__card {
      display: flex;
      align-items: center;
      &__num {
        @include fontStyle(Roboto, normal, 900, 60px, 82px, transparent);
        background: -webkit-linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        color: #ffffff;
        align-items: center;
      }
      &__par {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        background: #f6f6f6;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-sizing: border-box;
        backdrop-filter: blur(55px);
        border-radius: 15px;
        padding: 20px;
        margin-left: 12px;
        width: 1220px;
        height: 85px;
        display: flex;
        align-items: baseline;
        span {
          @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
          padding-right: 20px;
        }
      }
    }
  }
  @include mobile() {
    width: 90%;
    &-ogpo {
      margin-top: 35px;
      margin-bottom: -10px;
      &__title {
        //change
        font-size: 16px;
        font-weight: 600;
        line-height: 17px;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
        margin-bottom: 0;
      }
      &__card {
        align-items: baseline;
        padding-bottom: 15px;
        &__num {
          padding: 1px;
          font-size: 14px;
          line-height: 16px;
        }
        &__par {
          background-color: transparent;
          padding: 5px;
          margin-left: 0;
          //change
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-wrap: wrap;
          height: 65px;
          span {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            width: 100%;
            height: 10px;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
  @include tablet() {
    &-ogpo {
      margin-top: 35px;
      margin-bottom: -35px;
      &__title {
        font-size: 18px;
        line-height: 22px;
        background: linear-gradient(
          90deg,
          #ff143f 0%,
          #c600dd 49.97%,
          #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
        margin-bottom: 0;
      }
      &__card {
        align-items: baseline;
        padding-bottom: 20px;
        &__num {
          font-size: 15px;
          line-height: 18px;
        }
        &__par {
          background-color: transparent;
          padding: 5px;
          margin-left: 0;
          //change
          font-size: 14px;
          line-height: 17px;
          display: flex;
          flex-wrap: wrap;
          span {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
  @include tablet-1024() {
    &-ogpo {
      margin-top: 55px;
      &__title {
        font-size: 20px;
        line-height: 22px;
      }
      &__card {
        align-items: baseline;
        padding-bottom: 20px;
        &__num {
          font-size: 25px;
          line-height: 28px;
        }
        &__par {
          font-size: 13px;
          line-height: 17px;
          height: 80px;
          span {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
